import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../components/molecules/Tooltip';
import css from './SubDomainItem.css';
import StarIcon from '../../resources/svg/icons_small_bookmark.svg';
import StarEmptyIcon from '../../resources/svg/icons_small_empty_bookmark.svg';
import ArchiveIcon from '../../resources/svg/archive_grey.svg';

class SubDomainItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onFavouriteClicked = this._onFavouriteClicked.bind(this);
        this.onArchiveClicked = this._onArchiveClicked.bind(this);
    }

    _onFavouriteClicked() {
        const { onFavouriteClicked, subdomain, zone, favourite } = this.props;
        if (favourite) {
            return;
        }
        onFavouriteClicked(subdomain, zone, favourite);
    }

    _onArchiveClicked() {
        const { onArchiveClicked, subdomain, zone } = this.props;
        onArchiveClicked(subdomain, zone);
    }

    render() {
        const { fullDomain, favourite, isAgency } = this.props;
        const StarIconClass = favourite ? StarIcon : StarEmptyIcon;
        return !isAgency ? (
            <div className={css.container}>
                <div>{fullDomain}</div>
                <div className={css.defaultMark}>{favourite ? '(default)' : ''}</div>
                <div className={css.buttonsArea}>
                    <Tooltip
                        interactive
                        titleTranslationKey="STATIC.PAGES.MANAGE_LINKS.DEFAULT_TOOLTIP"
                        disabled={favourite}
                    >
                        <div onClick={this.onFavouriteClicked} className={css.buttonContainer}>
                            <StarIconClass className={`${css.button} ${favourite ? css.marked : ''}`} />
                        </div>
                    </Tooltip>
                    <Tooltip interactive titleTranslationKey="STATIC.PAGES.MANAGE_LINKS.ARCHIVE_TOOLTIP">
                        <div onClick={this.onArchiveClicked} className={css.buttonContainer}>
                            <ArchiveIcon className={`${css.button} ${css.archive}`} />
                        </div>
                    </Tooltip>
                </div>
            </div>
        ) : (
            <div className={css.domainNoHover}>{fullDomain}</div>
        );
    }
}

SubDomainItem.propTypes = {
    fullDomain: PropTypes.string,
    favourite: PropTypes.bool,
    onFavouriteClicked: PropTypes.func,
    onArchiveClicked: PropTypes.func,
    isAgency: PropTypes.bool,
};

SubDomainItem.defaultProps = {
    fullDomain: '',
    favourite: false,
    onFavouriteClicked: () => {},
    onArchiveClicked: () => {},
    isAgency: false,
};

export default SubDomainItem;
