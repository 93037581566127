import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import FauxButton from './FauxButton';
import TextField from '../molecules/TextField';
import css from './CopyArea.css';
import debounce from 'lodash/debounce';

class CopyArea extends React.Component {
    constructor(props) {
        super(props);
        this.textField = React.createRef();
        this.onCopyButtonClick = this._onCopyButtonClick.bind(this);
        this.state = {
            copying: false,
            value: props.content,
        };
    }

    _onCopyButtonClick() {
        const { onCopyClicked, label } = this.props;
        try {
            this.setState({
                copying: true,
            });
            const {value: copyText} = this.state;
            navigator.clipboard.writeText(copyText);
            setTimeout(() => {
                this.setState({
                    copying: false,
                });
            }, 2000);
            if (onCopyClicked) {
                onCopyClicked(copyText, label);
            }
        } catch (e) {
        }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.content !== this.state.value) {
        this.setState({ value: nextProps.content });
      }
    }

    render() {
        const { containerStyle, label, copyText, copiedText, type } = this.props;
        const { copying, value } = this.state;
        const isSmall = type === 'small';

        return (
            <div className={`${css.container} ${css[type]}`} style={containerStyle}>
                <div className={css.topArea}>
                    <div className={css.label}>
                        <Translate id={label} />
                    </div>
                    <FauxButton
                        onClick={this.onCopyButtonClick}
                        disabled={copying}
                        className={css.button}
                    >
                        <Translate id={copying && copiedText ? copiedText : copyText} />
                    </FauxButton>
                </div>
                <div>
                    <TextField
                        type={isSmall ? 'text' : 'textarea'}
                        value={value}
                        ref={this.textField}
                        inputConfig={
                            isSmall
                                ? {}
                                : {
                                      style: {
                                          resize: 'none',
                                          height: '115px',
                                      },
                                  }
                        }
                        onChange={debounce(newValue => this.setState({value: newValue}))}
                    />
                </div>
            </div>
        );
    }
}

CopyArea.propTypes = {
    containerStyle: PropTypes.objectOf(PropTypes.any),
    type: PropTypes.oneOf(['small', 'large', null]),
    label: PropTypes.string,
    copyText: PropTypes.string,
    copiedText: PropTypes.string,
    content: PropTypes.string,
    onCopyClicked: PropTypes.func,
};

CopyArea.defaultProps = {
    containerStyle: {},
    type: null,
    label: '',
    copyText: '',
    copiedText: '',
    content: '',
    onCopyClicked: () => {},
};

export default CopyArea;
