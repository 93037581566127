import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Translate, withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import css from './TextField.css';
import ClearFieldIcon from '../../resources/svg/small_clear_field.svg';
import QuestionMark from '../widgets/QuestionMark';

const NON_WHOLE_NUMBER_CHARACTERS = ['e', 'E', '+', '.'];

function TextField(props) {
    const {
        label,
        placeholder,
        password,
        error,
        errorClassName,
        value: propValue,
        debounce: debounceValue,
        autoCompleteOff,
        disabled,
        maxLength,
        type,
        containerStyle,
        containerClass,
        labelClass,
        labelTextClass,
        inputId,
        inputConfig,
        clearable,
        labelTooltip,
        mandatory,
        onKeyDown: onKeyDownProp,
        inputClassName,
        onChange: propOnChange,
        translate,
        wholeNumberOnly,
    } = props;

    const inputElem = useRef();
    const [value, setValue] = useState(propValue);

    useEffect(() => {
        if (value !== propValue) {
            setValue(propValue);
        }
    }, [propValue]);

    const debounceWrapper = (debounceValue, onChange) => {
        return debounce(
            value => {
                onChange(value);
            },
            debounceValue,
            { trailing: true }
        );
    };

    const outOnChange = useMemo(() => {
        return debounceValue ? debounceWrapper(debounceValue, propOnChange) : propOnChange;
    }, [debounceValue, propOnChange]);

    const onChange = event => {
        const { value: eventValue } = event.target;
        setValue(eventValue);
        outOnChange(eventValue);
    };

    const onClear = () => {
        setValue('');
        outOnChange('');
    };

    const id = inputId || label ? `textfield-${label}` : null;
    const inputType = password ? 'password' : type || 'text';
    const clearFieldIcon = <ClearFieldIcon key={`${id}_clear`} className={css.clear} onClick={onClear} />;

    const onKeyDown = event => {
        onKeyDownProp && onKeyDownProp(event);

        // don't type non-whole numbers
        if (type === 'number' && wholeNumberOnly && NON_WHOLE_NUMBER_CHARACTERS.includes(event.key)) {
            event.preventDefault();
        }
    };

    const inputElementProps = {
        id,
        key: `${id}_input`,
        className: classNames(inputClassName, css.fieldInput, { [css.errorColor]: error }),
        type: inputType,
        value,
        placeholder: placeholder ? translate(placeholder) : placeholder,
        autoComplete: autoCompleteOff ? 'off' : 'on',
        onChange,
        disabled,
        maxLength,
        onKeyDown,
        ...inputConfig,
    };

    const inputElement =
        type !== 'textarea' ? (
            <input {...inputElementProps} ref={inputElem} />
        ) : (
            <textarea {...inputElementProps} ref={inputElem} />
        );

    const inputComponentsSection = [
        inputElement,
        clearable && value && clearFieldIcon,
        error && (
            <div key={`${id}_error`} className={classNames(css.errorMessage, errorClassName)}>
                <Translate id={error} />
            </div>
        ),
    ];

    return (
        <div className={classNames(css.field, containerClass, { [css.disabled]: disabled })} style={containerStyle}>
            {label ? (
                <label htmlFor={id} className={labelClass}>
                    <div className={classNames(css.labelText, labelTextClass, { [css.errorColor]: error })}>
                        <span className={`${mandatory ? css.mandatory : ''}`}>
                            <Translate id={label} />
                        </span>
                        {labelTooltip ? <QuestionMark message={labelTooltip} /> : null}
                    </div>
                    <div className={css.inputContainer}>{inputComponentsSection}</div>
                </label>
            ) : (
                inputComponentsSection
            )}
        </div>
    );
}

TextField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    password: PropTypes.bool,
    error: PropTypes.string,
    autoCompleteOff: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'password', 'number', 'textarea', null]),
    containerStyle: PropTypes.objectOf(PropTypes.any),
    containerClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputId: PropTypes.string,
    debounce: PropTypes.number,
    inputConfig: PropTypes.objectOf(PropTypes.any),
    clearable: PropTypes.bool,
    mandatory: PropTypes.bool,
    labelTooltip: PropTypes.string,
    onKeyDown: PropTypes.func,
    inputClassName: PropTypes.string,
    wholeNumberOnly: PropTypes.bool,
    errorClassName: PropTypes.string,
    labelTextClass: PropTypes.string,
};

TextField.defaultProps = {
    label: '',
    value: '',
    placeholder: null,
    password: false,
    error: null,
    autoCompleteOff: false,
    disabled: false,
    type: null,
    containerStyle: {},
    containerClass: '',
    labelClass: '',
    inputId: '',
    debounce: 0,
    inputConfig: {},
    clearable: false,
    mandatory: false,
    labelTooltip: null,
    onKeyDown: null,
    inputClassName: '',
    wholeNumberOnly: false,
    errorClassName: '',
    labelTextClass: '',
};

const LocalizedTextField = withLocalize(React.memo(TextField));
LocalizedTextField.displayName = 'TextField'; // in order to be able to .find('TextField') in Enzyme tests.

export default LocalizedTextField;
