import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import Spinner from 'components/widgets/Spinner';
import css from 'mediaMixModeling/styles.css';
import AutoCompleteField from '../../components/molecules/AutoCompleteField';
import { filterOptionsParserByFilterType, findFilterValue, getFormattedSelectedValue } from 'benchmarks/utils';
import { useFetchMMMMetadata } from '../hooks';
import { APP_FILTER, COUNTRY_FILTER, METRIC_FILTER, PLATFORM_FILTER } from '../consts';

const MmmFilters = ({ children, onChange, enableFilters, wrapperStyle, getValidationErrors }) => {
    const {
        breakdowns = {},
        default_breakdowns: defaultFilterValues,
        raw_breakdowns: rawBreakdowns = [],
        isLoading,
    } = useFetchMMMMetadata();
    const [selectedFilterValues, setSelectedFilterValues] = useState({});
    const [validationErrors, setValidationErrors] = useState(null);

    const availableBreakdowns = useMemo(() => {
        const availableCountries = rawBreakdowns.filter(breakdown => {
            return !selectedFilterValues.app?.length || breakdown.app === selectedFilterValues.app[0];
        });
        const availablePlatforms = rawBreakdowns.filter(breakdown => {
            return (
                (!selectedFilterValues.app?.length || breakdown.app === selectedFilterValues.app[0]) &&
                (!selectedFilterValues.country?.length || breakdown.country === selectedFilterValues?.country[0])
            );
        });
        const availableMetrics = rawBreakdowns.filter(breakdown => {
            return (
                (!selectedFilterValues.app?.length || breakdown.app === selectedFilterValues.app[0]) &&
                (!selectedFilterValues.country?.length || breakdown.country === selectedFilterValues.country[0]) &&
                (!selectedFilterValues.platform?.length || breakdown.os === selectedFilterValues.platform[0])
            );
        });
        return {
            ...breakdowns,
            country: breakdowns.country?.filter(country =>
                availableCountries.map(({ country: availableCountry }) => availableCountry).includes(country.value)
            ),
            platform: breakdowns.platform?.filter(platform =>
                availablePlatforms.map(({ os: availableOS }) => availableOS).includes(platform.value)
            ),
            metric: breakdowns.metric?.filter(metric =>
                availableMetrics.map(({ metric: availableMetric }) => availableMetric).includes(metric.value)
            ),
        };
    }, [selectedFilterValues, breakdowns, rawBreakdowns]);

    useEffect(() => {
        if (defaultFilterValues) {
            setSelectedFilterValues(defaultFilterValues);
            onChange(defaultFilterValues, null);
        }
    }, [defaultFilterValues]);

    const handleChange = (filterType, selected) => {
        if (selected) {
            const newSelectedFilterValues = {
                ...selectedFilterValues,
                [filterType]: getFormattedSelectedValue(selected),
            };
            const newValidationErrors = getValidationErrors(newSelectedFilterValues);

            setSelectedFilterValues(newSelectedFilterValues);
            setValidationErrors(newValidationErrors);

            onChange(newSelectedFilterValues, newValidationErrors);
        }
    };

    if (!defaultFilterValues) {
        return <Spinner style={{ top: '10vh' }} show={isLoading} expanded />;
    } else {
        return (
            <div className={classNames(css.headerFiltersWrapper)} style={wrapperStyle}>
                <div className={css.headerFilters}>
                    {React.Children.map(children, child => {
                        const {
                            optionsKey,
                            isRequired = false,
                            isMulti = false,
                            addEmptyOption,
                            includeAllOption,
                            optional,
                        } = child.props;
                        const options = filterOptionsParserByFilterType[optionsKey](
                            availableBreakdowns[optionsKey],
                            { addEmptyOption },
                            { includeAllOption }
                        );
                        const value = findFilterValue(options, selectedFilterValues[optionsKey], isMulti);
                        const error = validationErrors && validationErrors[optionsKey];

                        return React.cloneElement(child, {
                            value,
                            options,
                            isMulti,
                            searchable: true,
                            controlled: true,
                            clearable: !!isMulti,
                            containerClass: css.headerFilter,
                            onChange: selected => {
                                handleChange(optionsKey, selected, isRequired, isMulti);
                            },
                            openMenuOnClick: !!(enableFilters || error),
                            error,
                            optional,
                        });
                    })}
                </div>
            </div>
        );
    }
};

MmmFilters.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    onChange: PropTypes.func.isRequired,
    enableFilters: PropTypes.bool,
    wrapperStyle: PropTypes.objectOf(PropTypes.any),
    getValidationErrors: PropTypes.func,
};

MmmFilters.defaultProps = {
    enableFilters: true,
    wrapperStyle: {},
    getValidationErrors: () => {},
};

const MMMFilter = withLocalize(({ optional, translate, type, ...props }) => {
    return (
        <AutoCompleteField
            label={`${translate(`STATIC.PAGES.MEDIA_MIX_MODELING.${type.toUpperCase()}_SELECTION_LABEL`)} ${
                optional ? translate('STATIC.PAGES.MEDIA_MIX_MODELING.SELECTION_LABEL_OPTIONAL') : ''
            }`}
            placeholder={`STATIC.PAGES.MEDIA_MIX_MODELING.${type.toUpperCase()}_SELECTION_PLACEHOLDER`}
            {...props}
        />
    );
});

MmmFilters.Countries = props => {
    return <MMMFilter {...props} type={COUNTRY_FILTER} />;
};

MmmFilters.Platforms = props => {
    return <MMMFilter {...props} type={PLATFORM_FILTER} />;
};

MmmFilters.Apps = props => {
    return <MMMFilter {...props} type={APP_FILTER} />;
};

MmmFilters.Metrics = props => {
    return <MMMFilter {...props} type={METRIC_FILTER} />;
};

export default withLocalize(MmmFilters);
