import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../molecules/Tooltip';
import Chevron from '../../resources/icons/chevron.svg';
import { trackMixpanelEvent } from '../../utils/general';
import { MIXPANEL_PREFIX } from '../../dataSources/utils';
import css from '../organisms/Table.css';

const TableCellPopover = props => {
    const { iconElement, value, tdStyle, tooltip, hasPopover, isAdMonetization, translate, children } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [showPopover, setShowPopover] = useState(false);

    const handleLocation = event => {
        setAnchorEl(event.currentTarget);
    };

    const openPopover = event => {
        if (event.currentTarget === anchorEl) {
            setAnchorEl(null);
            return;
        }
        trackMixpanelEvent(MIXPANEL_PREFIX, 'ETL drilldown clicked', {});
        handleLocation(event);
        setShowPopover(true);
    };

    const chevronAndPopover = hasPopover[value] ? (
        <Fragment>
            <div
                className={classNames(css.popoverChevron, { [css.popoverChevronOpen]: showPopover })}
                onClick={openPopover}
            >
                <Chevron />
            </div>
            <div className={css.popoverComponent} style={{ display: hasPopover[value] ? 'flex' : 'none' }}>
                {React.cloneElement(children, { showPopover, setShowPopover, anchorEl })}
            </div>
        </Fragment>
    ) : null;

    return (
        <td style={{ ...tdStyle }}>
            {value ? (
                <div className={css.popoverCell} style={hasPopover[value] ? null : { justifyContent: 'center' }}>
                    <span className={css.etlIconContainer}>
                        {tooltip[value] ? (
                            <Tooltip
                                placement="right"
                                titleTranslationKey={tooltip[value]}
                                titleTranslationData={{
                                    destinationType: isAdMonetization ? 'ad monetization' : 'marketing',
                                }}
                                translate={translate}
                                wraperClass={css.tooltip}
                            >
                                {iconElement[value] ? iconElement[value] : undefined}
                            </Tooltip>
                        ) : (
                            iconElement[value]
                        )}
                    </span>
                    {chevronAndPopover}
                </div>
            ) : null}
        </td>
    );
};

TableCellPopover.propTypes = {
    children: PropTypes.node.isRequired,
    tdStyle: PropTypes.objectOf(PropTypes.any),
    iconElement: PropTypes.object,
    value: PropTypes.string,
    tooltip: PropTypes.object,
    hasPopover: PropTypes.bool,
    translate: PropTypes.func,
    isAdMonetization: PropTypes.bool,
};

TableCellPopover.defaultProps = {
    tdStyle: {},
    iconElement: {},
    value: '',
    tooltip: null,
    hasPopover: false,
    translate: x => x,
    isAdMonetization: false,
};

export default TableCellPopover;
