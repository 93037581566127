import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { makeStyles } from '@mui/styles';
import pluralize from 'pluralize';
import MuSlider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import css from './Slider.css';
import QuestionMark from '../widgets/QuestionMark';

const useStyles = makeStyles({
    track: {
        background: 'var(--color)',
    },
    thumb: {
        background: 'var(--color)',
        height: '15px',
        width: '15px',
    },
    mark: {
        display: 'none',
    },
    marked: props => ({
        marginBottom: props.marginBottom,
        marginTop: props.marginTop,
    }),
    markLabel: {
        color: '#AAAAAA',
        fontWeight: 600,
        fontSize: '12px',
        top: '30px',
    },
    root: {
        '&$disabled': {
            '& $track': {
                background: 'var(--disabled-col)',
            },
            '& $thumb': {
                background: 'var(--disabled-col)',
            },
        },
        color: props => props.color,
    },
    disabled: {},
});

const ValueLabelComponent = ({ children, open, value, disabled }) => {
    return useMemo(() => {
        let useVal = value;
        try {
            useVal = !Number.isInteger(useVal) ? useVal.toFixed(2) : useVal;
        } catch (e) {}
        return (
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                    modifiers: {
                        preventOverflow: {
                            enabled: false,
                        },
                        hide: {
                            enabled: false,
                        },
                    },
                }}
                open={open || disabled}
                enterTouchDelay={0}
                placement="top"
                title={useVal}
                classes={{
                    tooltip: css.tooltip,
                    popper: css.tooltipPopper,
                }}
            >
                {children}
            </Tooltip>
        );
    }, [children, open, value, disabled]);
};

function Slider(props) {
    const [value, setValue] = useState(props.value);
    let classes = useStyles(props);
    const {
        max,
        min,
        step,
        showSteps,
        extraSteps,
        color,
        label,
        labelData,
        showValue,
        disabled,
        value: propValue,
        onChange,
        stepsPrefix,
        stepsOverride,
        labelTooltip,
        labelTooltipStyle,
        className,
        sliderContainerClassName,
        labelClassName,
        tooltipClassName,
        labelTextClassName,
        markLabelClassName,
        debounceTime,
    } = props;

    if (markLabelClassName) {
        classes = {
            ...classes,
            markLabel: classNames(classes.markLabel, markLabelClassName),
        };
    }

    useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    const _outOnChange = useCallback(
        debounce(
            val => {
                onChange(val);
            },
            debounceTime,
            { trailing: true }
        ),
        []
    );

    const _handleChange = (event, val) => {
        setValue(val);
        if (onChange) {
            _outOnChange(val, event);
        }
    };

    const _getStepText = val => {
        const suffix = pluralize(stepsPrefix, val);
        let ret = `${val} ${suffix}`;
        if (stepsOverride && stepsOverride[val]) {
            ret = stepsOverride[val];
        }
        return ret;
    };

    let marks = [];
    if (showSteps) {
        const steps = min === max ? [min] : [min, ...extraSteps, max];
        marks = steps.map(mark => ({ value: mark, label: _getStepText(mark) }));
    }

    return (
        <div className={classNames(css.container, className)}>
            {label ? (
                <div className={classNames(css.label, labelClassName)}>
                    <span className={classNames(labelTextClassName)}>
                        <Translate id={label} data={labelData} />
                    </span>
                    {labelTooltip ? (
                        <QuestionMark
                            message={labelTooltip}
                            tooltipClassName={tooltipClassName}
                            contentStyle={labelTooltipStyle}
                            interactive
                        />
                    ) : null}
                </div>
            ) : null}
            {showValue ? <div className={css.valueContainer} /> : null}
            <div className={classNames(css.sliderContainer, sliderContainerClassName)}>
                <MuSlider
                    components={{ ValueLabel: ValueLabelComponent }}
                    valueLabelDisplay={showValue ? 'on' : 'auto'}
                    value={value}
                    min={min}
                    max={max}
                    step={step}
                    onChange={_handleChange}
                    classes={classes}
                    color={color}
                    style={{
                        '--disabled-col': '#aaaaaa',
                    }}
                    marks={marks}
                    disabled={disabled}
                    size="small"
                />
            </div>
        </div>
    );
}

Slider.propTypes = {
    value: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    step: PropTypes.number,
    showSteps: PropTypes.bool,
    stepsPrefix: PropTypes.string,
    extraSteps: PropTypes.arrayOf(PropTypes.number),
    color: PropTypes.string,
    label: PropTypes.string,
    labelData: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func,
    classes: PropTypes.objectOf(PropTypes.any),
    showValue: PropTypes.bool,
    stepsOverride: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    labelTooltip: PropTypes.string,
    labelTooltipStyle: PropTypes.objectOf(PropTypes.any),
    marginBottom: PropTypes.number,
    className: PropTypes.string,
    sliderContainerClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    tooltipClassName: PropTypes.string,
    labelTextClassName: PropTypes.string,
    markLabelClassName: PropTypes.string,
    debounceTime: PropTypes.number,
};

Slider.defaultProps = {
    value: 1,
    max: 30,
    min: 1,
    step: 1,
    showSteps: true,
    stepsPrefix: '',
    extraSteps: [],
    color: '#3088f4',
    label: '',
    labelData: {},
    onChange: () => {},
    classes: {},
    showValue: true,
    stepsOverride: {},
    disabled: false,
    labelTooltip: null,
    labelTooltipStyle: {},
    marginBottom: 30,
    className: null,
    markLabelClassName: null,
    sliderContainerClassName: null,
    tooltipClassName: null,
    labelClassName: null,
    labelTextClassName: null,
    debounceTime: 500,
};

export default Slider;
