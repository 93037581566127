import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faCircle as faCircleSolid } from '@fortawesome/fontawesome-free-solid';
import { faCircle as faCircleRegular } from '@fortawesome/fontawesome-free-regular';
import { PageHeader, PageWrapper, DeleteDialog } from '../components/partials';
import {
    Button,
    Label,
    Table,
    TableCell,
    TableCellNumeric,
    TableCellIcon,
    ContextMenu,
    TableCellLink,
    Spinner,
    Dialog,
} from '../components/widgets';
import { SEGMENT_STATUSES } from './consts';
import Edit from './containers/Edit';
import SharingOptions from './containers/SharingOptions';
import Admin from './components/Admin';
import css from './styles.pcss';
import AutoComplete from '../components/molecules/AutoComplete';
import HistroyIcon from '../resources/svg/history.svg';
import WarningMessage from '../components/utilities/WarningMessage';

class AudienceSegments extends React.Component {
    constructor(props) {
        super(props);
        this.onSearch = this._onSearch.bind(this);
        this.onToggleStatus = this._onToggleStatus.bind(this);
        this.editStart = this._editStart.bind(this);
        this.editCancel = this._editCancel.bind(this);
        this.editConfirm = this._editConfirm.bind(this);
        this.deleteStart = this._deleteStart.bind(this);
        this.deleteCancel = this._deleteCancel.bind(this);
        this.deleteConfirm = this._deleteConfirm.bind(this);
        this.toggleAdmin = this._toggleAdmin.bind(this);
        this.download = this._download.bind(this);
        this.openSharingOptions = this._openSharingOptions.bind(this);
        this.changeSort = this._changeSort.bind(this);
        this.canResume = this._canResume.bind(this);

        this.metadata = [
            {
                name: 'name',
                displayName: 'Audience Segment',
                cellComponent: TableCellLink,
                cellValues: ['id'],
                cellEvents: {
                    onClick: propsVal => {
                        this.editStart(propsVal.id);
                    },
                },
                cellProps: {},
                headerClick: this.changeSort.bind(null, 'name', 'string'),
                showHeaderArrow: true,
            },
            {
                name: 'description',
                displayName: 'Description',
                cellComponent: TableCell,
                headerClick: this.changeSort.bind(null, 'description', 'string'),
                showHeaderArrow: true,
            },
            {
                name: 'reach',
                displayName: 'Reach',
                headerAlign: 'center',
                cellComponent: TableCellNumeric,
                headerTooltip: (
                    <span>
                        The estimated reach of the segment, indicating the number of devices belonging to the defined
                        segment.
                        <br />
                        The estimate reach is updated every 30 minutes.
                    </span>
                ),
                headerClick: this.changeSort.bind(null, 'reach', 'number'),
                showHeaderArrow: true,
            },
            {
                name: 'status',
                displayName: 'Status',
                headerAlign: 'center',
                cellComponent: TableCellIcon,
                cellProps: {
                    color: {
                        [SEGMENT_STATUSES.ACTIVE]: '#5cb85c',
                        [SEGMENT_STATUSES.LOADING]: '#3a92e5',
                        [SEGMENT_STATUSES.INACTIVE]: '#ffaa20',
                    },
                    tooltip: {
                        [SEGMENT_STATUSES.ACTIVE]: 'Active',
                        [SEGMENT_STATUSES.LOADING]: 'Building',
                        [SEGMENT_STATUSES.INACTIVE]: 'Inactive',
                    },
                },
                cellValues: [{ propName: 'icon', dataKey: 'statusIcon' }],
                headerTooltip: (
                    <div style={{ textAlign: 'left' }}>
                        <FontAwesomeIcon icon={faCircleSolid} style={{ color: '#5cb85c' }} /> Segment is active and
                        processing real-time app data.
                        <br />
                        <FontAwesomeIcon icon={faCircleSolid} style={{ color: '#3a92e5' }} /> Segment is building and
                        initializing. New segments may take up to an hour to initialize.
                        <br />
                        <FontAwesomeIcon icon={faCircleSolid} style={{ color: '#ffaa20' }} /> Segment was paused and now
                        is inactive.
                        <br />
                        <FontAwesomeIcon icon={faCircleRegular} style={{ color: '#ffaa20' }} /> Segment was paused
                        automatically due to inactivity.
                    </div>
                ),
                headerClick: this.changeSort.bind(null, 'status', 'string'),
                showHeaderArrow: true,
            },
            {
                name: 'modifiedStr',
                displayName: 'Last Modified',
                cellComponent: TableCell,
                headerTooltip: 'The last time the segment definition was edited.',
                headerClick: this.changeSort.bind(null, 'modifiedUnixTS', 'number'),
                showHeaderArrow: true,
                sortBy: 'modifiedUnixTS',
            },
            {
                name: 'distributionsStr',
                displayName: 'Distributions',
                cellComponent: TableCell,
                headerTooltip: (
                    <span>
                        A list of distributions this segment belongs to.
                        <br />
                        Visit the distributions page to edit configured distributions.
                    </span>
                ),
            },
            {
                name: 'history',
                displayName: 'Dist. History',
                cellComponent: TableCellLink,
                headerAlign: 'center',
                cellValues: ['id'],
                cellEvents: {
                    onClick: values => {
                        this.toggleAdmin(values.id);
                    },
                },
                cellProps: {
                    label: <HistroyIcon />,
                    align: 'center',
                    buttonClassName: css.tableIcon,
                },
            },
            {
                name: 'id',
                displayName: 'Actions',
                headerAlign: 'center',
                cellComponent: ContextMenu,
                cellValues: ['status', 'export', 'downloadable'],
                cellEvents: {
                    onSelect: (item, propsVal) => {
                        item.onClick(propsVal);
                    },
                },
                cellProps: {
                    items: [
                        {
                            name: 'Edit',
                            onClick: propsval => {
                                this.editStart(propsval.value);
                            },
                        },
                        {
                            name: propsval => `${propsval.status === SEGMENT_STATUSES.INACTIVE ? 'Resume' : 'Pause'}`,
                            disabled: propsval =>
                                propsval.status === SEGMENT_STATUSES.INACTIVE && !this.canResume(propsval.status),
                            onClick: propsval => {
                                this.onToggleStatus(propsval.value, propsval.status);
                            },
                        },
                        {
                            name: 'Duplicate',
                            onClick: propsval => {
                                this.editStart(propsval.value, '', true);
                            },
                        },
                        {
                            name: propsval => {
                                return propsval.downloadable ? 'Download' : 'Download not ready';
                            },
                            onClick: propsval => {
                                this.download(propsval.downloadable, propsval.value);
                            },
                        },
                        {
                            name: 'Share',
                            isNew: true,
                            onClick: propsval => {
                                this.openSharingOptions(propsval.value);
                            },
                        },
                        {
                            name: 'Delete',
                            onClick: propsval => {
                                this.deleteStart(propsval.value);
                            },
                        },
                    ],
                },
            },
        ];
    }

    _onSearch(value) {
        this.props.onSearch(value);
    }

    _onToggleStatus(id, status) {
        const newStatus = status === SEGMENT_STATUSES.INACTIVE ? SEGMENT_STATUSES.LOADING : SEGMENT_STATUSES.INACTIVE;
        this.props.onToggleStatus(id, newStatus);
    }

    _editStart(id = -1, template = '', duplicate = false) {
        this.props.onEditStart(id, template, duplicate);
    }

    _editCancel() {
        const { onEditCancel, editId } = this.props;
        onEditCancel(editId);
    }

    _editConfirm() {
        this.props.onEditConfirm(this.props.editId, this.props.edit);
    }

    _deleteStart(id) {
        this.props.onDeleteStart(id);
    }

    _deleteCancel() {
        this.props.onDeleteCancel();
    }

    _deleteConfirm() {
        this.props.onDeleteConfirm(this.props.deleteId);
    }

    _toggleAdmin(id) {
        this.props.onEditStart(id, '', false, true);
    }

    _download(downloadable, id) {
        if (!downloadable) {
            alert(
                'Download not yet ready. This can take up to an hour after creating a segment. If this message persists, contact support'
            );
        } else {
            this.props.onDownload(id);
        }
    }

    _openSharingOptions(id) {
        this.props.onOpenSharingOptions(id);
    }

    _changeSort(col, type) {
        const sort = {
            col,
            ascending: this.props.sort.col === col ? !this.props.sort.ascending : false,
            type,
        };
        this.props.onChangeSort(sort);
    }

    _canResume() {
        const { activeSegmentsLimit, activeSegmentsCount } = this.props;
        return activeSegmentsCount < activeSegmentsLimit;
    }

    render() {
        const {
            segments,
            search,
            sort,
            loading,
            deleteId,
            translate,
            templates,
            adminMode,
            activeSegmentsLimit,
            activeSegmentsCount,
        } = this.props;
        if (loading) {
            return <Spinner show expanded />;
        }

        const metaData = this.metadata.map(item => {
            if (item.showHeaderArrow) {
                item.headerArrow = sort.col === (item.sortBy || item.name) && (sort.ascending ? 'up' : 'down');
            }
            if (item.name === 'name') {
                item.cellProps.highlight = search && search.toLowerCase();
            }
            return item;
        });

        let warningMessage = '';
        if (activeSegmentsCount >= activeSegmentsLimit) {
            warningMessage = 'STATIC.PAGES.AUDIENCES.REACHED_MAXIMUM_WARNING';
        } else if (activeSegmentsLimit - activeSegmentsCount <= 5) {
            warningMessage = 'STATIC.PAGES.AUDIENCES.NEAR_MAXIMUM_WARNING';
        }

        return (
            <PageWrapper allowDropDownPageScrolling>
                <PageHeader
                    text="STATIC.PAGE_HEADERS.AUDIENCE_SEGMENTS"
                    subText={{
                        faqLink: 'https://support.singular.net/hc/en-us/articles/360025454492-Audiences-FAQ',
                    }}
                />
                <div>
                    <Button
                        type="primary"
                        level="level1"
                        onClick={() => this.editStart()}
                        disabled={activeSegmentsCount >= activeSegmentsLimit}
                    >
                        <Translate id="STATIC.PAGES.AUDIENCES.CREATE_NEW" />
                    </Button>
                </div>
                <div className={css.headerWrapper}>
                    <AutoComplete
                        placeholder="STATIC.PAGES.AUDIENCES.SEARCH_SEGMENTS"
                        containerStyle={{
                            flexBasis: '500px',
                            marginRight: '20px',
                        }}
                        selectOptions={{
                            options: [],
                            noOptionsMessage: () => null,
                            isSearchable: true,
                            inputValue: search,
                        }}
                        onInputChange={this.onSearch}
                    />
                    <div>
                        {Object.keys(templates).map(t => (
                            <Button
                                type="secondary"
                                level="level2"
                                className={css.templateButton}
                                onClick={() => {
                                    this.editStart(-1, t);
                                }}
                                key={`template_${templates[t]}`}
                            >
                                {templates[t]}
                            </Button>
                        ))}
                    </div>
                </div>
                <div className={css.segmentCounter}>
                    <Translate
                        id="STATIC.PAGES.AUDIENCES.SEGMENTS_COUNT"
                        data={{ activeSegmentsCount, activeSegmentsLimit, segmentsCount: segments.length }}
                    />
                </div>
                <WarningMessage
                    show={warningMessage}
                    message={warningMessage}
                    showIcon={false}
                    textReplace={{
                        activeSegmentsLimit,
                        activeSegmentsCount,
                    }}
                    containerStyle={{
                        marginBottom: 20,
                    }}
                />
                <Table metadata={metaData} data={segments} sort={sort} onSort={this.changeSort} />
                <Edit onEditCancel={this.editCancel} />
                <SharingOptions />
                <Admin editCancel={this.editCancel} {...this.props} />
                <Dialog modal open={!!deleteId} repositionOnUpdate={false}>
                    <DeleteDialog
                        title="STATIC.PAGES.AUDIENCES.DELETE_WARNING"
                        textReplace="segment"
                        onAccept={this.deleteConfirm}
                        onReject={this.deleteCancel}
                    />
                </Dialog>
            </PageWrapper>
        );
    }
}

AudienceSegments.propTypes = {};

AudienceSegments.defaultProps = {};

export default AudienceSegments;
