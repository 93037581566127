import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlusIcon from '../../resources/svg/plus_link.svg';
import XIcon from '../../resources/svg/icon_remove_row.svg';
import SettingsIcon from '../../resources/svg/settings-slider.svg';
import css from './TableActions.css';
import Tooltip from '../molecules/Tooltip';

const TableActions = ({
    onDelete,
    onAdd,
    onEdit,
    addClassName,
    addTooltip,
    tdClassName,
    rowIndex,
    editClassName,
    editTooltip,
}) => {
    const addButtonTooltip = addTooltip(rowIndex);
    const editButtonTooltip = editTooltip(rowIndex);
    const addButtonClassName = addClassName(rowIndex);
    const editButtonClassName = editClassName(rowIndex);

    return (
        <td className={classNames(tdClassName, css.actions)}>
            {onEdit && (
                <Tooltip
                    wrapperClass={classNames(css.action, editButtonClassName)}
                    disabled={!editButtonTooltip}
                    titleTranslationKey={editButtonTooltip}
                >
                    <SettingsIcon title="Edit" onClick={() => onEdit(rowIndex)} />
                </Tooltip>
            )}
            {onDelete && (
                <Tooltip disabled wrapperClass={css.action}>
                    <XIcon title="Delete" onClick={() => onDelete(rowIndex)} />
                </Tooltip>
            )}
            {onAdd && (
                <Tooltip disabled={!addButtonTooltip} titleTranslationKey={addButtonTooltip}>
                    <PlusIcon
                        title="Add"
                        className={classNames(css.action, css.add, addButtonClassName)}
                        onClick={() => onAdd(rowIndex)}
                    />
                </Tooltip>
            )}
        </td>
    );
};

TableActions.propTypes = {
    onEdit: PropTypes.func,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    addClassName: PropTypes.func,
    editClassName: PropTypes.func,
    addTooltip: PropTypes.func,
    tdClassName: PropTypes.string,
    rowIndex: PropTypes.number,
    editTooltip: PropTypes.func,
};

TableActions.defaultProps = {
    onEdit: null,
    onAdd: null,
    onDelete: null,
    addTooltip: () => null,
    addClassName: () => '',
    editClassName: () => '',
    editTooltip: () => null,
    tdClassName: '',
    rowIndex: 0,
};

export default TableActions;
