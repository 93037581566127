import React from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from './MaxSlotsIndicator.css';
import Tooltip from '../../../components/molecules/Tooltip';

function MaxSlotsIndicator({ tooltip, label, slots, children }) {
    return (
        <div data-testid={`testing-${label}`} className={css.maxConversionIndicatorWrapper}>
            <div className={css.maxConversionIndicator}>
                {label ? (
                    <Tooltip disabled={!tooltip} titleTranslationKey={tooltip}>
                        <div className={css.maxConversionIndicatorLabel}>
                            <Translate id={label} className={css.maxConversionIndicatorLabel} />:
                        </div>
                        <span>{slots}</span>
                    </Tooltip>
                ) : null}
            </div>
            {children}
        </div>
    );
}

MaxSlotsIndicator.propTypes = {
    slots: PropTypes.number,
    tooltip: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

MaxSlotsIndicator.defaultProps = {
    slots: 0,
    tooltip: null,
    label: null,
    children: null,
};

export default MaxSlotsIndicator;
