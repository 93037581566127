import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import { Translate, withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import css from '../widgets/FieldGroup.css';
import CheckableFieldCSS from './CheckableField.css';
import CheckableField from './CheckableField';
import Dropdown from './Dropdown';
import TagInput from '../widgets/TagInput';
import Tooltip from './Tooltip';
import AutoComplete from './AutoComplete';
import AddButton from '../../containers/widgets/AddButton';
import WarningMessage from '../utilities/WarningMessage';
import TooltipIcon from '../../resources/svg/tooltip.svg';
import { reportTypes, showFieldsAvailabilityForField } from '../../utils/reports';
import NewTagInput from '../widgets/NewTagInput';
import { REPORT_QUERY_PREFIX, trackMixpanelEvent } from '../../utils/general';

const AnimationItem = posed.div({
    pressable: true,
    init: {
        opacity: 1,
    },
    press: {
        applyAtEnd: { opacity: 1 },
        applyAtStart: { opacity: 0.5 },
        opacity: 1,
        transition: {
            opacity: { ease: 'easeInOut', duration: 400 },
            default: { ease: 'linear', duration: 400 },
        },
    },
});

function FieldGroup(props) {
    const {
        fields,
        onFieldClick,
        addButton,
        group,
        reportType,
        style,
        onFieldsAvailabilityClick,
        onDownloadReport,
        showFieldsAvailabilityFeature,
        children,
        selectedSources,
        dataTypeFields,
        labelTooltip,
        label,
        translate,
        warnings,
        showDownloadReport,
        labelPosition,
        labelStyle,
        containerStyle,
        renderChildrenOnly,
        fieldGroupWarning,
        fieldGroupWarningParameters,
        bottomComponent,
    } = props;

    const getTags = useCallback(() => {
        return fields
            .filter(field => field.checked)
            .map(field => ({
                text: field.display_name,
                id: field.name,
            }));
    }, [fields]);

    const getSuggestions = useCallback(() => {
        return fields.map(field => field.display_name);
    }, [fields]);

    const onTagChange = useCallback(
        tag => {
            const foundField = fields.find(field => field.display_name === tag);
            onFieldClick(foundField);
        },
        [fields, onFieldClick]
    );

    useEffect(() => {
        showDownloadReport && trackMixpanelEvent(REPORT_QUERY_PREFIX, 'Shown Download Report');
    }, [showDownloadReport]);

    const getFieldKey = field => {
        return `${field.name}_${field.id}`;
    };

    const shouldShowFieldAsDisabled = field =>
        !!(
            !field.checked &&
            selectedSources &&
            selectedSources.length &&
            field.available_sources &&
            !selectedSources.some(source => field.available_sources.includes(source))
        ) ||
        !!(
            !field.checked &&
            dataTypeFields?.length &&
            field.available_data_types?.length &&
            !dataTypeFields.some(type => field.available_data_types.includes(type))
        );

    const getGroup = () => {
        const onFieldClickSingle = field => {
            onFieldClick(field, true);
        };

        let ret = null;

        if (group.type === 'multi' || group.type === 'single') {
            ret = (
                <div className={css.fieldsContainer} style={style}>
                    <div>
                        {fields.map(field => (
                            <AnimationItem
                                key={`pose${getFieldKey(field)}`}
                                pose={field.checked ? 'press' : 'init'}
                                style={{ display: 'inline-block' }}
                            >
                                <CheckableField
                                    itemId={field.name}
                                    showInFieldsAvailability={(actualType, visible, name) => {
                                        return showFieldsAvailabilityForField(
                                            showFieldsAvailabilityFeature,
                                            reportType,
                                            actualType,
                                            visible,
                                            name
                                        );
                                    }}
                                    groupType={group.type}
                                    key={getFieldKey(field)}
                                    field={field}
                                    availableSources={field.available_sources}
                                    checkMarkOnSelected={field.ui_configuration?.checkmark_on_selected}
                                    checkedFieldClassName={
                                        CheckableFieldCSS[field.ui_configuration?.checked_field_class_name]
                                    }
                                    fieldClassName={CheckableFieldCSS[field.ui_configuration?.field_class_name]}
                                    selectedSources={selectedSources}
                                    dataTypeFields={dataTypeFields}
                                    shouldShowDisabled={shouldShowFieldAsDisabled(field)}
                                    {...field}
                                    onClick={group.type === 'single' ? onFieldClickSingle : onFieldClick}
                                    onFieldsAvailabilityClick={onFieldsAvailabilityClick}
                                    style={{
                                        ...((group.data && group.data.buttonStyle) || {}),
                                    }}
                                    hideIcon
                                />
                            </AnimationItem>
                        ))}
                        {addButton.show ? <AddButton {...addButton} /> : null}
                    </div>
                    <div>{children}</div>
                </div>
            );
        }
        if (group.type === 'dropdown') {
            ret = (
                <Dropdown
                    disabled={group.disabled}
                    items={fields}
                    onSelection={field => {
                        onFieldClick(field);
                    }}
                    {...group.data}
                />
            );
        }
        if (group.type === 'autocomplete') {
            ret = (
                <AutoComplete
                    onChange={val => {
                        onFieldClick(val);
                    }}
                    {...group.data}
                />
            );
        }
        if (group.type === 'creatable') {
            ret = (
                <AutoComplete
                    isCreatable
                    onChange={option => {
                        let val = '';
                        if (option && option.value) {
                            val = option.value;
                        }
                        onFieldClick(val);
                    }}
                    {...group.data}
                />
            );
        }
        if (group.type === 'tag') {
            ret = (
                <div className={css.tagContainer}>
                    <TagInput
                        containerStyle={{
                            width: '100%',
                            height: 'auto',
                        }}
                        tags={getTags()}
                        disabled={false}
                        suggestions={getSuggestions()}
                        onTagAdded={onTagChange}
                        onTagRemoved={onTagChange}
                        {...group.data}
                    />
                </div>
            );
        }

        if (group.type === 'newTag') {
            const options = [];
            let lastCategory = null;
            fields.forEach(field => {
                if (field.category !== lastCategory) {
                    options.push({ value: field.category, label: field.category, divider: true, isDisabled: true });
                    lastCategory = field.category;
                }
                options.push({ value: field.name, label: field.display_name });
            });
            ret = (
                <div className={css.tagContainer}>
                    <NewTagInput
                        containerStyle={{
                            width: '100%',
                        }}
                        tags={fields
                            .filter(field => field.checked)
                            .map(field => ({
                                label: field.display_name,
                                value: field.name,
                            }))}
                        options={options}
                        isMulti
                        onChange={tags => {
                            // Remove fields that are checked, but not in tags
                            fields.forEach(field => {
                                if (field.checked && tags.find(tag => tag.value === field.name) === undefined) {
                                    onFieldClick(field);
                                }
                            });

                            // Select fields that are in tags, but not checked
                            tags.forEach(tag => {
                                const foundField = fields.find(field => field.name === tag.value);
                                if (!foundField.checked) {
                                    onFieldClick(foundField);
                                }
                            });
                        }}
                        {...group.data}
                    />
                </div>
            );
        }

        if (group.type === 'text') {
            ret = (
                <input
                    className={css.textType}
                    type="text"
                    value={group.value}
                    onChange={e => onFieldClick(e.target.value)}
                    disabled={group.disabled}
                    placeholder={group.placeholder || ''}
                />
            );
        }
        return ret;
    };

    const renderLabel = () => {
        const BasicLabel =
            typeof label === 'string' ? (
                <Translate id={label} /> || label
            ) : (
                <Translate id={label.text} data={label.args} />
            );

        if (labelTooltip) {
            return (
                <Tooltip interactive position="top-start" title={translate(labelTooltip)}>
                    {BasicLabel}
                    <TooltipIcon className={css.tooltip} />
                </Tooltip>
            );
        } else {
            return BasicLabel;
        }
    };

    const containerClass = labelPosition === 'top' ? css.containerLabelTop : css.container;
    const innerStyle = labelTooltip ? { cursor: 'pointer' } : {};
    const isUnifiedReport = reportType === reportTypes.unifiedReport;

    return (
        <React.Fragment>
            {group.skanPlus && (
                <div className={css.skanPlusBorderDivider}>
                    <div className={css.skanPlusContent}>
                        <group.skanPlus.icon />
                        <span className={css.skanPlusContentSpan}>
                            <Translate id={group.skanPlus.topBorderText} />
                        </span>
                    </div>
                </div>
            )}
            {group.topBorder && (
                <div className={css.borderDivider}>
                    {group.topBorderText && (
                        <span className={css.borderDividerSpan}>
                            <Translate id={group.topBorderText} />
                        </span>
                    )}
                </div>
            )}
            {fieldGroupWarning && (
                <div className={classNames(css.warningMessages, css.unifiedReportOutdatedMessage)}>
                    <WarningMessage
                        showSpacer={false}
                        showIcon={false}
                        type="whiteInfo"
                        message={fieldGroupWarning}
                        textReplace={fieldGroupWarningParameters}
                    />
                </div>
            )}
            <div
                className={classNames(containerClass, {
                    [css.topSectorContainer]: isUnifiedReport && group.topSector,
                    [css.fieldsGroupContainer]: isUnifiedReport && !group.topSector,
                })}
                style={containerStyle}
            >
                {group.topSector && (
                    <div className={classNames(css.topSectorHolder, css.label)}>
                        <group.topSector.numberIcon />
                        <div className={css.topSectorText}>{translate(group.topSector.text)}</div>
                        {group.topSector.tooltip && (
                            <Tooltip position="top" title={translate(group.topSector.tooltip)}>
                                <TooltipIcon className={css.unifiedTooltip} />
                            </Tooltip>
                        )}
                    </div>
                )}
                {label && (
                    <div
                        className={css.label}
                        style={{
                            ...labelStyle,
                            ...innerStyle,
                            fontSize: group.fontSize ? group.fontSize : '16px',
                        }}
                    >
                        {renderLabel()}
                        {group.is_new && <sup className={css.supTag}>New</sup>}
                        {group.subText && (
                            <span className={css.groupSubText}>
                                <Translate id={group.subText} />
                            </span>
                        )}
                    </div>
                )}
                <div className={`${css.groupContainer} ${css[group.type]}`}>
                    {renderChildrenOnly ? children : getGroup()}
                    {bottomComponent}
                    {showDownloadReport ? (
                        <div className={css.warningMessages}>
                            <WarningMessage
                                message="STATIC.PAGES.REPORTS.DOWNLOAD_REPORT_MESSAGE"
                                buttonText="STATIC.PAGES.REPORTS.DOWNLOAD_REPORT_BUTTON"
                                buttonAction={onDownloadReport}
                                type="info"
                            />
                        </div>
                    ) : null}
                    {warnings.length ? (
                        <div className={css.warningMessages}>
                            {warnings.map(warning => (
                                <WarningMessage key={warning} message={warning} />
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
            {group.bottomBorder && <div className={css.borderDivider} />}
            {group.skanPlusBottomBorder && <div className={css.skanPlusBorderDivider} />}
        </React.Fragment>
    );
}

FieldGroup.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object),
    onFieldClick: PropTypes.func,
    addButton: PropTypes.objectOf(PropTypes.any),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
    group: PropTypes.objectOf(PropTypes.any),
    warnings: PropTypes.arrayOf(PropTypes.any),
    showDownloadReport: PropTypes.bool,
    labelPosition: PropTypes.string,
    labelTooltip: PropTypes.string,
    labelStyle: PropTypes.objectOf(PropTypes.any),
    containerStyle: PropTypes.objectOf(PropTypes.any),
    renderChildrenOnly: PropTypes.bool,
    reportType: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.element,
    translate: PropTypes.func,
    selectedSources: PropTypes.arrayOf(PropTypes.any),
    dataTypeFields: PropTypes.arrayOf(PropTypes.any),
    fieldGroupWarning: PropTypes.string,
    fieldGroupWarningParameters: PropTypes.objectOf(PropTypes.any),
    onFieldsAvailabilityClick: PropTypes.func,
    onDownloadReport: PropTypes.func,
    showFieldsAvailabilityFeature: PropTypes.bool,
    bottomComponent: PropTypes.element,
};

FieldGroup.defaultProps = {
    renderChildrenOnly: false,
    fields: [],
    onFieldClick: () => {},
    addButton: {
        show: false,
        label: '',
        onClick: () => {},
    },
    label: '',
    group: { type: 'multi', data: {} },
    warnings: [],
    showDownloadReport: false,
    labelPosition: 'left', // Supports 'top' or 'left'
    labelStyle: {},
    containerStyle: {},
    labelTooltip: undefined,
    reportType: 'reports',
    style: {},
    children: null,
    fieldGroupWarning: '',
    fieldGroupWarningParameters: {},
    translate: () => {},
    selectedSources: [],
    dataTypeFields: [],
    onFieldsAvailabilityClick: () => {},
    onDownloadReport: () => {},
    showFieldsAvailabilityFeature: false,
    bottomComponent: null,
};

export default withLocalize(FieldGroup);
