import React from 'react';
import css from 'alerts/components/CustomAlertsShelf.css';
import { Translate } from 'react-localize-redux';
import Dropdown from '../../../components/molecules/Dropdown';
import { DEFAULT_COHORT, DEFAULT_CONDITION, DEFAULT_METRIC, DEFAULT_TIME_PERIOD, OPERATOR_TYPES } from 'alerts/consts';
import { getMetricSymbol, getItemsWithDividers } from 'alerts/utils';
import TextField from '../../../components/molecules/TextField';
import PropTypes from 'prop-types';

function MetricGroup({
    alertsPageMetadata,
    metrics,
    selectedMetric,
    onMetricChanged,
    selectedCohort,
    onCohortChanged,
    selectedCondition,
    onConditionChanged,
    selectedValue,
    onValueChanged,
    selectedTimePeriod,
    onTimePeriodChanged,
}) {
    const isCohort = selectedMetric && selectedMetric.is_cohort;

    const timePeriodItems = selectedCondition
        ? alertsPageMetadata.time_periods.filter((timePeriod) => timePeriod.type === selectedCondition.type)
        : [];

    const _handleConditionChanged = (newCondition) => {
        onConditionChanged(newCondition);
        if (selectedTimePeriod && selectedTimePeriod.type !== newCondition.type) {
            onTimePeriodChanged(null);
        }
    };

    const metricSymbol = getMetricSymbol(selectedMetric, selectedCondition);
    const isMetricAfterValue = metricSymbol.includes('%');

    return (
        <div className={css.metricGroup}>
            <div className={css.inputHeader}>
                <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.WHEN_TITLE" />
            </div>
            <Dropdown
                items={metrics}
                wrapperStyle={{ width: '100%' }}
                containerStyle={{ width: '100%' }}
                selected={selectedMetric || DEFAULT_METRIC}
                selectedContainer={css.dropdown}
                onSelection={onMetricChanged}
            />
            <div className={css.thresholdCondition}>
                {isCohort && (
                    <Dropdown
                        items={alertsPageMetadata.cohort_periods}
                        disabled={!selectedMetric}
                        wrapperStyle={{ width: '150px' }}
                        containerStyle={{ width: '100%' }}
                        selected={selectedCohort || DEFAULT_COHORT}
                        selectedContainer={css.dropdown}
                        onSelection={onCohortChanged}
                    />
                )}
                <Dropdown
                    items={getItemsWithDividers(OPERATOR_TYPES, alertsPageMetadata.operators)}
                    disabled={!selectedMetric}
                    wrapperStyle={{ width: isCohort ? '320px' : '450px' }}
                    containerStyle={{ width: '100%', paddingLeft: isCohort ? '10px' : '0' }}
                    selected={selectedCondition || DEFAULT_CONDITION}
                    selectedContainer={css.dropdown}
                    onSelection={_handleConditionChanged}
                />
                {!isMetricAfterValue && <div className={css.metricFormat}>{metricSymbol}</div>}
                <TextField
                    containerStyle={{
                        width: isMetricAfterValue ? '134px' : '150px',
                        paddingLeft: '20px',
                        paddingBottom: '5px',
                    }}
                    disabled={!selectedMetric}
                    inputConfig={{ maxLength: 8 }}
                    value={selectedValue}
                    containerClass={css.textInputLine}
                    onChange={onValueChanged}
                    placeholder="Value"
                    type="number"
                />
                {isMetricAfterValue && <div className={css.metricFormat}>{metricSymbol}</div>}
            </div>
            <div className={css.inputHeader}>
                <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS_SHELF.TIME_PERIOD" />
            </div>
            <Dropdown
                items={timePeriodItems}
                disabled={!selectedMetric || !selectedCondition}
                wrapperStyle={{ width: '100%' }}
                containerStyle={{ width: '100%', paddingTop: '1px' }}
                selected={selectedTimePeriod || DEFAULT_TIME_PERIOD}
                selectedContainer={css.dropdown}
                onSelection={onTimePeriodChanged}
            />
        </div>
    );
}

MetricGroup.propTypes = {
    alertsPageMetadata: PropTypes.objectOf(PropTypes.any),
    metrics: PropTypes.arrayOf(PropTypes.object),
    selectedMetric: PropTypes.objectOf(PropTypes.any),
    onMetricChanged: PropTypes.func,
    selectedCohort: PropTypes.objectOf(PropTypes.any),
    onCohortChanged: PropTypes.func,
    selectedCondition: PropTypes.objectOf(PropTypes.any),
    onConditionChanged: PropTypes.func,
    selectedValue: PropTypes.string,
    onValueChanged: PropTypes.func,
    selectedTimePeriod: PropTypes.objectOf(PropTypes.any),
    onTimePeriodChanged: PropTypes.func,
};

MetricGroup.defaultProps = {
    alertsPageMetadata: {},
    metrics: [],
    selectedMetric: {},
    onMetricChanged: () => {},
    selectedCohort: {},
    onCohortChanged: () => {},
    selectedCondition: {},
    onConditionChanged: () => {},
    selectedValue: '',
    onValueChanged: () => {},
    selectedTimePeriod: {},
    onTimePeriodChanged: () => {},
};

export default MetricGroup;
