import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { REPORTS_EVENT_PREFIX, trackMixpanelEvent } from 'utils/general';
import classNames from 'classnames';
import css from './TopButtons.css';
import * as ContainersReportsWidgets from '../../containers/reportsWidgets';
import * as ComponentReportsWidgets from '../reportsWidgets';
import Tooltip from '../molecules/Tooltip';

const ReportsWidgets = {
    component: ComponentReportsWidgets,
    container: ContainersReportsWidgets,
};

function TopButtons({
    reportType,
    buttons,
    containerStyle,
    disabled,
    buttonsProps,
    bookmarkCreatedByUser,
    onClick,
}) {
    const [openId, setOpenId] = useState('');

    const handleClick = (e, button) => {
        e.stopPropagation();
        if ((disabled && button.disabledByDefault) || openId !== '') {
            return;
        }
        if (button.reportsWidget) {
            const newId = openId === button.id ? '' : button.id;
            setOpenId(newId);
            trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Top Button Clicked', {
                reportType,
                buttonType: newId,
                queryMode: disabled,
            });

            return;
        }
        onClick(button.id);
    };

    return (
        <div
            className={css.container}
            style={{
                ...containerStyle,
            }}
        >
            {buttons
                .filter(button => {
                    const shouldHide = !!buttonsProps[button.id]?.hide;
                    return !shouldHide;
                })
                .map(button => {
                    const ReportsWidgetClass = button.reportsWidget
                        ? ReportsWidgets[button.reportsWidget.classType][button.reportsWidget.componentClass]
                        : null;
                    const buttonProps = buttonsProps[button.id] || {};
                    const disablePublicReport = button.disableIfNotCreatedByUser && !bookmarkCreatedByUser;
                    const disableButton =
                        (button.disabledByDefault && disabled) || disablePublicReport || buttonProps.disabled;
                    const tooltipTitle = disableButton ? button.disableTooltip : button.tooltip;

                    const buttonEl = (
                        <Fragment key={`button-icon-${button.id}`}>
                            <button.icon
                                className={classNames(css.buttonIcon, { [css.buttonIconWithLabel]: !!button.label })}
                                style={button.style}
                            />
                            <Translate id={button.label} />
                        </Fragment>
                    );

                    return (
                        <div
                            className={css.button}
                            onClick={e => {
                                e.stopPropagation();
                                if (buttonProps.disabled || disablePublicReport) {
                                    return;
                                }
                                handleClick(e, button);
                            }}
                            key={button.id}
                            role="button"
                            tabIndex={0}
                            disabled={buttonProps.disabled || disablePublicReport}
                        >
                            <div
                                className={classNames(css.buttonInner, {
                                    [css.buttonInnerDisabled]: disableButton,
                                    [css.buttonWithLabel]: !!button.label,
                                })}
                            >
                                {tooltipTitle ? (
                                    <Tooltip
                                        style={{ display: 'flex' }}
                                        wrapperClass={css.buttonInner}
                                        titleTranslationKey={tooltipTitle}
                                    >
                                        {buttonEl}
                                    </Tooltip>
                                ) : (
                                    [buttonEl]
                                )}
                            </div>
                            {button.reportsWidget && (
                                <ReportsWidgetClass
                                    show={openId === button.id}
                                    queryMode={disabled}
                                    {...button.reportsWidget.props}
                                    onInnerShowChanged={(show = false) => {
                                        setOpenId(show ? button.id : '');
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
        </div>
    );
}

TopButtons.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.any),
    onClick: PropTypes.func,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    buttonsProps: PropTypes.objectOf(PropTypes.any),
    reportType: PropTypes.string,
    bookmarkCreatedByUser: PropTypes.bool,
};

TopButtons.defaultProps = {
    buttons: [],
    onClick: () => {},
    containerStyle: {},
    disabled: true,
    buttonsProps: {},
    reportType: '',
    bookmarkCreatedByUser: true,
};

export default TopButtons;
