import React, { useEffect, useMemo, useRef, useState } from 'react';
import posed, { PoseGroup } from 'react-pose';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '../../molecules/Tooltip';
import { STATUS_NONE, STATUSES } from '../../../linkManagement/utils';
import { ThreeLoadingDots } from '../../widgets';
import css from './VerticalList.css';
import StarIcon from '../../../resources/svg/icons_small_bookmark.svg';
import StarEmptyIcon from '../../../resources/svg/icons_small_empty_bookmark.svg';
import { sortAlphabetically } from '../../../utils/sortUtil';
import {
    DATA_TEST_ID_FAVOURITE_AREA,
    DATA_TEST_ID_FAVOURITE_ICON_ACTIVE,
    DATA_TEST_ID_FAVOURITE_ICON_INACTIVE,
    DATA_TEST_ID_VERTICAL_LIST,
} from './consts';
import OverflowingText from '../../foundations/OverflowingText';
import DefaultIcon from '../../../resources/svg/icon_new_app.svg';

const AnimationItem = posed.div({
    enter: {
        opacity: 1,
        y: 0,
        delay: ({ i }) => {
            return i * 100;
        },
        transition: {
            y: {
                type: 'tween',
                duration: 300,
                ease: 'anticipate',
            },
        },
    },
    exit: {
        opacity: 0,
        y: -20,
    },
});

export default function VerticalList({
    items,
    filter,
    className,
    selectedItem: selectedItemId,
    onItemSelected,
    sortItems,
    favouriteItems,
    onFavouriteItemClicked,
    enableFavourites,
    defaultIconElement,
    autoSelectItem,
    favouriteTooltip,
    disableAnimation,
    showLastUpdate,
}) {
    const itemRefs = useRef([]);
    const scrollToItem = useRef(null);

    const sortedItems = useMemo(() => {
        let itemsList = sortItems(items);

        if (favouriteItems?.length) {
            itemsList = itemsList.sort(
                (a, b) => Number(favouriteItems?.includes(b.id)) - Number(favouriteItems?.includes(a.id))
            );
        }

        const selectedIndex = scrollToItem.current
            ? itemsList.findIndex(({ id }) => id === scrollToItem.current)
            : itemsList.findIndex(({ selected }) => selected);
        if (itemRefs.current?.[selectedIndex]) {
            itemRefs.current[selectedIndex].scrollIntoView({ behavior: 'smooth' });
        }
        scrollToItem.current = null;

        return itemsList;
    }, [favouriteItems, items, sortItems]);

    useEffect(() => {
        if (!autoSelectItem) {
            return;
        }

        const isSelectedItem = !!sortedItems.find(({ selected, id }) => !!selected || id === selectedItemId);

        if (!isSelectedItem && sortedItems.length) {
            onItemSelected(sortedItems[0].id);
        }
    }, [selectedItemId, sortedItems]);

    const handleFavouriteItemClicked = (e, favourite, id, name) => {
        onFavouriteItemClicked(e, favourite, id, name);
        scrollToItem.current = id;
    };

    const itemsElement = sortedItems.map((item, i) => {
        const itemElement = (
            <VerticalListItem
                {...item}
                filter={filter}
                selected={selectedItemId ? item.id === selectedItemId : item.selected}
                favourite={favouriteItems?.includes(item.id)}
                onClick={onItemSelected}
                onFavouriteItemClicked={handleFavouriteItemClicked}
                showFavourites={enableFavourites}
                defaultIconElement={defaultIconElement}
                favouriteTooltip={favouriteTooltip}
                showLastUpdate={showLastUpdate}
                itemRefs={itemRefs}
                index={i}
            />
        );

        return disableAnimation ? (
            <div key={item.id}>{itemElement}</div>
        ) : (
            <AnimationItem key={item.id} i={i}>
                {itemElement}
            </AnimationItem>
        );
    });
    return (
        <div className={className} data-testid={DATA_TEST_ID_VERTICAL_LIST}>
            {disableAnimation ? itemsElement : <PoseGroup animateOnMount>{itemsElement}</PoseGroup>}
        </div>
    );
}

VerticalList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any),
    filter: PropTypes.string,
    className: PropTypes.string,
    selectedItem: PropTypes.number,
    onItemSelected: PropTypes.func,
    sortItems: PropTypes.func,
    favouriteItems: PropTypes.arrayOf(PropTypes.number),
    enableFavourites: PropTypes.bool,
    defaultIconElement: PropTypes.elementType,
    autoSelectItem: PropTypes.bool,
    favouriteTooltip: PropTypes.string,
    onFavouriteItemClicked: PropTypes.func,
    disableAnimation: PropTypes.bool,
    showLastUpdate: PropTypes.bool,
};

VerticalList.defaultProps = {
    items: [],
    filter: '',
    className: '',
    selectedItem: null,
    onItemSelected: () => {},
    sortItems: items => items.sort((a, b) => sortAlphabetically(a.name, b.name, false, false)),
    favouriteItems: [],
    enableFavourites: true,
    defaultIconElement: DefaultIcon,
    autoSelectItem: false,
    favouriteTooltip: 'STATIC.TOOLTIPS.MARK_FAVOURITE_APP',
    onFavouriteItemClicked: () => {},
    disableAnimation: false,
    showLastUpdate: true,
};

function VerticalListItem({
    name,
    content,
    rightSection,
    icon,
    lastUpdate,
    selected,
    filter,
    status,
    favourite,
    onClick,
    id,
    onFavouriteItemClicked,
    defaultIconElement: Component,
    favouriteTooltip,
    showLastUpdate,
    itemRefs,
    index,
}) {
    return (
        <div
            ref={el => {
                itemRefs.current[index] = el;
            }}
            className={classNames(css.container, css[status], {
                [css.selected]: selected,
            })}
            onClick={() => {
                !selected && onClick(id);
            }}
            role="button"
            tabIndex={0}
            key={index}
        >
            <div className={css.leftSection}>
                <div className={css.favouriteArea} data-testid={DATA_TEST_ID_FAVOURITE_AREA}>
                    {favourite ? (
                        <StarIcon
                            className={css.favourite}
                            onClick={e => onFavouriteItemClicked(e, favourite, id, name)}
                            data-testid={DATA_TEST_ID_FAVOURITE_ICON_ACTIVE}
                        />
                    ) : (
                        <Tooltip titleTranslationKey={favouriteTooltip}>
                            <StarEmptyIcon
                                className={css.emptyFavourite}
                                onClick={e => onFavouriteItemClicked(e, favourite, id, name)}
                                data-testid={DATA_TEST_ID_FAVOURITE_ICON_INACTIVE}
                            />
                        </Tooltip>
                    )}
                </div>
                <div className={css.iconArea}>
                    {icon ? <img className={css.icon} src={icon} alt={name} /> : <Component className={css.icon} />}
                </div>
                <div className={css.contentArea}>
                    <OverflowingText className={css.title}>
                        <Highlighter
                            searchWords={[filter]}
                            textToHighlight={name}
                            highlightClassName={css.highlighted}
                            autoEscape
                        />
                    </OverflowingText>
                    {content || lastUpdate ? (
                        <>
                            {content}
                            {lastUpdate && showLastUpdate && <div className={css.footer}>{lastUpdate}</div>}
                        </>
                    ) : (
                        <ThreeLoadingDots />
                    )}
                </div>
            </div>
            {rightSection && <div className={css.rightSection}>{rightSection}</div>}
        </div>
    );
}

VerticalListItem.propTypes = {
    name: PropTypes.string,
    content: PropTypes.objectOf(PropTypes.any),
    rightSection: PropTypes.objectOf(PropTypes.any),
    icon: PropTypes.string,
    id: PropTypes.number,
    lastUpdate: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    filter: PropTypes.string,
    status: PropTypes.oneOf(STATUSES),
    favourite: PropTypes.bool,
    onFavouriteItemClicked: PropTypes.func,
    defaultIconElement: PropTypes.elementType.isRequired,
    favouriteTooltip: PropTypes.string,
    showLastUpdate: PropTypes.bool,
    itemRefs: PropTypes.shape({
        current: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    }).isRequired,
    index: PropTypes.number.isRequired,
};

VerticalListItem.defaultProps = {
    name: '',
    content: null,
    rightSection: null,
    icon: '',
    id: -1,
    lastUpdate: '',
    selected: false,
    onClick: () => {},
    filter: '',
    status: STATUS_NONE,
    favourite: false,
    onFavouriteItemClicked: () => {},
    favouriteTooltip: null,
    showLastUpdate: true,
};
