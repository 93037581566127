import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Dropdown from '../molecules/Dropdown';
import css from './DimensionsChartDataFilter.css';
import { trackMixpanelEvent } from '../../utils/general';
import { CAMPAIGN_REPORT_MIXPANEL_PREFIX } from '../../quickReports/consts';

const DimensionsChartDataFilter = ({ dimension, dimensions, onChange, widgetName }) => {
    return (
        <div className={css.dimensionsFilterContainer}>
            <span className={css.dimensionsFilterLabel}>
                <Translate id="STATIC.SHOW_CHART_TOP_ROWS4" />
            </span>
            <Dropdown
                containerClass={css.dimensionsFilterDropdown}
                popperClass={css.dimensionsFilterDropdown}
                selected={{ name: dimension.name, display_name: dimension.displayName }}
                onSelection={selectedValue => {
                    onChange(selectedValue);
                    trackMixpanelEvent(CAMPAIGN_REPORT_MIXPANEL_PREFIX, 'Selected dimension value', {
                        selected_value: selectedValue.name,
                        widget_name: widgetName,
                    });
                }}
                items={dimensions.map(({ name, displayName }) => ({ name, display_name: displayName }))}
            />
        </div>
    );
};

DimensionsChartDataFilter.propTypes = {
    dimension: PropTypes.shape({ name: PropTypes.string.isRequired, displayName: PropTypes.string.isRequired })
        .isRequired,
    dimensions: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string.isRequired, displayName: PropTypes.string.isRequired }).isRequired
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    widgetName: PropTypes.string.isRequired,
};

export default DimensionsChartDataFilter;
