import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from '../organisms/Table.css';
import Tooltip from '../molecules/Tooltip';
import PlusIcon from '../../resources/svg/plus_square.svg';
import XIcon from '../../resources/svg/icon_remove_row.svg';

const TableRowActions = ({
    onDelete,
    isDeleteDisabled,
    onAdd,
    isAddDisabled,
    addDisabledTooltip,
    isShowAdd,
    isShowDelete,
    containerStyle,
}) => {
    const plusIconElement = (
        <PlusIcon
            className={classNames(css.plusIconContainer, {
                [css.disabled]: isAddDisabled,
            })}
            onClick={onAdd}
        />
    );

    return (
        <div className={css.editButtons} style={containerStyle}>
            {isShowDelete && (
                <XIcon className={classNames(css.xIcon, { [css.disabled]: isDeleteDisabled })} onClick={onDelete} />
            )}
            {isShowAdd &&
                (addDisabledTooltip ? (
                    <Tooltip titleTranslationKey={addDisabledTooltip} disabled={!isAddDisabled}>
                        {plusIconElement}
                    </Tooltip>
                ) : (
                    plusIconElement
                ))}
        </div>
    );
};

TableRowActions.propTypes = {
    onDelete: PropTypes.func,
    isDeleteDisabled: PropTypes.bool,
    onAdd: PropTypes.func,
    isAddDisabled: PropTypes.bool,
    addDisabledTooltip: PropTypes.string,
    isShowAdd: PropTypes.bool,
    isShowDelete: PropTypes.bool,
    containerStyle: PropTypes.objectOf(PropTypes.any),
};

TableRowActions.defaultProps = {
    onDelete: undefined,
    isDeleteDisabled: false,
    onAdd: undefined,
    isAddDisabled: false,
    addDisabledTooltip: undefined,
    isShowAdd: true,
    isShowDelete: true,
    containerStyle: {},
};

export default TableRowActions;
