import React, { useMemo, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import SDKSectionsWrapper from '../../SdkSectionsWrapper/SDKSectionsWrapper';
import { SDK_INTEGRATION_SECTIONS, TARGET_PLATFORM_SHORT_NAME } from '../../../consts';
import Dropdown from '../../../../../components/molecules/Dropdown';
import { useSDKPlanningContext } from '../../../context/useSDKPlanningContext';
import css from './AdMonetization.css';
import { MEDIATORS, MEDIATORS_DISPLAY_NAME } from './consts';
import { sendMixpanelEventSdkIntegration } from '../../../utils';
import { getSectionTranslateID, translateExists } from '../../../sectionsWrapper/utils';
import { useSDKCodeSnippetContext } from '../../../codeSnippets/context/useSDKCodeSnippetContext';
import { getAdMonCodeSnippet } from '../../../codeSnippets/codeGeneratorUtils';
import { Label, Step } from '../../../../../components/widgets';
import useMixpanelEventOnPageLoad from '../../../../hooks/useMixpanelEventOnPageLoad';
import { findMediatorItem, getMediatorChoices } from './utils';

const AD_MONETIZATION_MEDIATOR_MAX_STEPS = 3;
const OTHER_MEDIATOR_INDEX = 3;
const { AD_MONETIZATION } = SDK_INTEGRATION_SECTIONS;

const AdMonetization = ({ translate }) => {
    const { sdkConfigChoicesMetadata, sdkConfigForPlanning, selectedTargetPlatform } = useSDKPlanningContext();
    const { setActiveCodeSnippet } = useSDKCodeSnippetContext();
    const platform = TARGET_PLATFORM_SHORT_NAME[selectedTargetPlatform];

    const mediatorChoices = useMemo(() => {
        const choices = getMediatorChoices(sdkConfigChoicesMetadata);

        choices.splice(OTHER_MEDIATOR_INDEX, 0, {
            display_name: MEDIATORS_DISPLAY_NAME[MEDIATORS.TRADPLUS],
            name: MEDIATORS.TRADPLUS,
        });

        return choices;
    }, [sdkConfigChoicesMetadata]);

    const [selectedMediator, setSelectedMediator] = useState(
        findMediatorItem(mediatorChoices, sdkConfigForPlanning, selectedTargetPlatform)
    );

    useMixpanelEventOnPageLoad(AD_MONETIZATION, selectedTargetPlatform);

    const onSelection = selectedItem => {
        setActiveCodeSnippet(getAdMonCodeSnippet(selectedItem.name));
        setSelectedMediator(selectedItem);
        sendMixpanelEventSdkIntegration('SELECT_MEDIATOR', AD_MONETIZATION, platform);
    };

    return (
        <SDKSectionsWrapper sectionKey={AD_MONETIZATION}>
            <>
                <Label
                    className={css.dropdownLabel}
                    type="sideLabel"
                    text="STATIC.PAGES.SDK.SDK_PLANNING.SECTIONS.AD_MONETIZATION_MEDIATOR"
                />
                <Dropdown
                    items={mediatorChoices}
                    onSelection={onSelection}
                    selected={selectedMediator}
                    containerClass={css.dropdown}
                    bodyClassName={css.dropdownBody}
                />
                <div className={css.stepsContainer}>
                    {Array.from({ length: AD_MONETIZATION_MEDIATOR_MAX_STEPS }, (_, i) => i + 1).map(step => {
                        const translateId = getSectionTranslateID(
                            `${AD_MONETIZATION}.${selectedMediator.name}`,
                            `STEP_${step}`,
                            platform
                        );
                        if (translateExists(translate, translateId)) {
                            return <Step title={translateId} stepNumber={step} key={translateId} />;
                        } else {
                            return null;
                        }
                    })}
                </div>
            </>
        </SDKSectionsWrapper>
    );
};

AdMonetization.propTypes = {
    translate: PropTypes.func.isRequired,
};

export default withLocalize(AdMonetization);
