import PropTypes from 'prop-types';
import React from 'react';
import AddFutureCheckbox from 'teamManagement/containers/AddFutureCheckbox';
import IconMobile from 'resources/svg/icon_mobile.svg';
import AutoComplete from '../../components/molecules/AutoComplete';
import FauxButton from 'components/widgets/FauxButton';
import Wizardable from 'components/widgets/Wizardable';
import WizardFooter from 'components/widgets/WizardFooter';
import PicturizedMultiSelect from 'teamManagement/components/PicturizedMultiselect';
import css from 'teamManagement/components/SourceSelection.css';

class AppSelection extends Wizardable {
    constructor(props) {
        super(props);
        this.state = {
            appFilter: '',
        };
        this.selectAll = this.selectAll.bind(this);
        this.onSearchChanged = this.onSearchChanged.bind(this);
        this._textPredicate = this._textPredicate.bind(this);
        this.buttonOverrides = props.wizardOverrideButtons;
    }

    selectAll(value) {
        const { apps, onChange, enableAddFuture } = this.props;
        onChange(apps.map((app) => app.id), value);
        enableAddFuture();
    }

    onItemSelected = (id, value) => {
        const { onChange } = this.props;
        onChange([id], value);
    }

    onSearchChanged(val) {
        this.setState({ appFilter: val });
    }

    _textPredicate(app) {
        const { appFilter } = this.state;
        return app.name.toLocaleLowerCase().includes(appFilter.toLocaleLowerCase().trim());
    }

    validate() {
        const { apps, translate } = this.props;
        const isValid = apps.filter((a) => a.selected).length;

        const tooltipButtons = ['next'];
        this.setTooltip(
            tooltipButtons,
            isValid
                ? null
                : translate('STATIC.PAGES.TEAM_MEMBERS.SELECT_AT_LEAST_ONE_TOOLTIP', {
                      itemType: 'app',
                  })
        );

        return isValid;
    }

    render() {
        const { apps, onChange, lastAction } = this.props;
        const { appFilter } = this.state;
        const selected = apps.filter((a) => a.selected).length;
        const total = apps.length;
        return (
            <React.Fragment>
                <this.AnimationItem initialPose={lastAction} pose="visible" className={css.page}>
                    <div>
                        <div className={css.headline}>
                            <div className={css.preamble}>
                                <div>Select apps data to be available for this user.</div>
                                <div className={css.faqLine}>
                                    For more information{' '}
                                    <a
                                        href="https://singular.zendesk.com/hc/en-us/articles/360020835952-Team-Management-FAQ"
                                        target="_blank"
                                    >
                                        read the FAQ
                                    </a>
                                    .
                                </div>
                            </div>
                            <div className={css.selectorContainer}>
                                <div className={css.counter}>{`${selected} of ${total} selected`}</div>
                                <div className={css.vSeperator} />
                                <FauxButton
                                    onClick={() => this.selectAll(selected !== total)}
                                    containerStyle={{ padding: 0 }}
                                >
                                    {selected !== total ? 'Select ' : 'Deselect '} All
                                </FauxButton>
                            </div>
                        </div>
                        <AutoComplete
                            placeholder="Type to search an app"
                            containerStyle={{
                                flexBasis: '960px',
                                marginRight: '20px',
                                marginBottom: '30px',
                                width: '344px',
                            }}
                            selectOptions={{
                                options: [],
                                noOptionsMessage: () => null,
                                isSearchable: true,
                                inputValue: appFilter,
                            }}
                            onInputChange={this.onSearchChanged}
                            controlledValue={appFilter}
                        />
                    </div>
                    <PicturizedMultiSelect
                        containerStyle={{ flexShrink: 1, overflow: 'scroll' }}
                        eivarim={apps.filter(this._textPredicate)}
                        fallback={<IconMobile className={css.fallbackIcon} />}
                        onChange={this.onItemSelected}
                    />
                </this.AnimationItem>
                <WizardFooter
                    buttons={this.getButtons()}
                    leftComponents={<AddFutureCheckbox itemType="apps" form="app" dimensionFilter />}
                />
            </React.Fragment>
        );
    }
}

AppSelection.propTypes = {
    apps: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    enableAddFuture: PropTypes.func.isRequired,
    wizardOverrideButtons: PropTypes.objectOf(PropTypes.any),
    translate: PropTypes.func,
};

AppSelection.defaultProps = {
    apps: [],
    onChange: () => {},
    wizardOverrideButtons: {},
    translate: (x) => x,
};

export default AppSelection;
