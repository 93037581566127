import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TARGET_PLATFORMS_ALL_DROPDOWN_ITEMS } from '../consts';
import Dropdown from '../../../components/molecules/Dropdown';
import css from './SDKPlanningSelections.css';
import { Label } from '../../../components/widgets';
import { useSDKPlanningContext } from '../context/useSDKPlanningContext';
import { useSDKPageContext } from '../../context/useSDKPageContext';
import { sendMixpanelEventSdkIntegration } from '../utils';
import { SDK_INTEGRATION_MIXPANEL_EVENTS } from '../../consts';
import useSdkPlanningLocalStorage from '../hooks/useSdkPlanningLocalStorage';

const SDKPlanningSelections = ({ className }) => {
    const { selectedTargetPlatform, setSelectedTargetPlatform } = useSDKPlanningContext();
    const { localStorageSdkPlanningPage, onChangePlatform } = useSdkPlanningLocalStorage();

    const { showEmptyState } = useSDKPageContext();

    const initialSelectedTargetPlatformItem = useMemo(() => {
        if (localStorageSdkPlanningPage?.platform) {
            return TARGET_PLATFORMS_ALL_DROPDOWN_ITEMS.find(
                item => item.name === localStorageSdkPlanningPage?.platform
            );
        }
        return TARGET_PLATFORMS_ALL_DROPDOWN_ITEMS.find(item => item.name === selectedTargetPlatform);
    }, [localStorageSdkPlanningPage?.platform]);

    const onSelection = item => {
        setSelectedTargetPlatform(item.name);
        onChangePlatform(item.name);
        sendMixpanelEventSdkIntegration(
            SDK_INTEGRATION_MIXPANEL_EVENTS.TARGET_PLATFORM_SELECTED,
            '',
            selectedTargetPlatform
        );
    };

    useEffect(() => {
        if (localStorageSdkPlanningPage?.platform) {
            setSelectedTargetPlatform(localStorageSdkPlanningPage?.platform);
        }
    }, []);

    return (
        <div className={className}>
            <div className={css.dropdownWithLabel}>
                <Label
                    text="STATIC.PAGES.SDK.SDK_PLANNING.PLANNING_SELECT_KEYS.TARGET_PLATFORM"
                    type="sideLabel"
                    className={css.labelContainer}
                />
                <Dropdown
                    items={TARGET_PLATFORMS_ALL_DROPDOWN_ITEMS}
                    onSelection={onSelection}
                    selected={initialSelectedTargetPlatformItem}
                    containerClass={css.dropdownContainer}
                    iconClassName={css.dropdownIcon}
                    disabled={showEmptyState}
                />
            </div>
        </div>
    );
};

SDKPlanningSelections.propTypes = {
    className: PropTypes.string,
};

SDKPlanningSelections.defaultProps = {
    className: '',
};
export default SDKPlanningSelections;
