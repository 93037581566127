import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';

import { Skeleton } from '@mui/material';
import { CreativeType } from '../../types';
import css from './CreativesLayout.css';
import SortByIcon from '../../../resources/icons/sort_by.svg';
import CreativesGrid from '../creativesGrid/CreativesGrid';
import Select from '../../../components/widgets/Select';
import { AutoCompleteOptionType } from '../../../components/molecules/AutoCompleteField';
import { useUrlParams } from '../../../utils/useUrlParamsHook';
import { isObjectEmpty } from '../../../utils/general';

export const SORT_TYPE_ICON_TITLE = 'sortTypeIcon';
export const SORT_SELECT_LABEL = 'STATIC.PAGES.TAGS_VISIBILITY.GRID_HEADER.SORT_SELECT_LABEL';
export const CREATIVES_AMOUNT_TEXT = 'STATIC.PAGES.TAGS_VISIBILITY.GRID_HEADER.CREATIVES_AMOUNT_TEXT';

export const sortCreativeByMetric = (sortBy, isDescSort) =>
    isDescSort
        ? (firstCreative, secondCreative) => secondCreative.metrics[sortBy] - firstCreative.metrics[sortBy]
        : (firstCreative, secondCreative) => firstCreative.metrics[sortBy] - secondCreative.metrics[sortBy];

const CreativesLayout = forwardRef(
    (
        {
            creatives,
            onCreativeClick,
            isLoading,
            isLoadingDimensions,
            selectedMetrics,
            metricsOptions,
            onSortChange: onSortChangeProp,
        },
        ref
    ) => {
        const { getParamsObject, setParamsObject } = useUrlParams();
        const [sortBy, setSortBy] = useState();
        const [isDescSort, setIsDescSort] = useState(true);

        useEffect(() => {
            const urlParams = getParamsObject();

            if (isObjectEmpty(urlParams)) return;

            const { sortBy: urlSortBy, isDescSort: urlIsDescSort } = urlParams;

            if (urlSortBy) {
                setSortBy(urlSortBy);
            } else {
                setParamsObject({ sortBy: selectedMetrics[0].value });
            }

            if (urlIsDescSort !== undefined) {
                setIsDescSort(urlIsDescSort);
            } else {
                setParamsObject({ isDescSort });
            }
        }, []);

        useEffect(() => {
            const selectedMetricsValues = selectedMetrics.map(({ value }) => value);
            setSortBy(prevSortBy => {
                return selectedMetricsValues.includes(prevSortBy) ? prevSortBy : selectedMetricsValues[0];
            });
        }, [selectedMetrics]);

        const onSortChange = value => {
            onSortChangeProp(value);
            setSortBy(value);
            setParamsObject({ sortBy: value });
        };

        const sortedCreatives = useMemo(() => [...creatives].sort(sortCreativeByMetric(sortBy, isDescSort)), [
            sortBy,
            isDescSort,
            creatives,
        ]);

        const onSortDirectionChange = () => {
            const newDescValue = !isDescSort;
            setIsDescSort(newDescValue);
            setParamsObject({ isDescSort: newDescValue });
        };

        return (
            <>
                {isLoading ? (
                    <Skeleton variant="text" className={css.skeleton} />
                ) : (
                    <div className={css.header}>
                        <Translate id={CREATIVES_AMOUNT_TEXT} data={{ amount: creatives.length }} />
                        <div className={css.sortSection}>
                            <div className={css.sortDirectionContainer}>
                                <SortByIcon
                                    className={classNames(css.sortDirectionIcon, { [css.desc]: !isDescSort })}
                                    onClick={onSortDirectionChange}
                                    title={SORT_TYPE_ICON_TITLE}
                                />
                            </div>
                            <Select
                                useMaterialUI
                                value={sortBy}
                                onChange={onSortChange}
                                options={metricsOptions}
                                label={SORT_SELECT_LABEL}
                            />
                        </div>
                    </div>
                )}
                <CreativesGrid
                    ref={ref}
                    creatives={sortedCreatives}
                    onCreativeClick={onCreativeClick}
                    isLoading={isLoading}
                    isLoadingDimensions={isLoadingDimensions}
                    selectedMetrics={selectedMetrics}
                />
            </>
        );
    }
);

CreativesLayout.propTypes = {
    creatives: PropTypes.arrayOf(PropTypes.shape(CreativeType)),
    onCreativeClick: PropTypes.func,
    selectedMetrics: PropTypes.arrayOf(AutoCompleteOptionType),
    metricsOptions: PropTypes.arrayOf(AutoCompleteOptionType),
    isLoading: PropTypes.bool.isRequired,
    isLoadingDimensions: PropTypes.bool,
    onSortChange: PropTypes.func,
};

CreativesLayout.defaultProps = {
    creatives: [],
    selectedMetrics: [],
    metricsOptions: [],
};

export default CreativesLayout;
