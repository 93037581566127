import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import Tooltip from '../molecules/Tooltip';
import css from './TableCellImgLabel.css';
import NoLogoIcon from '../../../../dashboard/img/icons/no_logo.svg';
import Avatar from '../../resources/svg/avatar_small.svg';

const TableCellImgLabel = ({
    tdStyle,
    tdClassName,
    markCell,
    markCellTooltipText,
    containerStyle,
    imgSrc,
    imgStyle,
    imgClassName,
    SideImg,
    sideImgHoverText,
    labelsStyle,
    labelsClassName,
    searchText,
    value,
    subTextStyle,
    subTextClassName,
    subText,
    subTextExtraStyle,
    subTextExtraClassName,
    subTextExtra,
    showImgBadge,
    Icon,
    useDefaultEmptyImg,
    ImgComponent,
    reverseOrder,
    subTextElements,
    showUsernameImg,
    titleClassName,
}) => (
    <td style={tdStyle} className={tdClassName}>
        {markCell && (
            <Tooltip
                open={markCellTooltipText ? null : false}
                placement="top"
                title={markCellTooltipText}
                classes={{ tooltip: css.tooltip, popper: css.opaqueBackground }}
            >
                <div className={css.markCell} />
            </Tooltip>
        )}
        <div className={classNames([css.container, { [css.reversedContainer]: reverseOrder }])} style={containerStyle}>
            {showImgBadge && <Icon className={classNames('tableCellImgBadge', css.imgBadge)} />}
            {(imgSrc || ImgComponent || useDefaultEmptyImg) && (
                <div className={css.image}>
                    {imgSrc ? (
                        <img alt="" className={imgClassName} style={imgStyle} src={imgSrc} />
                    ) : ImgComponent ? (
                        <ImgComponent alt="" className={imgClassName} style={imgStyle} />
                    ) : (
                        <NoLogoIcon style={imgStyle} className={imgClassName} />
                    )}
                </div>
            )}
            <div className={classNames([css.labels, labelsClassName])} style={labelsStyle}>
                <div title={value} className={titleClassName}>
                    <Highlighter
                        highlightClassName={css.highlighted}
                        searchWords={[searchText]}
                        textToHighlight={value || ''}
                        autoEscape
                        className={css.ellipsis}
                    />
                </div>
                <div className={css.subTextContainer}>
                    {showUsernameImg ? (
                        <span className={css.avatar}>
                            <Avatar />
                        </span>
                    ) : null}
                    <Highlighter
                        unhighlightStyle={subTextStyle}
                        highlightStyle={subTextStyle}
                        unhighlightClassName={subTextClassName}
                        highlightClassName={subTextClassName || css.highlighted}
                        searchWords={[searchText]}
                        textToHighlight={subText || ''}
                        autoEscape
                        className={`${subText ? '' : css.noSubTextExtra} ${css.subTextContainer}`}
                    />
                </div>
                <div className={css.subTextContainer}>
                    {subTextElements}
                    <Highlighter
                        unhighlightStyle={subTextExtraStyle}
                        highlightStyle={subTextExtraStyle}
                        unhighlightClassName={subTextExtraClassName}
                        highlightClassName={subTextExtraClassName || css.highlighted}
                        searchWords={[searchText]}
                        textToHighlight={subTextExtra || ''}
                        autoEscape
                        className={css.ellipsis}
                    />
                </div>
                {SideImg && sideImgHoverText && (
                    <div className={css.image}>
                        <Tooltip wrapperClass={css.sideImg} title={sideImgHoverText}>
                            <SideImg />
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    </td>
);

TableCellImgLabel.propsTypes = {
    imgStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    imgSrc: PropTypes.string,
    searchWords: PropTypes.string,
    markCell: PropTypes.bool,
    bottomMarkCell: PropTypes.bool,
    useDefaultEmptyImg: PropTypes.bool,
    ImgComponent: PropTypes.object,
    reverseOrder: PropTypes.bool,
    labelsClassName: PropTypes.object,
    subTextExtraClassName: PropTypes.object,
    subTextElements: PropTypes.arrayOf(PropTypes.element),
    showUsernameImg: PropTypes.bool,
};

TableCellImgLabel.defaultProps = {
    useDefaultEmptyImg: true,
    reverseOrder: false,
    labelsStyle: {},
    subTextElements: [],
};

export default TableCellImgLabel;
