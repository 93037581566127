import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import css from './SharingLink.css';
import Tooltip from '../../components/molecules/Tooltip';
import TrashIcon from '../../resources/svg/trash.svg';
import EditIcon from '../../resources/svg/edit.svg';
import Icon, { IconSizes } from '../../components/foundations/Icon';

const styles = () => ({
    tooltip: {
        fontSize: 11,
    },
});

const LargeTooltip = withStyles(styles)(Tooltip);

class SharingLink extends React.PureComponent {
    state = {
        isEditing: false,
    };

    constructor(props) {
        super(props);

        this.onCopySharingLink = this._onCopySharingLink.bind(this);
        this.onRevokeSharingLink = this._onRevokeSharingLink.bind(this);
        this.onStartEdit = this._onStartEdit.bind(this);
        this.onEditDone = this._onEditDone.bind(this);
    }

    _onCopySharingLink() {
        this.props.onCopySharingLink(this.props.externalId);
    }

    _onRevokeSharingLink() {
        this.props.onRevokeSharingLink(this.props.externalId);
    }

    _onEditDone() {
        const displayName = this._nameInput.value;
        this.setState({ isEditing: false });

        if (!displayName || displayName === this.props.displayName) {
            return;
        }

        this.props.onRenameSharingLink(this.props.externalId, displayName);
    }

    _onStartEdit() {
        this.setState({ isEditing: true }, () => {
            this._nameInput.focus();
        });
    }

    render() {
        const { displayName } = this.props;
        const { isEditing } = this.state;

        return (
            <div className={css.container}>
                <input
                    type="text"
                    ref={e => {
                        this._nameInput = e;
                    }}
                    onBlur={this.onEditDone}
                    onClick={this.onStartEdit}
                    readOnly={!isEditing}
                    className={classNames(css.linkName, { [css.editing]: isEditing })}
                    defaultValue={displayName}
                />
                <div className={css.buttonsArea}>
                    <LargeTooltip titleTranslationKey="STATIC.PAGES.AUDIENCES.SHARING_OPTIONS_RENAME_TOOLTIP">
                        <div
                            onClick={this.onStartEdit}
                            className={classNames(css.buttonContainer, css.edit, { [css.active]: isEditing })}
                        >
                            <EditIcon />
                        </div>
                    </LargeTooltip>
                    <LargeTooltip titleTranslationKey="STATIC.PAGES.AUDIENCES.SHARING_OPTIONS_COPY_TOOLTIP">
                        <div onClick={this.onCopySharingLink} className={css.buttonContainer}>
                            <Icon name="copy" size={IconSizes.SMALL} />
                        </div>
                    </LargeTooltip>
                    <LargeTooltip titleTranslationKey="STATIC.PAGES.AUDIENCES.SHARING_OPTIONS_REVOKE_TOOLTIP">
                        <div onClick={this.onRevokeSharingLink} className={classNames(css.buttonContainer, css.delete)}>
                            <TrashIcon />
                        </div>
                    </LargeTooltip>
                </div>
            </div>
        );
    }
}

SharingLink.propTypes = {
    displayName: PropTypes.string,
    externalId: PropTypes.string,
    onCopySharingLink: PropTypes.func,
    onRevokeSharingLink: PropTypes.func,
    onRenameSharingLink: PropTypes.func,
};

SharingLink.defaultProps = {
    displayName: '',
    externalId: '',
    onCopySharingLink: () => {},
    onRevokeSharingLink: () => {},
    onRenameSharingLink: () => {},
};

export default SharingLink;
