import React from 'react';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Tooltip from '../molecules/Tooltip';
import css from './ActionButton.css';

class ActionButton extends React.Component {
    constructor(props) {
        super(props);
        this._handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        const { changeTextOnClick, text, textReplace, translate, onClick } = this.props;

        if (changeTextOnClick !== '') {
            this.buttonText.innerText = translate(changeTextOnClick) || text;
            setTimeout(() => {
                this.buttonText.innerText = translate(text, textReplace) || text;
            }, 3000);
        }
        onClick(e);
    }

    render() {
        const {
            newStyle,
            translate,
            text,
            primary,
            containerStyle,
            buttonIconStyle,
            buttonIconClass,
            disabledClass,
            disabled,
            containerClass,
            textReplace,
            icon,
            active,
            activeClass,
            flat,
            cssModifier,
            IconClass,
            tooltipText,
        } = this.props;
        let className;

        if (newStyle) {
            className = primary ? css.primary : css.secondary;
            if (flat) className = css.flat;
        } else {
            className = primary ? css.oldPrimary : css.oldSecondary;
        }

        return (
            <Tooltip titleTranslationKey={tooltipText} position="top" disabled={!tooltipText}>
                <button
                    className={classNames(css.container, className, containerClass, {
                        [activeClass]: active,
                        [css.active]: active,
                        [css[cssModifier]]: cssModifier,
                    })}
                    onClick={this._handleClick}
                    style={{
                        ...containerStyle,
                    }}
                    disabled={disabled}
                >
                    <div
                        className={classNames(css.buttonInner, { [css.disabledText]: disabled, [disabledClass]: disabled })}
                        ref={el => {
                            this.buttonText = el;
                        }}
                    >
                        {IconClass && (
                            <IconClass
                                className={classNames(css.Icon, buttonIconClass, { [disabledClass]: disabled })}
                            />
                        )}
                        {translate(text, textReplace)}
                        {icon && (
                            <FontAwesomeIcon
                                className={classNames(css.icon, buttonIconClass, { [disabledClass]: disabled })}
                                icon={icon}
                                style={buttonIconStyle}
                            />
                        )}
                    </div>
                </button>
            </Tooltip>
        );
    }
}

ActionButton.propTypes = {
    newStyle: PropTypes.bool,
    text: PropTypes.string,
    textReplace: PropTypes.objectOf(PropTypes.any),
    onClick: PropTypes.func,
    primary: PropTypes.bool,
    flat: PropTypes.bool,
    disabled: PropTypes.bool,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    containerClass: PropTypes.string,
    cssModifier: PropTypes.string,
    buttonIconStyle: PropTypes.objectOf(PropTypes.any),
    buttonIconClass: PropTypes.string,
    disabledClass: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    IconClass: PropTypes.func,
    activeClass: PropTypes.string,
    active: PropTypes.bool,
    changeTextOnClick: PropTypes.string,
    translate: PropTypes.func,
    tooltipText: PropTypes.string,
};

ActionButton.defaultProps = {
    newStyle: false,
    text: '',
    textReplace: {},
    onClick: () => {},
    primary: true,
    flat: false,
    disabled: false,
    containerStyle: {},
    containerClass: '',
    cssModifier: undefined,
    buttonIconStyle: {},
    buttonIconClass: '',
    disabledClass: '',
    icon: null,
    IconClass: null,
    active: false,
    activeClass: '',
    changeTextOnClick: '',
    translate: () => {},
    tooltipText: '',
};

export default withLocalize(ActionButton);
