import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from './index';
import Tooltip from '../molecules/Tooltip';

const TableCellCheckbox = ({ tdClassName, className, value, onChange, disabled, tooltipText, tooltipData, id }) => {
    return (
        <td className={tdClassName}>
            <Tooltip titleTranslationKey={tooltipText} titleTranslationData={tooltipData} disabled={!tooltipText}>
                <Checkbox className={className} checked={value} disabled={disabled} onChange={e => onChange(e, id)} />
            </Tooltip>
        </td>
    );
};

TableCellCheckbox.propTypes = {
    tdClassName: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltipText: PropTypes.string,
    tooltipData: PropTypes.objectOf(PropTypes.any),
};

TableCellCheckbox.defaultProps = {
    tdClassName: '',
    className: '',
    onChange: () => {},
    value: false,
    disabled: false,
    tooltipText: null,
    tooltipData: null,
};

export default TableCellCheckbox;
