import React from 'react';
import PropTypes from 'prop-types';
import css from './TopArea.css';
import AutoCompleteField from '../../components/molecules/AutoCompleteField';

// AutoComplete doesn't like not having any options set...
// Can change this to be app names if we'd like
const EMPTY_OPTIONS = [];

function TopArea({ onInputChanged, search, filterPlaceholder, style }) {
    return (
        <div className={css.container}>
            <div>
                <AutoCompleteField
                    containerStyle={style}
                    onInputChange={onInputChanged}
                    onChange={onInputChanged}
                    value={search}
                    placeholder={filterPlaceholder}
                    searchable
                    clearable
                    controlled
                    isMulti={false}
                    debounceTime={500}
                    options={EMPTY_OPTIONS}
                />
            </div>
        </div>
    );
}

TopArea.propTypes = {
    style: PropTypes.objectOf(PropTypes.any),
    onInputChanged: PropTypes.func,
    search: PropTypes.string,
    filterPlaceholder: PropTypes.string,
};

TopArea.defaultProps = {
    style: {},
    onInputChanged: () => {},
    search: '',
    filterPlaceholder: '',
};

export default TopArea;
