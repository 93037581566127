import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/widgets';
import WizardFooter from 'components/widgets/WizardFooter';
import { Translate, withLocalize } from 'react-localize-redux';
import Tooltip from '../../components/molecules/Tooltip';
import Spinner from 'components/widgets/Spinner';
import EmailTagInput from 'alerts/components/EmailTagInput';
import { trackMixpanelEvent } from 'utils/general';
import DataDelayIcon from '../../resources/svg/data_connector_alerts_envelopes/dc_data_delay_alerts.svg';
import LoginIssueIcon from '../../resources/svg/data_connector_alerts_envelopes/dc_login_issue_alerts.svg';
import css from './AlertsShelf.css';
import SlackIcon from '../../resources/svg/icons/slack-icon.svg';
import { SLACK_AUTH, TRACK_EVENT_PREFIX } from '../consts';
import { SLACK_AUTH_URL } from '../../utils/consts';
import { isInvalidEmail } from '../utils';

function AlertsShelf({
    title,
    aboutTitle,
    bottomSectionShelf,
    orgUsers,
    loginAlert,
    dataDelayAlert,
    setAlertsToSave,
    isModifiedFlag,
    setIsModifiedFlag,
    setShouldSaveAlert,
    isAlertSaved,
    isAlertFetched,
    onClose,
    isUserSlackAuth,
    onDeleteUserSlackAuth,
    isUserRestricted,
}) {
    const [showSavingSpinner, setShowSavingSpinner] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [loginAlertEmails, setLoginAlertEmails] = useState([]);
    const [loginAlertSendToAdmins, setLoginAlertSendToAdmins] = useState(false);
    const [dataDelayAlertEmails, setDataDelayAlertEmails] = useState([]);
    const [dataDelayAlertSendToAdmins, setDataDelayAlertSendToAdmins] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(false);

    const closeShelf = () => {
        setShowSuccess(false);
        onClose();
    };

    const onChange = () => {
        setIsModifiedFlag(true);
    };

    const saveAlert = () => {
        setAlertsToSave({
            login: { emails: loginAlertEmails, send_to_admins: loginAlertSendToAdmins },
            dataDelay: { emails: dataDelayAlertEmails, send_to_admins: dataDelayAlertSendToAdmins },
        });
        setShowSuccess(false);
        setShowSavingSpinner(true);
    };

    const onSlackAction = () => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, SLACK_AUTH, {
            isEnabled: !isUserSlackAuth,
        });

        if (isUserSlackAuth) {
            onDeleteUserSlackAuth();
        } else {
            window.open(SLACK_AUTH_URL, '_blank');
        }
    };

    useEffect(() => {
        setIsFormInvalid(isInvalidEmail(loginAlertEmails) || isInvalidEmail(dataDelayAlertEmails));
    }, [loginAlertEmails, dataDelayAlertEmails]);

    useEffect(() => {
        if (isAlertFetched) {
            setShouldSaveAlert(false);
            setLoginAlertEmails(loginAlert.emails);
            setLoginAlertSendToAdmins(loginAlert.send_to_admins);
            setDataDelayAlertEmails(dataDelayAlert.emails);
            setDataDelayAlertSendToAdmins(dataDelayAlert.send_to_admins);
        }
    }, [loginAlert, dataDelayAlert]);

    useEffect(() => {
        if (isModifiedFlag && isAlertSaved) {
            setShowSuccess(true);
            setShowSavingSpinner(false);
            setIsModifiedFlag(false);
        }
    }, [isAlertSaved]);

    if (!isAlertFetched) {
        return <Spinner style={{ top: '10vh' }} show expanded />;
    } else {
        return (
            <Fragment>
                <div className={css.container}>
                    <div className={css.text}>
                        <Translate id={`STATIC.PAGES.ALERTS_V2.${title.toUpperCase()}.INTRO`} />
                    </div>
                    <div className={css.aboutTitle}>{aboutTitle}</div>
                    <div className={css.sectionHeader}>
                        <DataDelayIcon />
                        <div className={css.text}>
                            <Translate id={`STATIC.PAGES.ALERTS_V2.${title.toUpperCase()}.DATA_DELAY_TITLE`} />
                        </div>
                    </div>
                    <EmailTagInput
                        orgUsers={orgUsers}
                        emails={dataDelayAlertEmails}
                        setEmails={setDataDelayAlertEmails}
                        sendToAdmins={dataDelayAlertSendToAdmins}
                        setSendToAdmins={setDataDelayAlertSendToAdmins}
                        onChange={onChange}
                    />
                    <div>
                        <div className={css.slackContainer}>
                            <Tooltip
                                title={
                                    isUserSlackAuth
                                        ? 'Click here to stop receiving the daily Slack alerts'
                                        : 'Get daily alerts on Slack on the status of your Data Connectors'
                                }
                            >
                                <span className={css.slackText} onClick={onSlackAction}>
                                    <SlackIcon className={css.slackIcon} />
                                    {isUserSlackAuth
                                        ? 'Remove data delay alerts on Slack '
                                        : 'Activate data delay alerts on Slack'}
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={css.sectionHeader}>
                        <LoginIssueIcon />
                        <div className={css.text}>
                            <Translate id={`STATIC.PAGES.ALERTS_V2.${title.toUpperCase()}.LOGIN_ERRORS_TITLE`} />
                        </div>
                    </div>
                    <EmailTagInput
                        orgUsers={orgUsers}
                        emails={loginAlertEmails}
                        setEmails={setLoginAlertEmails}
                        sendToAdmins={loginAlertSendToAdmins}
                        setSendToAdmins={setLoginAlertSendToAdmins}
                        onChange={onChange}
                    />
                    {bottomSectionShelf}
                </div>
                {!isUserRestricted && (
                    <WizardFooter
                        leftComponents={[
                            <span key="Saving Emails">
                                {showSavingSpinner && (
                                    <Translate id={`STATIC.PAGES.ALERTS_V2.${title.toUpperCase()}.SAVING_EMAILS`} />
                                )}
                            </span>,
                        ]}
                        buttons={[
                            <Button
                                type="flat"
                                disabled={showSavingSpinner || showSuccess}
                                onClick={closeShelf}
                            >
                                Cancel
                            </Button>,
                            <Button
                                onClick={saveAlert}
                                showSpinner={showSavingSpinner && !showSuccess}
                                showV={showSuccess}
                                disabledDark={!isModifiedFlag || isFormInvalid || showSuccess || showSavingSpinner}
                            >
                                Save
                            </Button>,
                        ]}
                    />
                )}
            </Fragment>
        );
    }
}

AlertsShelf.propTypes = {
    title: PropTypes.string,
    aboutTitle: PropTypes.object,
    bottomSectionShelf: PropTypes.object,
    orgUsers: PropTypes.array,
    loginAlert: PropTypes.object,
    dataDelayAlert: PropTypes.object,
    setAlertsToSave: PropTypes.func,
    isModifiedFlag: PropTypes.bool,
    setIsModifiedFlag: PropTypes.func,
    setShouldSaveAlert: PropTypes.func,
    isAlertSaved: PropTypes.bool,
    isAlertFetched: PropTypes.bool,
    onClose: PropTypes.func,
    isUserSlackAuth: PropTypes.bool,
    onDeleteUserSlackAuth: PropTypes.func,
    isUserRestricted: PropTypes.bool,
};

AlertsShelf.defaultProps = {
    title: '',
    aboutTitle: null,
    bottomSectionShelf: null,
    orgUsers: [],
    loginAlert: {},
    dataDelayAlert: {},
    setAlertsToSave: () => {},
    isModifiedFlag: false,
    setIsModifiedFlag: () => {},
    setShouldSaveAlert: () => {},
    onClose: () => {},
    isAlertSaved: false,
    isAlertFetched: false,
    isUserSlackAuth: false,
    onDeleteUserSlackAuth: () => {},
    isUserRestricted: false,
};

export default withLocalize(AlertsShelf);
