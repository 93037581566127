import React, { useState, useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from '../molecules/Tooltip';
import SortOff from '../../resources/svg/sort_off.svg';
import SortUp from '../../resources/svg/sort_up.svg';
import SortDown from '../../resources/svg/sort_down.svg';
import TooltipIcon from '../../resources/svg/tooltip.svg';
import TableCell from '../widgets/TableCell';
import Checkbox from '../atoms/Checkbox';
import TextField from '../molecules/TextField';
import css from './Table.css';
import { TableWidgetMetadataShape } from '../../global/widgetTypes';

const DEFAULT_HEADER_ALIGNMENT = 'left';

function getJustifyContent(headerAlign) {
    if (headerAlign === 'center') {
        return headerAlign;
    }

    return `flex-${headerAlign === 'right' ? 'end' : 'start'}`;
}

function getColumnTooltipProp(tooltip, tooltipData) {
    // There was a bug where this originally used `title` instead of `titleTranslationKey`
    // But some uses of this now pass an object, which can't be translated.. so this is a backwards-compatibility fix
    return typeof tooltip === 'string'
        ? { titleTranslationKey: tooltip, titleTranslationData: tooltipData }
        : { title: tooltip };
}

const Table = props => {
    const {
        metadata,
        data,
        tableClass,
        thStyle,
        dataTestId,
        actionComponentsConfigs,
        theadClass,
        thFiltersClass,
        thClass,
        tbodyClass,
        trClass,
        highlightRowIndex,
        zebraTable,
        selectable,
        filterable,
        onToggleSelectAll,
        onToggleSingleRow,
        rowHoverEnabled,
        showInnerBorder,
        hasActionComponentCell,
    } = props;

    const tableFilters = {};
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    function isAllRowsSelected() {
        return data.every(row => row.is_selected);
    }

    function filterRows(columnNameToFilter, filterText) {
        tableFilters[columnNameToFilter] = filterText;

        let newFilteredData = filteredData;
        Object.keys(tableFilters).forEach(columnName => {
            newFilteredData = newFilteredData.filter(row => {
                let cellValue = row[columnName];
                if (typeof row[columnName] === 'object' && row[columnName] !== null) {
                    cellValue = row[columnName].name;
                }
                return cellValue.toLowerCase().includes(tableFilters[columnName]);
            });
        });

        setFilteredData(newFilteredData);
    }
    return (
        <table
            data-testid={dataTestId}
            className={classNames(
                css.table,
                tableClass,
                { [css.tableWithRowHover]: rowHoverEnabled },
                { [css.showInnerBorder]: showInnerBorder }
            )}
        >
            <thead className={theadClass}>
                <tr>
                    {selectable && (
                        <th className={thClass} style={{ width: '50px' }}>
                            <div className={css.checkboxCell}>
                                <Checkbox
                                    checked={isAllRowsSelected()}
                                    onChange={checked => onToggleSelectAll(checked)}
                                    style={{ margin: 0 }}
                                    checkmarkStyle={{ margin: 0 }}
                                />
                            </div>
                        </th>
                    )}
                    {metadata
                        .filter(({ subHeader }) => !subHeader)
                        .map(col => (
                            <th
                                key={`col_header_${col.key || col.displayName}`}
                                style={{
                                    textAlign: col.headerAlign || DEFAULT_HEADER_ALIGNMENT,
                                    width: col.headerWidth,
                                    fontWeight: col.boldHeader ? 'bold' : '',
                                    ...thStyle,
                                    ...(col.thStyle || {}),
                                }}
                                className={classNames(col.className, thClass, {
                                    [css.actionsTh]: hasActionComponentCell,
                                })}
                                onClick={() => col.headerClick?.(col?.name)}
                            >
                                <div
                                    className={css.tableHeaderContainer}
                                    style={{ justifyContent: getJustifyContent(col.headerAlign) }}
                                >
                                    {col.HeaderIcon && <col.HeaderIcon className={css.tableHeaderIcon} />}
                                    <Translate className={css.tableHeaderText} id={col.displayName} />
                                    {col.headerTooltip && (
                                        <Tooltip
                                            {...getColumnTooltipProp(col.headerTooltip || '', col.headerTooltipData)}
                                            style={{ alignSelf: 'center' }}
                                        >
                                            <TooltipIcon className={css.tableHeaderTooltipIcon} />
                                        </Tooltip>
                                    )}
                                    {col.showHeaderArrow ? (
                                        <div className={css.tableHeaderArrowContainer}>
                                            {col.headerArrow ? (
                                                col.headerArrow === 'up' ? (
                                                    <SortUp className={css.arrowIcon} />
                                                ) : (
                                                    <SortDown className={css.arrowIcon} />
                                                )
                                            ) : (
                                                <SortOff className={css.sortOff} />
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            </th>
                        ))}
                </tr>
                {filterable && (
                    <tr>
                        {selectable && <th className={thFiltersClass} />}
                        {metadata.map(col => (
                            <th
                                key={`col_filter_${col.displayName}`}
                                className={classNames(css.filterCell, thFiltersClass)}
                            >
                                <TextField
                                    placeholder={`Search ${col.placeholder}`}
                                    onChange={text => filterRows(col.name, text)}
                                />
                            </th>
                        ))}
                    </tr>
                )}
            </thead>
            <tbody className={tbodyClass}>
                {filteredData.map((row, i) => (
                    <tr
                        className={classNames(
                            { [css.highlightRow]: i === highlightRowIndex },
                            {
                                [css.rowActionsHover]:
                                    (hasActionComponentCell || actionComponentsConfigs.length) && !row.disabled,
                            },
                            { [css.zebraRows]: zebraTable },
                            { [css.disabledRow]: row.disabled },
                            row.trClass,
                            trClass
                        )}
                        key={row.key || i}
                    >
                        {selectable && (
                            <td style={{ width: '50px' }}>
                                <div className={css.checkboxCell}>
                                    <Checkbox
                                        checked={row.is_selected}
                                        onChange={checked => onToggleSingleRow(checked, row)}
                                        style={{ margin: 0 }}
                                        checkmarkStyle={{ margin: 0 }}
                                        disabled={row.disabled}
                                    />
                                </div>
                            </td>
                        )}
                        {[
                            metadata
                                .filter(({ subHeader }) => {
                                    return row.subHeaderText ? subHeader : !subHeader;
                                })
                                .map((col, j) => {
                                    const CellComponent = col.getCellComponent?.(row) || col.cellComponent || TableCell;
                                    const cellValues = {};
                                    if (col.cellValues) {
                                        col.cellValues.forEach(cellValue => {
                                            if (typeof cellValue === 'object') {
                                                const { propName, dataKey, transformation } = cellValue;
                                                cellValues[propName] = row[dataKey];
                                                if (transformation) {
                                                    cellValues[propName] = transformation(cellValues[propName], i);
                                                }
                                            } else {
                                                cellValues[cellValue] = row[cellValue];
                                            }
                                        });
                                    }

                                    const ChildrenComponent = col.childrenComponent || null;
                                    const childrenComponentProps = {};
                                    if (col.childrenComponentProps && col.childrenComponentProps.length) {
                                        col.childrenComponentProps.forEach(prop => {
                                            childrenComponentProps[prop.propName] = row[prop.dataKey];
                                        });
                                    }
                                    return ChildrenComponent ? (
                                        <CellComponent
                                            key={(row.key || i) + (col.key || col.displayName)}
                                            id={row.key || i}
                                            rowIndex={i}
                                            value={row[col.name]}
                                            onChange={value => onToggleSingleRow(value, row, col.name, i)}
                                            className={col.className}
                                            row={row}
                                            disabled={row.disabled || col.disabled}
                                            {...cellValues}
                                            {...col.cellEvents}
                                            {...col.cellProps}
                                        >
                                            <ChildrenComponent {...childrenComponentProps} />
                                        </CellComponent>
                                    ) : (
                                        <CellComponent
                                            key={(row.key || i) + (col.key || col.displayName)}
                                            id={row.key || i}
                                            rowIndex={i}
                                            value={row[col.name]}
                                            subValue={row[col.subValue]}
                                            isBold={col.isBold}
                                            onChange={value => onToggleSingleRow(value, row, col.name, i)}
                                            className={col.className}
                                            row={row}
                                            disabled={row.disabled || col.disabled}
                                            {...cellValues}
                                            {...col.cellEvents}
                                            {...col.cellProps}
                                        />
                                    );
                                }),
                            actionComponentsConfigs.length > 0 ? (
                                <td className={css.actions} key={`${row.key || i}_actions`}>
                                    <div>
                                        {actionComponentsConfigs.map(
                                            ({
                                                ActionComponent,
                                                onClick,
                                                onMouseEnter,
                                                onMouseLeave,
                                                key,
                                                title,
                                                shouldShow = () => true,
                                                tooltipText,
                                                badge,
                                                shouldTranslateToolTip,
                                                className,
                                            }) => {
                                                return shouldShow(row) ? (
                                                    <Tooltip
                                                        open={tooltipText ? null : false}
                                                        title={tooltipText}
                                                        titleTranslationKey={
                                                            shouldTranslateToolTip ? tooltipText : null
                                                        }
                                                        key={`tooltip_${key}`}
                                                        wrapperClass={css.action}
                                                    >
                                                        {badge && <span className={css.badge}>{badge}</span>}
                                                        <ActionComponent
                                                            title={title}
                                                            key={`${row.key || i}_${key}_actions`}
                                                            className={className}
                                                            onClick={() => {
                                                                onClick(row);
                                                            }}
                                                            onMouseEnter={onMouseEnter}
                                                            onMouseLeave={onMouseLeave}
                                                        />
                                                    </Tooltip>
                                                ) : null;
                                            }
                                        )}
                                    </div>
                                </td>
                            ) : null,
                        ]}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

Table.defaultProps = {
    metadata: [],
    data: [],
    actionComponentsConfigs: [],
    tableClass: '',
    thStyle: {},
    theadClass: '',
    thClass: '',
    thFiltersClass: '',
    tbodyClass: '',
    trClass: '',
    rowHoverEnabled: true,
    highlightRowIndex: -1,
    zebraTable: true,
    selectable: false,
    filterable: false,
    onToggleSelectAll: () => {},
    onToggleSingleRow: () => {},
    translate: () => {},
    dataTestId: '',
    showInnerBorder: true,
    hasActionComponentCell: false,
};

Table.propTypes = {
    metadata: TableWidgetMetadataShape,
    data: PropTypes.arrayOf(PropTypes.any),
    actionComponentsConfigs: PropTypes.arrayOf(PropTypes.any),
    tableClass: PropTypes.string,
    thStyle: PropTypes.objectOf(PropTypes.any),
    theadClass: PropTypes.string,
    thClass: PropTypes.string,
    thFiltersClass: PropTypes.string,
    tbodyClass: PropTypes.string,
    trClass: PropTypes.string,
    rowHoverEnabled: PropTypes.bool,
    highlightRowIndex: PropTypes.number,
    zebraTable: PropTypes.bool,
    selectable: PropTypes.bool,
    filterable: PropTypes.bool,
    onToggleSelectAll: PropTypes.func,
    onToggleSingleRow: PropTypes.func,
    translate: PropTypes.func,
    dataTestId: PropTypes.string,
    showInnerBorder: PropTypes.bool,
    hasActionComponentCell: PropTypes.bool,
};

export default Table;
