import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import Dropdown from '../../components/molecules/Dropdown';
import { trackMixpanelEvent } from '../../utils/general';
import css from './DataShelf.css';
import { Button, Spinner, WizardFooter } from '../../components/widgets';
import Table from '../../components/organisms/Table';
import { useFetchMonth } from '../hooks';
import { MIXPANEL_PREFIX, getMonthsList, parseMonthData } from '../utils';

function DataAvailability({ uanId, onClose, innerState, setInnerState, earliestDataDate, timezone, channel, uanName }) {
    const headerAlign = 'center';
    const metadataConfig = [
        {
            name: 'date',
            displayName: 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_AVAILABILITY.DAY',
            headerAlign,
        },
        {
            name: 'lastUpdate',
            displayName: 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_AVAILABILITY.LAST_UPDATE',
            headerAlign,
        },
        {
            name: 'nextUpdate',
            displayName: 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_AVAILABILITY.NEXT_UPDATE',
            headerAlign,
        },
        {
            name: 'message',
            displayName: 'STATIC.PAGES.DATA_SOURCES.SHELF.DATA_AVAILABILITY.MESSAGE',
            headerAlign,
        },
    ];
    const monthsList = getMonthsList(earliestDataDate);
    const initialMonthData = Object.keys(innerState).length ? innerState.monthData : [];
    const initialMonthIdx = Object.keys(innerState).length ? innerState.monthIdx : 0;
    const [selectedMonthIdx, setSelectedMonthIdx] = useState(initialMonthIdx);
    const [shouldFetchMonth, setShouldFetchMonth] = useState(!Object.keys(innerState).length);
    const { isLoading: loadingMonth, error, value: monthData = initialMonthData } = useFetchMonth(
        uanId,
        monthsList[selectedMonthIdx].name,
        shouldFetchMonth,
        () => {
            setShouldFetchMonth(false);
        }
    );
    useEffect(() => {
        if (!loadingMonth) {
            setInnerState({ monthIdx: selectedMonthIdx, monthData });
        }
    }, [loadingMonth]);

    useEffect(() => {
        if (Object.keys(innerState).length === 0) {
            trackMixpanelEvent(MIXPANEL_PREFIX, 'Entered Data Availability shelf', {
                'DC name': uanName,
                'DC channel': channel,
            });
        }
    }, []);

    function getFooterBtns() {
        return [
            <Button onClick={onClose}>
                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_AVAILABILITY.CANCEL_BUTTON" />
            </Button>,
        ];
    }

    function changeSelectedMonth(monthIdx) {
        setSelectedMonthIdx(monthIdx);
        setShouldFetchMonth(true);
        trackMixpanelEvent(MIXPANEL_PREFIX, 'Clicked on Data availability month', {
            'DC name': uanName,
            'DC channel': channel,
            'Selected Month': monthsList[monthIdx],
        });
    }

    return (
        <div className={css.content}>
            <div className={css.shelfTitle}>
                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_AVAILABILITY.TITLE" />
            </div>
            <div className={css.description}>
                <Translate id="STATIC.PAGES.DATA_SOURCES.SHELF.DATA_AVAILABILITY.DESCRIPTION" data={{ timezone }} />
            </div>
            <div className={css.dataSection} style={{ height: '100%' }}>
                <div className={css.monthDiv}>
                    <Dropdown
                        items={monthsList}
                        onSelection={newV => changeSelectedMonth(monthsList.findIndex(v => v.name === newV.name))}
                        selected={monthsList[selectedMonthIdx]}
                    />
                </div>
                {loadingMonth ? (
                    <Spinner show className={css.DALoader} />
                ) : (
                    <Table
                        tableClass={css.tableContainer}
                        trClass={css.tableRow}
                        thClass={css.thead}
                        thStyle={{ fontWeight: 'bold' }}
                        tbodyClass={css.tbody}
                        data={parseMonthData(monthData, timezone)}
                        zebraTable={false}
                        metadata={metadataConfig}
                    />
                )}
            </div>
            <WizardFooter buttons={getFooterBtns()} />
        </div>
    );
}

DataAvailability.propTypes = {
    uanId: PropTypes.number,
    onClose: PropTypes.func,
    innerState: PropTypes.objectOf(PropTypes.any),
    setInnerState: PropTypes.func,
    earliestDataDate: PropTypes.string,
    timezone: PropTypes.string,
    channel: PropTypes.string,
    uanName: PropTypes.string,
};

DataAvailability.defaultProps = {
    uanId: null,
    onClose: () => {},
    innerState: {},
    setInnerState: () => null,
    earliestDataDate: null,
    timezone: '',
    channel: '',
    uanName: '',
};

export default DataAvailability;
