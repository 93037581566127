import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import classNames from 'classnames';
import { QuestionMark, Checkbox } from '../../../components/widgets';
import Tooltip from '../../../components/molecules/Tooltip';
import { PLATFORMS_CONFIG } from './consts';
import { getOrganizationIsWeb, getOrganizationAvailablePlatforms } from '../../../selectors/user';
import css from './sdkConfiguration.css';
import { isPlatformAvailable } from './utils';

const SDKConfigurationPlatformSelection = ({ platforms, onPlatformChange, isValid, translate }) => {
    const isWebOrg = useSelector(getOrganizationIsWeb);
    const orgAvailablePlatforms = useSelector(getOrganizationAvailablePlatforms);

    const getPlatformChoices = () => {
        return Object.values(PLATFORMS_CONFIG).map(({ Icon, name }) => {
            const platformChecked = platforms.includes(name);
            const platformEnabled = isPlatformAvailable(name, orgAvailablePlatforms, isWebOrg);

            const platformChoice = (
                <div
                    key={name}
                    className={classNames(
                        css.platformCheckboxContainer,
                        { [css.active]: platformChecked },
                        { [css.disabled]: !platformEnabled }
                    )}
                    onClick={() => onPlatformChange(name)}
                >
                    <Checkbox
                        size="small"
                        disabled={!platformEnabled}
                        className={css.platformCheckbox}
                        checked={platformChecked}
                        label={translate(
                            `STATIC.PAGES.SDK_CONFIGURATION.PLATFORMS_STEP.PLATFORM_LABEL_${name.toUpperCase()}`
                        )}
                    />
                    <div className={css.checkboxLogoContainer}>
                        <Icon className={css.checkboxLogo} />
                    </div>
                </div>
            );

            return platformEnabled ? (
                platformChoice
            ) : (
                <Tooltip titleTranslationKey="STATIC.PAGES.SDK_CONFIGURATION.PLATFORMS_STEP.DISABLED_PLATFORM_TOOLTIP">
                    {platformChoice}
                </Tooltip>
            );
        });
    };

    return (
        <div className={css.firstStepContainer}>
            <div className={css.platformSelectionTitle}>
                <Translate id="STATIC.PAGES.SDK_CONFIGURATION.PLATFORMS_STEP.TITLE" />
            </div>
            <div className={css.platformQuestion}>
                <Translate id="STATIC.PAGES.SDK_CONFIGURATION.PLATFORMS_STEP.PLATFORMS_QUESTION.TEXT" />
                <QuestionMark message="STATIC.PAGES.SDK_CONFIGURATION.PLATFORMS_STEP.PLATFORMS_QUESTION.HELP" />
            </div>
            <div className={css.platformSelectionContainer}>
                <div className={css.platformCheckboxesContainer}>{getPlatformChoices()}</div>
                <div className={classNames(css.selectPlatformWarning, { [css.hide]: isValid })}>
                    <Translate id="STATIC.PAGES.SDK_CONFIGURATION.PLATFORMS_STEP.SELECT_PLATFORM" />
                </div>
            </div>
        </div>
    );
};

SDKConfigurationPlatformSelection.propTypes = {
    platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    onPlatformChange: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
};

SDKConfigurationPlatformSelection.defaultProps = {
    isValid: true,
};

export default SDKConfigurationPlatformSelection;
