import React, { memo, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Translate } from 'react-localize-redux';
import css from './PivotChart.css';
import reportsCss from '../../pages/Reports.css';
import Dropdown from '../../molecules/Dropdown';
import TagInput from '../../widgets/TagInput';
import { REPORTS_EVENT_PREFIX, trackMixpanelEvent } from '../../../utils/general';
import {
    CHART_TYPES,
    COLUMN_CHART,
    CUSTOM_DASHBOARD_CHART_TYPES,
    STACKED_CHART,
    TOP_X_COUNT_MAX_VALUE,
    TOP_X_THRESHOLD,
} from './consts';
import { getChartDataSettings, getMetricsFormat, isPieChart } from './utils';
import TopXChartDataFilter from '../TopXChartDataFilter';
import { useFilteredRowData } from '../../../customDashboards/hooks';

function getLabelForDimensions(dimensions) {
    return dimensions.map(dim => dim.displayName).join(', ');
}

function PivotChart({
    rowData,
    reportColumns,
    settings,
    onSettingsChange,
    showControls,
    disableHiding,
    height,
    containerClass,
    controlled,
    metricForTopX,
    widgetName,
}) {
    // State variables to configure the chart widget (type, selected metric, x & y axis values)
    // The default values can be provided by a settings object (see PropTypes for structure)
    const controlledChartType = useMemo(() => CHART_TYPES.find(x => x.name === settings.chartType) || CHART_TYPES[0], [
        settings.chartType,
    ]);
    const [topXCount, setTopXCount] = useState(Math.min(rowData.length, TOP_X_COUNT_MAX_VALUE));
    const [_chartType, setChartType] = useState(controlledChartType);
    const chartType = controlled ? controlledChartType : _chartType;
    const filteredRowData = useFilteredRowData(rowData, topXCount, metricForTopX);

    const controlledMetrics = useMemo(() => getMetricsFormat(reportColumns), [reportColumns]);
    const controlledSelectedMetric = useMemo(
        () =>
            controlledMetrics.find(x => x.name === settings.selectedMetric) ||
            controlledMetrics.find(x => x.name === 'adn_cost') ||
            controlledMetrics[0],
        [settings.selectedMetric]
    );
    const [_selectedMetric, setSelectedMetric] = useState(controlledSelectedMetric);
    const selectedMetric = controlled ? controlledSelectedMetric : _selectedMetric;

    const controlledXAxis = useMemo(() => settings.xAxis.map(name => reportColumns.find(x => x.name === name)), [
        settings.xAxis,
    ]);
    const [_xAxis, setX] = useState(controlledXAxis);
    const xAxis = controlled ? controlledXAxis : _xAxis;

    const controlledYAxis = useMemo(() => settings.yAxis.map(name => reportColumns.find(x => x.name === name)), [
        settings.yAxis,
    ]);
    const [_yAxis, setY] = useState(controlledYAxis);
    const yAxis = controlled ? controlledYAxis : _yAxis;

    const [categories, series, finalYaxis] = getChartDataSettings(
        chartType,
        controlledMetrics,
        xAxis,
        yAxis,
        filteredRowData
    );

    const publishSettingsChange = (chartType, selectedMetric, xAxis, yAxis, hide) => {
        if (controlled) return;
        onSettingsChange({
            chartType: chartType.name,
            selectedMetric: selectedMetric.name,
            xAxis: xAxis.map(x => x.name),
            yAxis: yAxis.map(x => x.name),
            hide,
        });
    };

    return (
        <>
            {!disableHiding && (
                <div className={reportsCss.hideElementDivider} style={{ marginBottom: '19px' }}>
                    <span
                        onClick={() => {
                            publishSettingsChange(chartType, selectedMetric, xAxis, yAxis, !settings.hide);
                            trackMixpanelEvent(REPORTS_EVENT_PREFIX, 'Toggle pivot chart hide', {
                                hide: settings.hide,
                            });
                        }}
                    >
                        <Translate id={`STATIC.BUTTONS.${settings.hide ? 'SHOW_CHART' : 'HIDE_CHART'}`} />
                    </span>
                </div>
            )}
            {!settings.hide && (
                <div className={containerClass || css.container}>
                    {showControls && (
                        <>
                            <div>Chart Type:</div>
                            <Dropdown
                                onSelection={newChartType => {
                                    setChartType(newChartType);
                                    publishSettingsChange(newChartType, selectedMetric, xAxis, yAxis, settings.hide);
                                }}
                                selected={chartType}
                                items={CUSTOM_DASHBOARD_CHART_TYPES}
                            />
                            <div>Select metric:</div>
                            <Dropdown
                                onSelection={newSelectedMetric => {
                                    setSelectedMetric(newSelectedMetric);
                                    publishSettingsChange(chartType, newSelectedMetric, xAxis, yAxis, settings.hide);
                                }}
                                selected={selectedMetric}
                                items={controlledMetrics}
                            />
                            <div>X Axis:</div>
                            <TagInput
                                suggestions={reportColumns
                                    .filter(x => x.fieldType === 'dimension' && !yAxis.find(a => a.name === x.name))
                                    .map(x => ({ value: x.name, label: x.displayName }))}
                                tags={xAxis.map(x => ({ value: x.name, label: x.displayName }))}
                                onChange={tags => {
                                    const newXAxis = tags.map(tag => reportColumns.find(x => x.name === tag.value));
                                    setX(newXAxis);
                                    publishSettingsChange(chartType, selectedMetric, newXAxis, yAxis, settings.hide);
                                }}
                            />
                            {!isPieChart(chartType) && (
                                <>
                                    <div>Y Axis:</div>
                                    <TagInput
                                        suggestions={reportColumns
                                            .filter(
                                                x => x.fieldType === 'dimension' && !xAxis.find(a => a.name === x.name)
                                            )
                                            .map(x => ({ value: x.name, label: x.displayName }))}
                                        tags={yAxis.map(x => ({ value: x.name, label: x.displayName }))}
                                        onChange={tags => {
                                            const newYAxis = tags.map(tag =>
                                                reportColumns.find(x => x.name === tag.value)
                                            );
                                            setY(newYAxis);
                                            publishSettingsChange(
                                                chartType,
                                                selectedMetric,
                                                xAxis,
                                                newYAxis,
                                                settings.hide
                                            );
                                        }}
                                    />
                                </>
                            )}
                            <br />
                            <br />
                        </>
                    )}
                    {metricForTopX && rowData.length >= TOP_X_THRESHOLD && (
                        <TopXChartDataFilter
                            topXCount={topXCount}
                            rowDataLength={rowData.length}
                            onChange={setTopXCount}
                            widgetName={widgetName}
                        />
                    )}
                    <HighchartsReact
                        key={`${chartType.name}_${topXCount}`}
                        highcharts={Highcharts}
                        options={{
                            title: { text: null },
                            chart: {
                                type: chartType.name === STACKED_CHART ? COLUMN_CHART : chartType.name,
                                height,
                            },
                            xAxis: {
                                categories,
                                reversed:
                                    xAxis.find(x => x.displayFormat && x.displayFormat.type === 'date') !== undefined,
                                title: !isPieChart(chartType) && {
                                    text: getLabelForDimensions(xAxis),
                                },
                            },
                            yAxis: finalYaxis,
                            legend: {
                                enabled: finalYaxis.length > 1 || series.length > 1,
                                title: {
                                    text: getLabelForDimensions(yAxis),
                                },
                                reversed: true,
                            },
                            tooltip: {
                                shared: true,
                            },
                            series,
                            plotOptions: {
                                series: {
                                    stacking: chartType.name === STACKED_CHART ? 'normal' : null,
                                },
                            },
                            colors: [
                                '#4479A9',
                                '#0BB5D2',
                                '#F4B03C',
                                '#F37773',
                                '#A75885',
                                '#17A38F',
                                '#B186D8',
                                '#FED674',
                                '#A2745E',
                                'crimson',
                            ],
                            credits: false,
                        }}
                    />
                </div>
            )}
        </>
    );
}

PivotChart.propTypes = {
    // @GE we're using this but eslint doesn't detect it
    // eslint-disable-next-line react/no-unused-prop-types
    reportId: PropTypes.string.isRequired,
    rowData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    reportColumns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            fieldType: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            displayFormat: PropTypes.shape({
                type: PropTypes.string.isRequired,
                code: PropTypes.string,
                precision: PropTypes.number,
                symbol: PropTypes.string,
            }),
        })
    ).isRequired,
    showControls: PropTypes.bool.isRequired,
    settings: PropTypes.shape({
        chartType: PropTypes.string,
        selectedMetric: PropTypes.string,
        xAxis: PropTypes.arrayOf(PropTypes.string),
        yAxis: PropTypes.arrayOf(PropTypes.string),
        hide: PropTypes.bool,
    }),
    onSettingsChange: PropTypes.func,
    disableHiding: PropTypes.bool,
    height: PropTypes.number,
    containerClass: PropTypes.string,
    controlled: PropTypes.bool,
    metricForTopX: PropTypes.string,
    widgetName: PropTypes.string,
};

PivotChart.defaultProps = {
    settings: {
        chartType: null,
        selectedMetric: null,
        xAxis: [],
        yAxis: [],
        hide: false,
    },
    onSettingsChange: () => {},
    disableHiding: false,
    height: null,
    containerClass: null,
    controlled: false,
    metricForTopX: undefined,
    widgetName: undefined,
};

export default memo(PivotChart, (prevProps, nextProps) => {
    return (
        nextProps.reportId === prevProps.reportId &&
        nextProps.showControls === prevProps.showControls &&
        nextProps.settings.hide === prevProps.settings.hide &&
        nextProps.height === prevProps.height &&
        (!nextProps.controlled || nextProps.settings === prevProps.settings)
    );
});
