import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { useLocation } from 'react-router-dom';
import { PageWrapper, GeneralPopup, PageHeader, PopupTypes } from '../../components/partials';
import Table from '../../components/organisms/Table';
import css from '../style.css';
import { parseSearchParams, getImpersonationFromQuery } from '../../utils/url';
import {
    buildTestReportRedirectUrl,
    DATA_SOURCES_STATUSES,
    ETLS_STATES,
    parseMetadata,
    translateMessage,
    searchDataSources,
    shouldShowExport2Phase,
    shouldShowRunTestReport,
    shouldShowDeleteButton,
    UAN_ACTION_MSG_BY_TYPE,
    PAGE_DATA_TYPES,
    shouldShowFieldsAvailability,
    NETWORKS_WITH_ACCOUNTS_NAMES,
    STATUSES_COLOR_MAP,
    isSingularTrackerUan,
    ETL_FEATURE_FLAG,
} from '../utils';
import { MESSAGE_ONCLICK_ACTION, SLACK_AUTH_URL, TOUR_LINK_IDS } from '../../utils/consts';
import TableCellIcon from '../../components/widgets/TableCellIcon';
import MonetizationIcon from '../../../../dashboard/img/icons/monetization.svg';
import Export2PhaseIcon from '../../../../dashboard/img/icons/raw-data.svg';
import TestReportIcon from '../../../../dashboard/img/icons/test_report.svg';
import EmailIcon from '../../resources/icons/dataConnectors/Email.svg';
import S3Icon from '../../resources/icons/dataConnectors/S3.svg';
import GDriveIcon from '../../resources/icons/dataConnectors/Gdrive.svg';
import APIIcon from '../../resources/icons/dataConnectors/api.svg';
import SFTPIcon from '../../resources/icons/dataConnectors/sftp.svg';
import EditIcon from '../../resources/svg/edit.svg';
import SlackIcon from '../../resources/svg/icons/slack-icon.svg';
import TrashIcon from '../../resources/svg/trash.svg';
import GlassesIcon from '../../resources/svg/glasses.svg';
import DataRefreshIcon from '../../resources/svg/data-refresh.svg';
import DataAvailabilityIcon from '../../resources/svg/data-availability.svg';
import DataConnectorAlertsIcon from '../../resources/svg/data_connector_alerts.svg';
import { sortBool, sortByType } from '../../utils/sortUtil';
import { Spinner, Toggle, EmptyState, Shelf, Button } from '../../components/widgets';
import AdNetworksEmptyState from '../../components/widgets/AdNetworksEmptyState';
import WizardWarningMessage from '../../teamManagement/components/WizardWarningMessage';
import AdnetworkBasicLogin from '../containers/AdnetworkBasicLogin';
import DataConnectorAlerts from './DataConnectorAlerts';
import Logger from '../../services/logger';
import ProgressCircle1 from '../../resources/svg/etl_states/progress-circle-1-of-4.svg';
import ProgressCircle2 from '../../resources/svg/etl_states/progress-circle-2-of-4.svg';
import ProgressCircle3 from '../../resources/svg/etl_states/progress-circle-3-of-4.svg';
import ProgressCircle4 from '../../resources/svg/etl_states/progress-circle-4-of-4.svg';
import ETLStatusUpToDate from '../../resources/svg/etl_states/etl_state_green.svg';
import ETLStatusInProgress from '../../resources/svg/etl_states/etl_state_blue.svg';
import ETLStatusDisabled from '../../resources/svg/etl_states/etl_state_disabled.svg';
import ETLStatusNoETL from '../../resources/svg/etl_states/etl_state_no_etl.svg';
import ETLStatusNotAllActive from '../../resources/svg/etl_states/etl_state_red.svg';
import Tooltip from '../../components/molecules/Tooltip';

import TextField from '../../components/molecules/TextField';
import { AddNewUan } from './AddNewUan';
import { route } from '../../utils/routerUtils';
import { DATA_CONNECTORS_EVENT_PREFIX, trackMixpanelEvent, getMixpanelEventLegacyFormat } from '../../utils/general';
import TableCellImgLabelWithAgency from '../../components/widgets/TableCellImgLabelWithAgency';
import FieldsAvailability from '../../fieldsAvailability/containers/fieldsAvailabilityContainer';
import TableCellPopover from '../../components/widgets/TableCellPopOver';
import EtlPopover from './EtlPopover';
import PlusIconWhite from '../../resources/svg/plus_white.svg';
import DataShelf from './DataShelf';
import TableCellActions from '../../components/widgets/TableCellActions';
import UpgradeETLBanner from './UpgradeETLBanner';

const logger = new Logger('[dataSourcesPage]');

let hoverStartTime = null;
let dataRefreshHoverStart = null;
let dataAvailabilityHoverStart = null;

const loadingText = (text, step) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span key="text">{text}</span>
        <span key="sub-text" style={{ color: '#777777', fontSize: 10 }}>
            {`Step ${step} of 4`}
        </span>
    </div>
);

class DataSourcesPage extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        const { archivedToggleEnabled, mixpanelPrefix } = props;
        this.state = {
            searchText: '',
            showArchivedUans: !archivedToggleEnabled,
            sortedColName: 'status',
            sortType: 'number',
            isSortAscending: true,
            selectedUan: null,
            selectedUanAdnetworkName: null,
            dataShelfUan: null,
            dataConnectorAlertsUan: null,
            showDeleteDialog: false,
            showErrorPopup: false,
            showCancelDialog: false,
            uanDeleteInProccess: false,
            lastUpdatedUanId: null,
            openFieldsAvailability: false,
            openDataRefresh: false,
            openDataConnectorAlerts: false,
            openDataConnectorFilter: false,
            adnetworkBasicLoginHasChanges: false,
        };

        this.onSearchChange = this._onSearchChange.bind(this);
        this.onExport2PhaseClick = this._onExport2PhaseClick.bind(this);
        this.onRunTestReportClick = this._onRunTestReportClick.bind(this);
        this.onEditUanClick = this._onEditUanClick.bind(this);
        this.onDeleteUanClick = this._onDeleteUanClick.bind(this);
        this.onAdnetworkLoginChange = this._onAdnetworkLoginChange.bind(this);
        this.onAdNetworkSelectionChange = this._onAdNetworkSelectionChange.bind(this);
        this.changeSort = this._changeSort.bind(this);
        this.onToggleArchivedUans = this._onToggleArchivedUans.bind(this);
        this.onPopupClose = this._onPopupClose.bind(this);
        this.onAcceptUanDelete = this._onAcceptUanDelete.bind(this);
        this.onAcceptCancelAdnetworkLoginShelf = this._onAcceptCancelAdnetworkLoginShelf.bind(this);
        this.onMessageTextClick = this._onMessageTextClick.bind(this);
        this.toggleFieldsAvailabilityShelf = this._toggleFieldsAvailabilityShelf.bind(this);
        this.toggleDataRefreshShelf = this._toggleDataRefreshShelf.bind(this);
        this.toggleDataConnectorAlertsShelf = this._toggleDataConnectorAlertsShelf.bind(this);
        this.toggleFilterAvailabilityShelf = this._toggleDataConnectorsFilter.bind(this);
        this.closeFieldsAvailabilityShelf = this._closeFieldsAvailabilityShelf.bind(this);
        this.getNewUanComponent = this._getNewUanComponent.bind(this);
    }

    componentDidMount() {
        const { onValidationFinished, mixpanelPrefix } = this.props;

        trackMixpanelEvent(mixpanelPrefix, 'Page View', {});

        // TODO: delete after migration, since we already have Page View
        window.mixpanel.track(getMixpanelEventLegacyFormat(mixpanelPrefix, 'Page Load'));

        window.addEventListener('validationFinished', ({ detail }) => {
            onValidationFinished(detail.uanId);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            export2phaseLatestTaskKey,
            username,
            template2phaseLink,
            uanAction,
            selectedUanData,
            updatedUanId,
        } = this.props;
        const { showErrorPopup, uanIdToShowFirst } = this.state;
        const {
            export2phaseLatestTaskKey: nextExport2phaseLatestTaskKey,
            uanAction: nextUanAction,
            selectedUanData: nextSelectedUanData,
            updatedUanId: nextUpdatedUanId,
            apiRequestFailed,
        } = nextProps;

        if (export2phaseLatestTaskKey !== nextExport2phaseLatestTaskKey) {
            let link = template2phaseLink
                .replace('{user_name}', username)
                .replace('{task_id}', nextExport2phaseLatestTaskKey);
            const impersonatedUser = getImpersonationFromQuery();
            if (impersonatedUser) {
                link += `&user=${decodeURIComponent(impersonatedUser)}`;
            }
            window.open(link, '_blank');
        }

        if (selectedUanData !== nextSelectedUanData) {
            this.setState({ selectedUan: nextSelectedUanData });
        }

        if (uanAction !== nextUanAction) {
            if (nextUanAction.uan && nextUanAction.type && nextUanAction.type !== 'delete') {
                this.setState({ uanIdToShowFirst: nextUanAction.uan.uan_id });
            } else if (!nextUanAction.type) {
                this.setState({ lastUpdatedUanId: uanIdToShowFirst });
                this.setState({ uanIdToShowFirst: undefined });
            }
        }

        if (updatedUanId !== nextUpdatedUanId) {
            if (!nextUpdatedUanId && updatedUanId) {
                this.setState({ lastUpdatedUanId: uanIdToShowFirst });
            }
            this.setState({ uanIdToShowFirst: nextUpdatedUanId });
        }

        if (apiRequestFailed && !showErrorPopup) {
            this.setState({ showErrorPopup: true });
        }
    }

    componentDidUpdate(prevProps) {
        const { uanAction, shelf, uansData, adnetworks, location, redirectPath, isLoadingUans } = this.props;
        const { shelf: prevShelf } = prevProps;

        if (!shelf.open) {
            this.selectedRowData = null;

            // In case of closing shelf, remove params from url.
            if (prevShelf.open) {
                route(redirectPath);
            }
            // This code will run only when the user enter to the page for the first time.
            else if (location && !isLoadingUans) {
                const urlParams = parseSearchParams(location.search);
                const id = urlParams.id ? parseInt(urlParams.id, 10) : null;

                const editedUan = uansData.find(uan => uan.uanId === id);
                const selectedUan = Object.values(adnetworks).find(adn => adn.id === id);

                if (editedUan) {
                    this._onEditUanClick(editedUan, !!urlParams['is-mail']);
                } else if (selectedUan) {
                    this._addNewUan(selectedUan);
                } else if (id) {
                    // Could not find an uan with the specified id
                    route(redirectPath);
                }
            }
        }

        if (uanAction.type && prevProps.uanAction !== uanAction) {
            document.querySelector('#reactContainer').scrollTo(0, 0);
        }
    }

    defaultThrottle(func) {
        return throttle(func, 3000, { trailing: false });
    }

    _onSearchChange(searchText) {
        trackMixpanelEvent(this.props.mixpanelPrefix, 'Filter Data Connector', { text: searchText });

        this.setState({ searchText });
    }

    _onExport2PhaseClick(rowData) {
        const { export2Phase, mixpanelPrefix } = this.props;

        trackMixpanelEvent(mixpanelPrefix, 'Clicked Download Raw CSV');

        export2Phase(rowData.uanId);
    }

    _onRunTestReportClick(rowData) {
        const { isStandardAnalytics, mixpanelPrefix } = this.props;

        trackMixpanelEvent(mixpanelPrefix, 'Clicked Run Test Report');

        window.open(buildTestReportRedirectUrl(rowData.uanId, isStandardAnalytics), '_blank');
    }

    _shouldDisplayEtlStatus() {
        const { featureFlags, redirectPath, etlStatusEnabled } = this.props;
        return (
            redirectPath === 'react/data-sources' &&
            featureFlags.length &&
            featureFlags.includes('singular_etl') &&
            etlStatusEnabled
        );
    }

    _toggleFieldsAvailabilityShelf(rowData, isOpen) {
        this.setState({ openFieldsAvailability: isOpen, selectedUanAdnetworkName: rowData.adnetworkName });
    }

    _toggleDataRefreshShelf(rowData, openDataRefresh, openDataShelf = true) {
        if (!openDataShelf) {
            this.setState({ openDataRefresh: false, dataShelfUan: null });
            return;
        }

        this.selectedRowData = rowData;
        this.setState({
            openDataRefresh,
            dataShelfUan: {
                canDataRefresh: rowData.canDataRefresh,
                earliestDataDate: rowData.earliestDataDate,
                orgTimezone: rowData.orgTimezone,
                uanId: rowData.uanId,
                displayName: rowData.displayName,
                status: rowData.status,
                imgSrc: rowData.imgSrc,
                channel: rowData.channel,
            },
        });
    }

    _toggleDataConnectorAlertsShelf(rowData, isOpen) {
        this.setState({
            openDataConnectorAlerts: isOpen,
            dataConnectorAlertsUan: {
                uanId: rowData.uanId,
                displayName: rowData.displayName,
            },
        });
    }

    _toggleDataConnectorsFilter() {
        this.setState({ openDataConnectorFilter: true });
    }

    _closeFieldsAvailabilityShelf() {
        this.toggleFieldsAvailabilityShelf({}, false);
    }

    _onEditUanClick(rowData, isMailEdit = false) {
        const { openShelf, redirectPath, mixpanelPrefix } = this.props;

        trackMixpanelEvent(mixpanelPrefix, 'Clicked Edit Data Connector', {
            ...rowData,
            has_validation_error: Boolean(
                rowData.report_validation_error && Object.keys(rowData.report_validation_error).length > 0
            ),
            isMailEdit,
        });

        this.selectedRowData = rowData;
        this.setState({ selectedUan: { uan_id: rowData.uanId } });
        openShelf(rowData.displayName, rowData.uanId);
        route(redirectPath, { id: rowData.uanId });
    }

    _onSlackAction() {
        const { isUserSlackAuth, mixpanelPrefix, onDeleteUserSlackAuth } = this.props;

        trackMixpanelEvent(mixpanelPrefix, 'Slack Auth', {
            isEnabled: !isUserSlackAuth,
        });

        if (isUserSlackAuth) {
            onDeleteUserSlackAuth();
        } else {
            window.location.href = SLACK_AUTH_URL;
        }
    }

    _onDeleteUanClick(rowData) {
        this.uanIdToDelete = rowData.uanId;
        this.setState({
            showDeleteDialog: true,
        });
    }

    _onPopupClose() {
        this.uanIdToDelete = null;
        this.setState({
            showDeleteDialog: false,
            showErrorPopup: false,
            showCancelDialog: false,
        });
    }

    _onAcceptUanDelete() {
        const { onUanDelete } = this.props;
        this.setState({
            uanDeleteInProccess: true,
        });
        onUanDelete(this.uanIdToDelete);
        setTimeout(() => {
            this.setState({
                showDeleteDialog: false,
                uanDeleteInProccess: false,
            });
        }, 3000);
    }

    _onAcceptCancelAdnetworkLoginShelf() {
        const { onCloseShelf } = this.props;
        onCloseShelf();
        this.setState({ showCancelDialog: false });
    }

    _addNewUan(selectedUan) {
        const { openShelf } = this.props;
        this.setState({ selectedUan });
        openShelf(selectedUan.display_name);
    }

    _onAdnetworkLoginChange(hasChanges) {
        this.setState({ adnetworkBasicLoginHasChanges: hasChanges });
    }

    _onAdNetworkSelectionChange(selectedAdNetworkOption) {
        const { adnetworks, redirectPath, mixpanelPrefix, translationPageKey } = this.props;

        if (!selectedAdNetworkOption) {
            logger.log('No network selected!');
        } else {
            trackMixpanelEvent(mixpanelPrefix, 'Add UAN', { name: selectedAdNetworkOption.value }, true);

            if (Object.keys(adnetworks).length) {
                const adnetworkName = selectedAdNetworkOption.value
                    ? selectedAdNetworkOption.value
                    : Object.keys(adnetworks).find(key => adnetworks[key].id === selectedAdNetworkOption.id);

                const selectedUan = adnetworks[adnetworkName];
                if (!selectedUan) {
                    return;
                }

                this._addNewUan(selectedUan);
                route(redirectPath, { id: selectedUan.id });
            }
        }
    }

    _changeSort(colName, type) {
        const { sortedColName, isSortAscending } = this.state;
        const { mixpanelPrefix } = this.props;
        const nextIsSortAscending = sortedColName === colName ? !isSortAscending : false;

        trackMixpanelEvent(mixpanelPrefix, 'Clicked Sort', {
            column_name: colName,
            is_ascending: nextIsSortAscending,
        });
        const mixpanelParams = { name: colName, type: nextIsSortAscending ? 'asc' : 'desc' };
        trackMixpanelEvent(mixpanelPrefix, 'Table Sort', mixpanelParams, true);

        this.setState({
            sortedColName: colName,
            sortType: type,
            isSortAscending: nextIsSortAscending,
            uanIdToShowFirst: undefined,
        });
    }

    _onToggleArchivedUans(event, checked) {
        trackMixpanelEvent(this.props.mixpanelPrefix, 'Clicked 30 Day Toggle', { turned: checked ? 'on' : 'off' });

        this.setState({ showArchivedUans: checked });
    }

    _onMessageTextClick(action, rowData) {
        if (action === MESSAGE_ONCLICK_ACTION.OPEN_SHELF && rowData) {
            this._onEditUanClick(rowData);
        }
    }

    _getActionComponentsConfigs() {
        const { translate, showFieldsAvailabilityFeature, adminMode, mixpanelPrefix } = this.props;

        return [
            {
                ActionComponent: DataAvailabilityIcon,
                onClick: this.defaultThrottle(rowData => this.toggleDataRefreshShelf(rowData, false)),
                key: 'dataAvailability',
                title: translate('STATIC.PAGES.DATA_SOURCES.DATA_AVAILABILITY_TITLE'),
                shouldShow: uanData => uanData.canDataAvailability,
                tooltipText: translate('STATIC.PAGES.DATA_SOURCES.DATA_AVAILABILITY_TOOLTIP'),
                onMouseEnter: () => {
                    dataAvailabilityHoverStart = new Date();
                },
                onMouseLeave: () => {
                    const hoverTime = new Date() - dataAvailabilityHoverStart;
                    if (hoverTime > 1000) {
                        trackMixpanelEvent(mixpanelPrefix, 'Hover on Data Availability icon', {
                            hoverTime,
                        });
                    }
                },
            },
            {
                ActionComponent: DataRefreshIcon,
                onClick: this.defaultThrottle(rowData => this.toggleDataRefreshShelf(rowData, true)),
                onMouseEnter: () => {
                    dataRefreshHoverStart = new Date();
                },
                onMouseLeave: () => {
                    const hoverTime = new Date() - dataRefreshHoverStart;
                    if (hoverTime > 1000) {
                        trackMixpanelEvent(mixpanelPrefix, 'Hover on Data Refresh icon', {
                            hoverTime,
                        });
                    }
                },
                key: 'dataRefresh',
                title: translate('STATIC.PAGES.DATA_SOURCES.DATA_REFRESH_TITLE'),
                shouldShow: uanData => uanData.canDataRefresh,
                tooltipText: translate('STATIC.PAGES.DATA_SOURCES.DATA_REFRESH_TOOLTIP'),
            },
            {
                ActionComponent: GlassesIcon,
                onClick: this.defaultThrottle(rowData => this.toggleFieldsAvailabilityShelf(rowData, true)),
                key: 'fieldsAvailability',
                title: translate('STATIC.PAGES.DATA_SOURCES.FIELDS_AVAILABILITY_TITLE'),
                shouldShow: uanData => {
                    return shouldShowFieldsAvailability(uanData, showFieldsAvailabilityFeature);
                },
                tooltipText: translate('STATIC.PAGES.DATA_SOURCES.OPEN_FIELDS_AVAILABILITY_TOOLTIP'),
            },
            {
                ActionComponent: EditIcon,
                onClick: this.defaultThrottle(this.onEditUanClick),
                key: 'editUan',
                title: translate('STATIC.PAGES.DATA_SOURCES.EDIT_UAN_TITLE'),
                shouldShow: uanData => !isSingularTrackerUan(uanData.displayName),
                tooltipText: translate('STATIC.PAGES.DATA_SOURCES.EDIT_UAN_TOOLTIP'),
            },
            {
                ActionComponent: DataConnectorAlertsIcon,
                onClick: this.defaultThrottle(rowData => this.toggleDataConnectorAlertsShelf(rowData, true)),
                key: 'dataConnectorAlerts',
                badge: translate('STATIC.PAGES.DATA_SOURCES.NEW_BADGE'),
                title: translate('STATIC.PAGES.DATA_SOURCES.DATA_CONNECTOR_ALERTS_TITLE'),
                shouldShow: uanData => uanData.canDataConnectorAlerts,
                tooltipText: translate('STATIC.PAGES.DATA_SOURCES.DATA_CONNECTOR_ALERTS_TOOLTIP'),
            },
            {
                ActionComponent: Export2PhaseIcon,
                onClick: this.defaultThrottle(this.onExport2PhaseClick),
                key: 'export2Phase',
                title: translate('STATIC.PAGES.DATA_SOURCES.EXPORT_RAW_DATA_TITLE'),
                shouldShow: shouldShowExport2Phase,
                tooltipText: translate('STATIC.PAGES.DATA_SOURCES.EXPORT_RAW_DATA'),
            },
            {
                ActionComponent: TestReportIcon,
                onClick: this.defaultThrottle(this.onRunTestReportClick),
                key: 'runTestReport',
                title: translate('STATIC.PAGES.DATA_SOURCES.RUN_TEST_REPORT_TITLE'),
                shouldShow: shouldShowRunTestReport,
                tooltipText: translate('STATIC.PAGES.DATA_SOURCES.RUN_TEST_REPORT_TOOLTIP'),
            },
            {
                ActionComponent: TrashIcon,
                onClick: this.defaultThrottle(this.onDeleteUanClick),
                key: 'deleteUan',
                shouldShow: shouldShowDeleteButton,
                tooltipText: translate('STATIC.PAGES.DATA_SOURCES.DELETE_UAN_TOOLTIP'),
                title: translate('STATIC.PAGES.DATA_SOURCES.DELETE_UAN_TITLE'),
            },
        ];
    }

    _getMetadataConfig(actionComponentsConfigs, isDataDestination) {
        const { translate, translationPageKey } = this.props;
        const channelImages = { mail: EmailIcon, s3: S3Icon, gdrive: GDriveIcon, api: APIIcon, ftp: SFTPIcon };
        const channelProperName = { mail: 'a mail', s3: 'an S3', gdrive: 'a GDrive', api: 'an API', ftp: 'an SFTP' };

        const etlStatusConfig = {
            name: 'etlStatus',
            displayName: translate(`STATIC.PAGES.${translationPageKey}.TABLE.HEADERS.ETL_STATUS`),
            headerAlign: 'center',
            cellComponent: TableCellPopover,
            thStyle: { minWidth: '98.5px' },
            childrenComponent: EtlPopover,
            childrenComponentProps: [
                { propName: 'header', dataKey: 'etlPopoverHeader' },
                { propName: 'etlTableContent', dataKey: 'etlPopoverContent' },
            ],
            cellProps: {
                tdStyle: {
                    minWidth: '98.5px',
                    height: 50,
                    paddingRight: '0px',
                },
                iconElement: {
                    [ETLS_STATES.UP_TO_DATE]: <ETLStatusUpToDate />,
                    [ETLS_STATES.SOME_IN_PROGRESS]: <ETLStatusInProgress />,
                    [ETLS_STATES.ALL_IN_PROGRESS]: <ETLStatusInProgress />,
                    [ETLS_STATES.NOT_EXIST]: <ETLStatusNoETL />,
                    [ETLS_STATES.NOT_ALL_ACTIVE]: <ETLStatusNotAllActive />,
                    [ETLS_STATES.NO_STATE]: <ETLStatusDisabled />,
                    [ETLS_STATES.NOT_SUPPORTED]: <ETLStatusNoETL />,
                },
                tooltip: {
                    [ETLS_STATES.UP_TO_DATE]: `STATIC.PAGES.${translationPageKey}.TABLE.CELLS.ETL_STATUS.TOOLTIPS.UP_TO_DATE`,
                    [ETLS_STATES.SOME_IN_PROGRESS]: `STATIC.PAGES.${translationPageKey}.TABLE.CELLS.ETL_STATUS.TOOLTIPS.SOME_IN_PROGRESS`,
                    [ETLS_STATES.ALL_IN_PROGRESS]: `STATIC.PAGES.${translationPageKey}.TABLE.CELLS.ETL_STATUS.TOOLTIPS.ALL_IN_PROGRESS`,
                    [ETLS_STATES.NOT_EXIST]: `STATIC.PAGES.${translationPageKey}.TABLE.CELLS.ETL_STATUS.TOOLTIPS.NOT_EXIST`,
                    [ETLS_STATES.NOT_ALL_ACTIVE]: `STATIC.PAGES.${translationPageKey}.TABLE.CELLS.ETL_STATUS.TOOLTIPS.NOT_ALL_ACTIVE`,
                    [ETLS_STATES.NOT_SUPPORTED]: `STATIC.PAGES.${translationPageKey}.TABLE.CELLS.ETL_STATUS.TOOLTIPS.NOT_SUPPORTED`,
                },
                hasPopover: {
                    [ETLS_STATES.UP_TO_DATE]: true,
                    [ETLS_STATES.SOME_IN_PROGRESS]: true,
                    [ETLS_STATES.ALL_IN_PROGRESS]: true,
                    [ETLS_STATES.NOT_EXIST]: false,
                    [ETLS_STATES.NOT_ALL_ACTIVE]: true,
                    [ETLS_STATES.NO_STATE]: false,
                    [ETLS_STATES.NOT_SUPPORTED]: false,
                },
                translate,
            },
            cellValues: [{ propName: 'isAdMonetization', dataKey: 'is_ad_monetization' }],
        };

        const metadataConfig = [
            {
                name: 'displayName',
                displayName: translate(`STATIC.PAGES.${translationPageKey}.TABLE.HEADERS.DISPLAY_NAME`),
                headerAlign: 'center',
                cellComponent: TableCellImgLabelWithAgency,
                showHeaderArrow: true,
                cellValues: [
                    { propName: 'subText', dataKey: 'subText' },
                    { propName: 'subTextExtra', dataKey: 'subTextExtra' },
                    { propName: 'agencyName', dataKey: 'agencyName' },
                    { propName: 'imgSrc', dataKey: 'imgSrc' },
                    { propName: 'markCell', dataKey: 'showInArchive' },
                    { propName: 'showImgBadge', dataKey: 'is_ad_monetization' },
                    { propName: 'showUsernameImg', dataKey: 'showUsernameImg' },
                    { propName: 'SideImg', dataKey: 'channel', transformation: channel => channelImages[channel] },
                    {
                        propName: 'sideImgHoverText',
                        dataKey: 'channel',
                        transformation: channel => `This connector is ${channelProperName[channel]} integration`,
                    },
                ],
                headerClick: this.changeSort.bind(null, 'displayName', 'string'),
                thStyle: { minWidth: '300px' },
                cellProps: {
                    tdStyle: {
                        minWidth: '300px',
                        maxWidth: '300px',
                    },
                    containerStyle: {
                        padding: '5px',
                    },
                    imgStyle: {
                        height: '30px',
                        maxWidth: '30px',
                        margin: '0 20px 0 0',
                        borderRadius: '3px',
                    },
                    labelsStyle: {
                        maxWidth: '83%',
                    },
                    subTextStyle: {
                        fontSize: '10px',
                        color: 'black',
                        maxWidth: '180px',
                    },
                    subTextExtraStyle: {
                        fontSize: '10px',
                        color: '#515864',
                        maxWidth: '180px',
                    },
                    markCellTooltipText: 'Connector without data in the last 30 days',
                    Icon: MonetizationIcon,
                },
            },
            {
                name: 'status',
                subValue: 'message',
                isBold: true,
                displayName: 'Status',
                headerAlign: 'center',
                cellComponent: TableCellIcon,
                thStyle: { width: '80%' },
                headerClick: this.changeSort.bind(null, 'status', 'number'),
                cellValues: [{ propName: 'isLoading', dataKey: 'isUanLoading' }],
                cellProps: {
                    color: STATUSES_COLOR_MAP,
                    tdStyle: { width: '80%', height: 50 },
                    iconElement: {
                        [DATA_SOURCES_STATUSES.FIRST_SCRAPE_STAGE_SCRAPING]: <ProgressCircle1 />,
                        [DATA_SOURCES_STATUSES.FIRST_SCRAPE_STAGE_DATA_SOURCE_PIPELINE]: <ProgressCircle2 />,
                        [DATA_SOURCES_STATUSES.FIRST_SCRAPE_STAGE_SOURCE_PIPELINE]: <ProgressCircle3 />,
                        [DATA_SOURCES_STATUSES.FIRST_SCRAPE_STAGE_DRUID_LOAD]: <ProgressCircle4 />,
                    },
                    text: {
                        [DATA_SOURCES_STATUSES.ACTIVE]: 'Active',
                        [DATA_SOURCES_STATUSES.PENDING]: 'Pending',
                        [DATA_SOURCES_STATUSES.INACTIVE]: 'Inactive',
                        [DATA_SOURCES_STATUSES.NOT_IMPLEMENTED]: 'Not Implemented',
                        [DATA_SOURCES_STATUSES.FIRST_SCRAPE_STAGE_SCRAPING]: loadingText('Pulling Data', 1),
                        [DATA_SOURCES_STATUSES.FIRST_SCRAPE_STAGE_DATA_SOURCE_PIPELINE]: loadingText(
                            'Normalizing Data',
                            2
                        ),
                        [DATA_SOURCES_STATUSES.FIRST_SCRAPE_STAGE_SOURCE_PIPELINE]: loadingText('Combining Data', 3),
                        [DATA_SOURCES_STATUSES.FIRST_SCRAPE_STAGE_DRUID_LOAD]: loadingText('Loading Data', 4),
                        [DATA_SOURCES_STATUSES.ACTION_REQUIRED]: 'Action Required',
                        [DATA_SOURCES_STATUSES.DATA_DELAY]: 'Delayed',
                    },
                    onSubValueClick: this.onMessageTextClick,
                },
                showHeaderArrow: true,
            },
            {
                name: 'lastDataImportDate',
                displayName: translate(`STATIC.PAGES.${translationPageKey}.TABLE.HEADERS.LAST_DATA_IMPORT_DATE`),
                thStyle: { minWidth: '150px' },
                headerClick: this.changeSort.bind(null, 'lastDataImportDate', 'date'),
                cellValues: [{ propName: 'isLoading', dataKey: 'isUanLoading' }],
                cellProps: {
                    containerStyle: {
                        minWidth: '150px',
                        textAlign: 'center',
                    },
                },
                showHeaderArrow: true,
            },
            {
                name: 'actionItems',
                cellComponent: TableCellActions,
                displayName: '',
                thStyle: { minWidth: '200px' },
                cellProps: {
                    containerStyle: {
                        minWidth: '200px',
                        textAlign: 'right',
                    },
                    isDataDestination,
                    actionComponentsConfigs,
                    extendedMenuTooltipText: translate(`STATIC.PAGES.DATA_SOURCES.MORE_ACTIONS_TOOLTIP`),
                },
            },
        ];

        if (this._shouldDisplayEtlStatus()) {
            metadataConfig.splice(2, 0, etlStatusConfig);
        }

        return metadataConfig;
    }

    _getNewUanComponent() {
        const { adNetworkOptions, mixpanelPrefix, redirectPath, translationPageKey } = this.props;
        const allowedAdns = adNetworkOptions.filter(adn => !adn.isDisabled);
        if (allowedAdns?.length === 1) {
            return (
                <Button
                    onClick={() => this.onAdNetworkSelectionChange(allowedAdns[0])}
                    type="primary"
                    level="level1"
                    text={`STATIC.PAGES.${translationPageKey}.ADD_NEW_SPECIFIC_UAN`}
                    textReplace={{ adnetwork: allowedAdns[0].label }}
                    className={css.addUanButton}
                />
            );
        }
        return (
            <AddNewUan
                adNetworkOptions={adNetworkOptions}
                onAdNetworkSelectionChange={this.onAdNetworkSelectionChange}
                buttonText={`STATIC.PAGES.${translationPageKey}.ADD_NEW_UAN`}
                placeholderText={`STATIC.PAGES.${translationPageKey}.ADNETWORK_SELECTION_PLACEHOLDER`}
                icon={redirectPath === 'react/data-sources' ? PlusIconWhite : null}
                onInputChange={text =>
                    trackMixpanelEvent(mixpanelPrefix, 'Table State New UAN Search', { name: text }, true)
                }
            />
        );
    }

    render() {
        const {
            sortedColName,
            sortType,
            isSortAscending,
            searchText,
            showArchivedUans,
            uanIdToShowFirst,
            selectedUan,
            showDeleteDialog,
            showErrorPopup,
            showCancelDialog,
            uanDeleteInProccess,
            lastUpdatedUanId,
            openFieldsAvailability,
            openDataRefresh,
            openDataConnectorFilter,
            selectedUanAdnetworkName,
            adnetworkBasicLoginHasChanges,
            dataShelfUan,
            dataConnectorAlertsUan,
            openDataConnectorAlerts,
        } = this.state;
        const {
            translate,
            uansData: propsUansData,
            isLoadingUans,
            adNetworkOptions,
            uanAction,
            timezone,
            archivedToggleEnabled,
            redirectPath,
            emptyStateDataConnectors,
            mixpanelPrefix,
            translationPageKey,
            shelf,
            onCloseShelf,
            adminMode,
            isUserSlackAuth,
            onDeleteUserSlackAuth,
            showFieldsAvailabilityFeature,
            partnersIds,
            adnetworks,
            featureFlags,
        } = this.props;
        const { uan: uanFromUanAction = {}, type: uanActionType } = uanAction || {};
        const isDataDestination = redirectPath === 'react/data-destinations';
        const hasEtlFlag = featureFlags.includes(ETL_FEATURE_FLAG);

        const isETLUpgradeAvaialble = isDataDestination && !hasEtlFlag && !isLoadingUans;

        let uansData = searchDataSources(searchText, propsUansData, showArchivedUans, adminMode);

        if (sortType) {
            uansData = uansData.sort((uan1, uan2) => {
                return sortByType[sortType](uan1[sortedColName], uan2[sortedColName], isSortAscending);
            });
        }

        uansData = uansData.map(row => translateMessage(row, translate));

        if (uanIdToShowFirst || lastUpdatedUanId) {
            const uanId = uanIdToShowFirst || lastUpdatedUanId;
            uansData.sort(({ uanId: uanId1 }, { uanId: uanId2 }) => {
                return sortBool(uanId1 === uanId, uanId2 === uanId);
            });
        }

        let shelfHeader = selectedUan && selectedUan.display_name ? `${selectedUan.display_name} Configuration` : '';

        if (adminMode && selectedUan && selectedUan.uan_id) {
            shelfHeader = shelfHeader.concat(` (uan_id: ${selectedUan.uan_id})`);
        }

        const handleCloseShelf = () => {
            adnetworkBasicLoginHasChanges ? this.setState({ showCancelDialog: true }) : onCloseShelf();
        };

        const pageHeaderSubText = {
            [PAGE_DATA_TYPES.DATA_SOURCES]: {
                faqLink: 'https://support.singular.net/hc/en-us/articles/360031000051-Data-Connectors-FAQ',
                tourId: TOUR_LINK_IDS.dataConnectors,
            },
            [PAGE_DATA_TYPES.DATA_DESTINATIONS]: {
                faqLink: `https://support.singular.net/hc/en-us/articles/360037917571`,
                tourId: TOUR_LINK_IDS.dataDestinations,
            },
        };

        const { externalShelfElement: ExternalShelfElement } = this.props;
        const isEditMode = !!selectedUan?.uan_id;
        const actionComponents = this._getActionComponentsConfigs();

        // TODO: reduce minWidth and fix headers accordingly. atm less than 1400 causes a bug with sticky thead
        return (
            <PageWrapper
                style={{ overflowX: 'initial', minWidth: !isLoadingUans && propsUansData.length > 0 ? 1080 : 1000 }}
                onMouseEnter={() => {
                    hoverStartTime = new Date();
                }}
                onMouseLeave={() => {
                    const hoverTime = new Date() - hoverStartTime;
                    if (hoverTime > 8000) {
                        trackMixpanelEvent(mixpanelPrefix, 'Hover on Page', { hoverTime });
                    }
                }}
            >
                <WizardWarningMessage
                    containerStyle={{
                        position: 'absolute',
                        width: '100%',
                        top: 0,
                        left: 0,
                        paddingLeft: '40px',
                        paddingRight: '40px',
                    }}
                    show={uanAction && uanAction.uan && uanAction.type === 'delete'}
                    message={UAN_ACTION_MSG_BY_TYPE[uanActionType]}
                    textReplace={{
                        uan_name: uanFromUanAction.display_name,
                    }}
                    showIcon
                    showTypeIcon
                    textAlignCenter
                    type="info"
                    delay={300}
                />
                <PageHeader
                    text={`STATIC.PAGE_HEADERS.${translationPageKey}`}
                    subText={pageHeaderSubText[translationPageKey]}
                />
                {isLoadingUans && <Spinner show expanded />}
                {!isLoadingUans && propsUansData.length === 0 && adNetworkOptions.length > 0 && (
                    <AdNetworksEmptyState
                        adNetworkOptions={adNetworkOptions}
                        emptyStateAdNetworks={emptyStateDataConnectors}
                        translationPageKey={translationPageKey}
                        mixpanelPrefix={mixpanelPrefix}
                        onAdNetworkSelectionChange={this.onAdNetworkSelectionChange}
                    />
                )}
                {!isLoadingUans && propsUansData.length > 0 && (
                    <div>
                        <div className={css.header} ref={this.myRef}>
                            <div className={css.dataConnectorsTopSection}>
                                <div className={css.dataConnectorsSection}>
                                    <div className={css.search}>
                                        <TextField
                                            clearable
                                            value={searchText}
                                            debounce={500}
                                            onChange={this.onSearchChange}
                                            containerClass={css.searchInput}
                                            placeholder={translate(
                                                `STATIC.PAGES.${translationPageKey}.TYPE_NETWORK_NAME`
                                            )}
                                        />
                                    </div>
                                    <div className={css.addNewUanSection}>
                                        {showFieldsAvailabilityFeature && !isDataDestination && (
                                            <div
                                                className={css.fieldsAvailability}
                                                onClick={() => this.toggleFieldsAvailabilityShelf({}, true)}
                                            >
                                                <GlassesIcon className={css.fieldsAvailabilityIcon} />
                                                <div className={css.fieldsAvailabilityLabel}>
                                                    <Translate id="STATIC.PAGES.DATA_SOURCES.FIELDS_AVAILABILITY_BUTTON" />
                                                </div>
                                            </div>
                                        )}
                                        {this.getNewUanComponent()}
                                    </div>
                                </div>
                                <div className={css.dataConnectorsSlackSection}>
                                    {archivedToggleEnabled && (
                                        <div>
                                            <div
                                                className={css.slackContainer}
                                                onClick={this._onSlackAction.bind(this)}
                                            >
                                                <span className={css.line} />
                                                <Tooltip
                                                    style={{ marginRight: 'auto' }}
                                                    title={
                                                        isUserSlackAuth
                                                            ? 'Click here to stop receiving the daily Slack alerts'
                                                            : 'Get daily alerts on Slack on the status of your Data Connectors'
                                                    }
                                                >
                                                    <span className={css.slackText}>
                                                        <SlackIcon className={css.slackImg} />
                                                        {isUserSlackAuth
                                                            ? 'Remove alerts from Slack'
                                                            : 'Alert me on Slack'}
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <Toggle
                                                style={{ float: 'right', width: '362px' }}
                                                checked={showArchivedUans}
                                                label={translate('STATIC.PAGES.DATA_SOURCES.TOGGLE_ARCHIVED_UANS')}
                                                onToggle={this.onToggleArchivedUans}
                                                styleOverride={{
                                                    labelStyle: {
                                                        color: '#515864',
                                                        fontSize: '13px',
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}
                                    {searchText && uansData.length === 0 && (
                                        <div className={css.noDataContainer}>
                                            <EmptyState
                                                icon="noDataSource"
                                                headerTextReplace={{ searchText: `"${searchText}"` }}
                                                header="STATIC.PAGES.DATA_SOURCES.NO_SEARCH_RESULTS"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Table
                            highlightRowIndex={
                                uanIdToShowFirst || (uanAction.type && uanAction.type !== 'delete')
                                    ? uansData.findIndex(uanData => uanData.uanId === uanIdToShowFirst)
                                    : -1
                            }
                            theadClass={classNames([css.thead, { [css.theadWithSlack]: archivedToggleEnabled }])}
                            tbodyClass={css.tbody}
                            thStyle={{
                                padding: '10px 10px 10px 25px',
                                fontWeight: 600,
                                textAlign: 'center',
                                boxShadow: '1px 1px 3px #F8F8F8',
                            }}
                            tableClass={css.table}
                            data={uansData}
                            metadata={parseMetadata(
                                this._getMetadataConfig(actionComponents, isDataDestination),
                                timezone,
                                sortedColName,
                                isSortAscending,
                                searchText,
                                {
                                    lastDataImportDate: translate(
                                        `STATIC.PAGES.${translationPageKey}.TABLE.TOOLTIPS.LAST_DATA_IMPORT_DATE`
                                    ),
                                    etlStatus: translate(
                                        `STATIC.PAGES.${translationPageKey}.TABLE.TOOLTIPS.ETL_STATUS`
                                    ),
                                }
                            )}
                            zebraTable
                            hasActionComponentCell
                        />
                    </div>
                )}
                <GeneralPopup
                    open={showDeleteDialog}
                    type={PopupTypes.DELETE}
                    title={`STATIC.PAGES.${translationPageKey}.DELETE_MODAL.TITLE`}
                    text={`STATIC.PAGES.${translationPageKey}.DELETE_MODAL.MESSAGE`}
                    acceptText={`STATIC.PAGES.${translationPageKey}.DELETE_MODAL.ACCEPT_BUTTON_TEXT`}
                    rejectText={`STATIC.PAGES.${translationPageKey}.DELETE_MODAL.REJECT_BUTTON_TEXT`}
                    onAccept={this.onAcceptUanDelete}
                    onReject={this.onPopupClose}
                    showSpinner={uanDeleteInProccess}
                />
                <GeneralPopup
                    open={showErrorPopup}
                    type={PopupTypes.INFO}
                    headerStyle={{ fontSize: '18px', fontWeight: '600' }}
                    title={`STATIC.PAGES.${translationPageKey}.ERROR_MODAL.TITLE`}
                    text={`STATIC.PAGES.${translationPageKey}.ERROR_MODAL.MESSAGE`}
                    acceptText={`STATIC.PAGES.${translationPageKey}.ERROR_MODAL.ACCEPT_BUTTON_TEXT`}
                    onReject={this.onPopupClose}
                />
                {selectedUan && (
                    <GeneralPopup
                        open={showCancelDialog}
                        type={PopupTypes.INFO_WITH_CANCEL}
                        title={`STATIC.PAGES.${translationPageKey}.CANCEL_MODAL.TITLE`}
                        text={
                            NETWORKS_WITH_ACCOUNTS_NAMES.includes(selectedUan.name)
                                ? `STATIC.PAGES.${translationPageKey}.CANCEL_MODAL.MESSAGE_WITH_ACCOUNTS`
                                : `STATIC.PAGES.${translationPageKey}.CANCEL_MODAL.MESSAGE`
                        }
                        acceptText={`STATIC.PAGES.${translationPageKey}.CANCEL_MODAL.ACCEPT_BUTTON_TEXT`}
                        rejectText={`STATIC.PAGES.${translationPageKey}.CANCEL_MODAL.REJECT_BUTTON_TEXT`}
                        onAccept={this.onAcceptCancelAdnetworkLoginShelf}
                        onReject={this.onPopupClose}
                    />
                )}
                {selectedUan && (
                    <>
                        {/* In the future we will want to split DataDestinationsPage from DataSourcesPage component */}
                        {ExternalShelfElement && shelf.open ? (
                            <ExternalShelfElement
                                {...shelf}
                                adminMode={adminMode}
                                onClose={handleCloseShelf}
                                {...(isEditMode ? { uan: selectedUan } : { adnetwork: selectedUan })}
                                networkData={{ ...selectedUan, ...this.selectedRowData }}
                                translate={translate}
                            />
                        ) : (
                            <Shelf
                                {...shelf}
                                shelfSize="medium"
                                headerText={shelfHeader}
                                headerImagePath={selectedUan.logo_path}
                                imageStatusColor={
                                    this.selectedRowData && STATUSES_COLOR_MAP[this.selectedRowData.status]
                                }
                                closeOnClickOutside={false}
                                onClose={handleCloseShelf}
                            >
                                <AdnetworkBasicLogin
                                    networkData={{ ...selectedUan, ...this.selectedRowData }}
                                    adnetworks={adnetworks}
                                    partnersIds={partnersIds}
                                    onCancel={handleCloseShelf}
                                    translate={translate}
                                    onAuth={uanId => {
                                        route(redirectPath, { id: uanId });
                                    }}
                                    onChange={this.onAdnetworkLoginChange}
                                />
                            </Shelf>
                        )}
                    </>
                )}
                <Shelf
                    open={openFieldsAvailability}
                    shelfSize="medium"
                    headerText="STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.HEADER_TEXT"
                    HeaderImage={GlassesIcon}
                    headerImageClassName={css.fieldsAvailabilityShelfIcon}
                    closeOnClickOutside
                    onClose={this.closeFieldsAvailabilityShelf}
                >
                    <FieldsAvailability
                        adnetworkName={selectedUanAdnetworkName}
                        onClose={this.closeFieldsAvailabilityShelf}
                    />
                </Shelf>
                {dataShelfUan && (
                    <Shelf
                        open
                        shelfSize="large"
                        headerText={dataShelfUan.displayName}
                        headerImagePath={dataShelfUan.imgSrc}
                        imageStatusColor={STATUSES_COLOR_MAP[dataShelfUan.status]}
                        closeOnClickOutside
                        onClose={() => this.toggleDataRefreshShelf({}, false, false)}
                    >
                        <DataShelf
                            uanId={dataShelfUan.uanId}
                            uanName={dataShelfUan.displayName}
                            uanStatus={dataShelfUan.status?.toString()}
                            onClose={() => this.toggleDataRefreshShelf({}, false, false)}
                            translate={translate}
                            dataRefreshOpen={openDataRefresh}
                            canDataRefresh={dataShelfUan.canDataRefresh}
                            earliestDataDate={dataShelfUan.earliestDataDate}
                            timezone={dataShelfUan.orgTimezone}
                            channel={dataShelfUan.channel}
                        />
                    </Shelf>
                )}
                {dataConnectorAlertsUan && (
                    <Shelf
                        open={openDataConnectorAlerts}
                        shelfSize="large"
                        headerText={`${dataConnectorAlertsUan.displayName} Data Connector Alerts`}
                        HeaderImage={DataConnectorAlertsIcon}
                        headerImageClassName={css.dataConnectorAlertsShelfIcon}
                        onClose={() => this.toggleDataConnectorAlertsShelf({}, false)}
                    >
                        <DataConnectorAlerts
                            uanId={dataConnectorAlertsUan.uanId}
                            onClose={() => this.toggleDataConnectorAlertsShelf({}, false)}
                            isUserSlackAuth={isUserSlackAuth}
                            onDeleteUserSlackAuth={onDeleteUserSlackAuth}
                            translate={translate}
                        />
                    </Shelf>
                )}
                {isETLUpgradeAvaialble && <UpgradeETLBanner />}
            </PageWrapper>
        );
    }
}

DataSourcesPage.propTypes = {
    uansData: PropTypes.arrayOf(PropTypes.any),
    adnetworks: PropTypes.objectOf(PropTypes.any),
    partnersIds: PropTypes.objectOf(PropTypes.any),
    adminMode: PropTypes.bool,
    translate: PropTypes.func,
    export2Phase: PropTypes.func,
    openShelf: PropTypes.func,
    onCloseShelf: PropTypes.func,
    onUanDelete: PropTypes.func,
    onDeleteUserSlackAuth: PropTypes.func,
    selectedUanData: PropTypes.objectOf(PropTypes.any),
    shelf: PropTypes.objectOf(PropTypes.any),
    apiRequestFailed: PropTypes.bool,
    export2phaseLatestTaskKey: PropTypes.string,
    username: PropTypes.string,
    isLoadingUans: PropTypes.bool,
    uanAction: PropTypes.objectOf(PropTypes.any),
    updatedUanId: PropTypes.number,
    timezone: PropTypes.string,
    onValidationFinished: PropTypes.func.isRequired,
    adNetworkOptions: PropTypes.arrayOf(PropTypes.any),
    redirectPath: PropTypes.string,
    translationPageKey: PropTypes.string,
    archivedToggleEnabled: PropTypes.bool,
    emptyStateDataConnectors: PropTypes.arrayOf(PropTypes.number),
    mixpanelPrefix: PropTypes.string,
    template2phaseLink: PropTypes.string,
    isUserSlackAuth: PropTypes.bool,
    isStandardAnalytics: PropTypes.bool,
    showFieldsAvailabilityFeature: PropTypes.bool,
    featureFlags: PropTypes.arrayOf(PropTypes.string),
    etlStatusEnabled: PropTypes.bool,
    isContinuousETL: PropTypes.bool,
    location: PropTypes.objectOf(PropTypes.any),
};

DataSourcesPage.defaultProps = {
    uansData: [],
    adnetworks: {},
    partnersIds: new Set(),
    adminMode: false,
    translate: () => {},
    export2Phase: () => {},
    openShelf: () => {},
    onCloseShelf: () => {},
    onUanDelete: () => {},
    onDeleteUserSlackAuth: () => {},
    selectedUanData: {},
    shelf: { open: false },
    apiRequestFailed: false,
    template2phaseLink: '/logs/download?user={user_name}&api_type=download_2phase&task_id={task_id}',
    uanAction: {},
    updatedUanId: null,
    adNetworkOptions: [],
    redirectPath: 'react/data-sources',
    translationPageKey: PAGE_DATA_TYPES.DATA_SOURCES,
    archivedToggleEnabled: true,
    emptyStateDataConnectors: [345, 124, 77760, 377, 1530, 1377],
    mixpanelPrefix: DATA_CONNECTORS_EVENT_PREFIX,
    export2phaseLatestTaskKey: '',
    username: '',
    isLoadingUans: undefined,
    timezone: '',
    isUserSlackAuth: false,
    isStandardAnalytics: false,
    showFieldsAvailabilityFeature: false,
    location: null,
    featureFlags: [],
    etlStatusEnabled: false,
    isContinuousETL: false,
};

function DataSourcesPageWrapper(props) {
    const location = useLocation();
    return React.createElement(DataSourcesPage, {
        ...props,
        location,
    });
}

export { DataSourcesPage };
export default withLocalize(DataSourcesPageWrapper);
