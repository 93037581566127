import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { ROLE_RESTRICTED } from 'teamManagement/utils';
import WizardWarningMessage from 'teamManagement/components/WizardWarningMessage';
import { EmptyState, HelpMenu, Button } from 'components/widgets';

import TextField from '../components/molecules/TextField';
import CustomAlertsShelf from 'alerts/components/CustomAlertsShelf';
import {
    useFetchAlertsMetadata,
    useFetchReportTypes,
    useFetchOrgUsers,
    useFetchAlerts,
    useDeleteAlert,
    useFetchCanDataConnectorAlerts,
} from 'alerts/hooks';
import Spinner from 'components/widgets/Spinner';
import { trackMixpanelEvent } from 'utils/general';
import { TRACK_EVENT_PAGE_LOADED, TRACK_EVENT_PREFIX } from 'alerts/consts';
import CustomAlertsEmptyState from 'alerts/components/CustomAlertsEmptyState';
import PropTypes from 'prop-types';
import { sortAlphabetically } from 'utils/sortUtil';
import { TOUR_LINK_IDS } from 'utils/consts';
import GeneralPopup from '../components/organisms/GeneralPopup';
import { PageWrapper, WhitePageHeader } from '../components/partials';

import css from './AlertsPage.css';
import AlertItem from './components/AlertItem';
import SystemAlertShelf from './components/SystemAlertShelf';
import toaster from '../utils/toaster';

const AlertsPage = ({ userData, onDeleteUserSlackAuth, translate }) => {
    const [reportType, setReportType] = React.useState({});
    const [showSystemAlertsShelf, setShowSystemAlertsShelf] = useState(false);
    const [showCustomAlertsShelf, setShowCustomAlertsShelf] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [newCustomAlerts, setNewCustomAlerts] = useState([]);
    const [selectedCustomAlert, setSelectedCustomAlert] = useState({});
    const [shouldDeleteAlert, setShouldDeleteAlert] = useState(false);
    const [shouldPullAlerts, setShouldPullAlerts] = useState(false);
    const [resultError, setResultError] = useState(false);
    const [shouldDataConnectorAlerts, setShouldDataConnectorAlerts] = useState(true);
    const onCloseSystemAlerts = useCallback(() => {
        setShowSystemAlertsShelf(false);
    }, [setShowSystemAlertsShelf]);

    const { value: reportTypes = [], error: reportTypesError } = useFetchReportTypes();
    const { value: alerts = {}, error: alertsError, isLoading: alertsLoading } = useFetchAlerts(shouldPullAlerts);
    const { value: alertsPageMetadata = {}, error: alertsMetadataError } = useFetchAlertsMetadata();
    const { value: users = [], error: orgUsersError, isLoading: usersLoading } = useFetchOrgUsers();

    const { can_data_connector_alerts: canDataConnectorAlerts } = useFetchCanDataConnectorAlerts(
        shouldDataConnectorAlerts,
        setShouldDataConnectorAlerts
    );

    const errorMessage =
        alertsError?.message || reportTypesError?.message || orgUsersError?.message || alertsMetadataError?.message;
    if (!resultError && errorMessage) {
        toaster.error(errorMessage);
        setResultError(true);
    }

    const onDeleteCleanup = () => {
        setShouldDeleteAlert(false);
    };
    const { value: deletedAlertID } = useDeleteAlert(shouldDeleteAlert, selectedCustomAlert.id, onDeleteCleanup);

    useEffect(() => {
        trackMixpanelEvent(TRACK_EVENT_PREFIX, TRACK_EVENT_PAGE_LOADED);
    }, []);

    useEffect(() => {
        if (deletedAlertID === selectedCustomAlert.id) {
            setShouldPullAlerts(true);
            if (newCustomAlerts.length) {
                setNewCustomAlerts([...newCustomAlerts.filter(alert => alert.id !== deletedAlertID)]);
            }
            setTimeout(() => {
                setShowDeleteDialog(false);
            }, 400);
        }
    }, [deletedAlertID]);

    const systemAlerts = [
        {
            name: translate('STATIC.PAGES.ALERTS_V2.DATA_CONNECTOR_ALERT_NAME'),
            subHeader: translate('STATIC.PAGES.ALERTS_V2.DATA_CONNECTOR_ALERT_SUBHEADER'),
            id: 0,
        },
    ];

    const defaultFraudAlerts = 'Default Fraud Alerts';

    const onAddCustomAlertToTop = newAlert => {
        setNewCustomAlerts([newAlert, ...newCustomAlerts.filter(alert => alert.id !== newAlert.id)]);
    };

    const getCustomAlerts = () => {
        let customAlerts = [];
        if (alerts.custom_alerts !== undefined) {
            customAlerts = alerts.custom_alerts.filter(
                alert => !newCustomAlerts.map(newAlert => newAlert.id).includes(alert.id)
            );
            customAlerts.sort((a, b) => {
                return sortAlphabetically(a.name, b.name);
            });
        }
        customAlerts = [...newCustomAlerts, ...customAlerts];
        return customAlerts;
    };

    const searchAlerts = (searchText, alerts) => {
        if (!alerts) return [];
        return alerts.filter(alert => {
            return (
                searchText === '' ||
                alert.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
                (alert.creator && alert.creator.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) ||
                (alert.subHeader && alert.subHeader.toLowerCase().indexOf(searchText.toLowerCase()) >= 0)
            );
        });
    };

    const systemAlertsFiltered = searchAlerts(searchText, systemAlerts);
    const customAlertsFiltered = searchAlerts(searchText, getCustomAlerts());

    const isSystemAlerts = Object.keys(systemAlertsFiltered).length > 0;
    const isCustomAlerts = Object.keys(customAlertsFiltered).length > 0;
    const isDefaultCustomAlert =
        Object.keys(customAlertsFiltered).length === 1 &&
        customAlertsFiltered.some(alert => alert.name === defaultFraudAlerts);

    const isUserRestricted = userData.role_type === ROLE_RESTRICTED;
    const isUserSlackAuth = userData.is_slack_auth;
    const onCreateNewAlert = _reportType => {
        setSelectedCustomAlert({});
        setShowCustomAlertsShelf(true);
        setReportType({ ..._reportType });
    };

    const onDeleteApproved = () => {
        setShouldDeleteAlert(true);
        setShouldPullAlerts(false);
    };
    const onDeleteRejected = () => {
        setShowDeleteDialog(false);
    };

    return (
        <>
            <GeneralPopup
                open={showDeleteDialog}
                type="delete"
                title="STATIC.PAGES.ALERTS_V2.DELETE_MODAL.TITLE"
                text="STATIC.PAGES.ALERTS_V2.DELETE_MODAL.MESSAGE"
                acceptText="STATIC.PAGES.ALERTS_V2.DELETE_MODAL.ACCEPT_BUTTON_TEXT"
                rejectText="STATIC.PAGES.ALERTS_V2.DELETE_MODAL.REJECT_BUTTON_TEXT"
                onAccept={onDeleteApproved}
                onReject={onDeleteRejected}
                showSpinner={false}
            />
            <SystemAlertShelf
                showShelf={showSystemAlertsShelf}
                onDeleteUserSlackAuth={onDeleteUserSlackAuth}
                onCloseShelf={onCloseSystemAlerts}
                orgUsers={users}
                systemAlerts={alerts.system_alerts}
                shelfSize="medium"
                isAlertsFetched={!(alertsLoading && usersLoading)}
                isUserSlackAuth={isUserSlackAuth}
                isUserRestricted={isUserRestricted}
                onSetShouldPullAlerts={setShouldPullAlerts}
                canDataConnectorAlerts={canDataConnectorAlerts}
            />
            <div className={css.pageHeaderWrapper}>
                <WhitePageHeader
                    text="STATIC.PAGES.ALERTS_V2.PAGE_NAME"
                    className={css.pageHeader}
                    style={{ paddingLeft: '40px', height: '58px' }}
                    textStyle={{ fontWeight: '600', fontSize: '24px' }}
                />
            </div>
            <WizardWarningMessage
                show={false}
                showIcon
                showTypeIcon
                type="warning"
                message="STATIC.PAGES.ALERTS_V2.NEW_ALERT"
                textAlignCenter
            />
            {!resultError && (
                <PageWrapper className={css.pageWrapper}>
                    <HelpMenu
                        faqLink="https://singular.zendesk.com/hc/en-us/articles/207429293-Alerts-FAQ"
                        tourId={TOUR_LINK_IDS.alerts}
                    />
                    <div className={css.search}>
                        <TextField
                            clearable
                            value={searchText}
                            debounce={500}
                            onChange={setSearchText}
                            containerClass={css.searchInput}
                            placeholder={translate(`STATIC.PAGES.ALERTS_V2.SEARCH`)}
                        />
                        <Button
                            level="important"
                            text="STATIC.PAGES.ALERTS_V2.ADD_NEW_ALERT"
                            type="primary"
                            onClick={() => onCreateNewAlert(reportTypes[0])}
                            style={{ paddingLeft: '15px', paddingRight: '15px' }}
                        />
                    </div>
                    {(reportTypes.length === 0 || !alerts.custom_alerts) && (
                        <Spinner style={{ top: '10vh' }} show expanded />
                    )}
                    {alerts.custom_alerts && reportTypes.length > 0 && (
                        <>
                            {Object.keys(reportType).length > 0 && showCustomAlertsShelf && (
                                <CustomAlertsShelf
                                    reportTypes={reportTypes}
                                    reportType={reportType}
                                    onSetReportType={setReportType}
                                    showShelf={showCustomAlertsShelf}
                                    existingAlert={selectedCustomAlert}
                                    onSetSelectedAlert={setSelectedCustomAlert}
                                    onCloseShelf={() => setShowCustomAlertsShelf(false)}
                                    onAddCustomAlertToTop={onAddCustomAlertToTop}
                                    orgUsers={users}
                                    shelfSize="medium"
                                    alertsPageMetadata={alertsPageMetadata}
                                    onSetShouldPullAlerts={setShouldPullAlerts}
                                />
                            )}
                            {isSystemAlerts && (
                                <div className={css.alertTypeHeaderText}>
                                    <Translate id="STATIC.PAGES.ALERTS_V2.SYSTEM_ALERTS" />
                                </div>
                            )}
                            {systemAlertsFiltered.map(alert => {
                                return (
                                    <AlertItem
                                        key={alert.id}
                                        alert={alert}
                                        userData={userData}
                                        openShelf={() => setShowSystemAlertsShelf(true)}
                                        isSystemAlert
                                        systemAlerts={alerts.system_alerts}
                                        searchedText={searchText}
                                        isUserRestricted={isUserRestricted}
                                    />
                                );
                            })}
                            {isSystemAlerts && isCustomAlerts && <div className={css.divider} />}
                            {(isDefaultCustomAlert || !isCustomAlerts) && searchText === '' && (
                                <CustomAlertsEmptyState reportTypes={reportTypes} onCreateNewAlert={onCreateNewAlert} />
                            )}
                            {isCustomAlerts && (
                                <>
                                    <div className={css.alertTypeHeaderText}>
                                        <Translate id="STATIC.PAGES.ALERTS_V2.CUSTOM_ALERTS" />
                                    </div>
                                    {customAlertsFiltered.map(alert => {
                                        return (
                                            <AlertItem
                                                key={alert.id}
                                                reportTypes={reportTypes}
                                                alert={alert}
                                                userData={userData}
                                                onSetReportType={setReportType}
                                                openShelf={() => setShowCustomAlertsShelf(true)}
                                                onSetSelectedAlert={setSelectedCustomAlert}
                                                isSystemAlert={false}
                                                searchedText={searchText}
                                                openDeleteDialog={() => setShowDeleteDialog(true)}
                                            />
                                        );
                                    })}
                                </>
                            )}
                        </>
                    )}
                    {!isSystemAlerts && !isCustomAlerts && alerts.custom_alerts && searchText && (
                        <>
                            <div className={css.emptyState}>
                                <EmptyState icon="happyPage" header="STATIC.PAGES.ALERTS_V2.SEARCH_EMPTY_STATE" />
                            </div>
                        </>
                    )}
                </PageWrapper>
            )}
        </>
    );
};

AlertsPage.propTypes = {
    userData: PropTypes.objectOf(PropTypes.any),
    onDeleteUserSlackAuth: PropTypes.func,
    translate: PropTypes.func,
};

AlertsPage.defaultProps = {
    userData: {},
    onDeleteUserSlackAuth: () => {},
    translate: () => {},
};

export default withLocalize(AlertsPage);
