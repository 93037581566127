import { AutoCompleteFieldEnhanced, FormField } from 'utils/components/formUtils';
import { isAssetFieldType } from 'governance/utils';
import { DROPDOWN_TYPES, FIELD_TYPES } from 'governance/consts';
import React from 'react';
import { useFormikContext } from 'formik-2';
import { EMPTY_OPTIONS } from 'banners/consts';
import PropTypes from 'prop-types';
import FileField from '../../components/molecules/FileField';
import css from './table.css';

const ALL_IMAGES = 'image/*';
const ALL_VIDEOS = 'video/*';

export default function NewCodeForm({ schema }) {
    const { values, setValues, touched, errors } = useFormikContext();

    return (
        <div className={css.newCodeFormContainer}>
            {schema?.fields.map(field => {
                const potentialValues = schema.fields
                    .find(schemaField => schemaField.name === field.name)
                    .values.map(value => ({
                        label: value?.value,
                        value: value?.code,
                    }));
                return (
                    <div key={`schema.${schema.id}.field.${field.name}`} className={css.formFieldContainer}>
                        {(field.kind === 'autocomplete' || field.kind === 'tag' || field.kind === 'text') && (
                            <FormField className={css.formField}>
                                <AutoCompleteFieldEnhanced
                                    name={field.guid}
                                    searchable
                                    ignoreOnInputChange={DROPDOWN_TYPES.includes(field.kind)}
                                    placeholder={field.name}
                                    clearable={DROPDOWN_TYPES.includes(field.kind)}
                                    loading={false}
                                    isMulti={field.kind === 'tag'}
                                    debounceTime={500}
                                    label={field.name}
                                    mandatory={!field.optional}
                                    options={DROPDOWN_TYPES.includes(field.kind) ? potentialValues : EMPTY_OPTIONS}
                                    disabled={DROPDOWN_TYPES.includes(field.kind) && potentialValues.length === 0}
                                    error={touched[field.guid] && errors[field.guid]}
                                    value={values[field.guid] ?? []}
                                    dataTestId={`acf-code_shelf-${field.name}`}
                                />
                            </FormField>
                        )}
                        {isAssetFieldType(field.kind) && (
                            <FormField className={css.formField}>
                                <FileField
                                    clearable
                                    value={values[field.guid]}
                                    label={field.name}
                                    uploadText="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.SHELF.UPLOAD_LOGO_TEXT"
                                    clearText="STATIC.PAGES.GOVERNANCE.MANAGE_CODES.SHELF.DELETE_LOGO_TEXT"
                                    onChange={value => setValues({ ...values, [field.guid]: value })}
                                    error={touched[field.guid] && errors[field.guid]}
                                    acceptableTypes={[ALL_IMAGES, ALL_VIDEOS]}
                                />
                            </FormField>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

NewCodeForm.propTypes = {
    schema: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        pattern: PropTypes.string,
        fields: PropTypes.arrayOf(
            PropTypes.shape({
                guid: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                kind: PropTypes.oneOf(FIELD_TYPES.map(x => x.value)),
                values: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: PropTypes.string,
                        value: PropTypes.string,
                    })
                ).isRequired,
                default: PropTypes.arrayOf(
                    PropTypes.shape({
                        code: PropTypes.string,
                        value: PropTypes.string,
                    })
                ).isRequired,
                optional: PropTypes.bool,
                hidden: PropTypes.bool,
            })
        ).isRequired,
    }).isRequired,
};
