import React, { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import DeviceIcon from '../resources/svg/device.svg';
import { EmptyStateBox, Label, Button } from '../components/widgets';
import TestingConsoleShelf from './components/TestingConsoleShelf';
import Tooltip from '../components/molecules/Tooltip';
import LogsWrapper from './components/LogsWrapper';
import { EmptyStateTasks, trackEmptyStateMixPanelEvents } from '../components/partials/EmptyState/utils';
import { GeneralPopup, PageWrapper, PopupTypes, StickyPageHeader } from '../components/partials';
import { useDeleteDevice, useFetchDevices, useFetchLogs, useTriggerSDKAudit } from './hooks';
import {
    DATA_TEST_ID_TESTING_CONSOLE,
    DATA_TEST_ID_DEVICES,
    DEVICE_SELECTION_LIMIT,
    MIXPANEL_EVENT_FILTERED_DEVICE,
    MIXPANEL_EVENT_PREFIX,
    EMPTY_STATE_BOXES_TASKS,
    MIXPANEL_EVENT_DELETED_DEVICE,
} from './consts';
import { EDIT_DEVICE_INITIAL_STATE, SELECTED_DEVICES_INITIAL_STATE } from '../utils/consts';
import { getImpersonationFromQuery } from '../utils/url';
import {
    POLLING_EXPIRED_TIME,
    choseDevice,
    dataExist,
    defaultEmptyStateValue,
    organizeDevicesPresentation,
    TESTING_CONSOLE_STATES,
    prepareDevicePresentation,
    parseLogs,
    getTestingConsoleEmptyStateData,
} from './utils';
import { getUserData } from '../selectors/user';
import { getEmptyStateOrgData } from '../selectors/onboarding';
import css from './page.css';
import { trackMixpanelEvent } from '../utils/general';
import TestingConsoleAPI from './service';
import NewTagInput, { TAG_INPUT_VALUE_ACTIONS } from '../components/widgets/NewTagInput';
import {
    StickyPageHeaderActions,
    StickyPageHeaderEmptyState,
} from '../components/organisms/PageOutlines/StickyPageHeader';
import SingularLoader from '../../../dashboard/js/common/components/SingularLoader';

const TestingConsole = () => {
    const [firstPageLoad, setFirstPageLoad] = useState(true);
    const [shouldFetchDevices, setShouldFetchDevices] = useState(false);
    const [showDeleteDeviceDialog, setShowDeleteDeviceDialog] = useState(false);
    const [shouldDeleteDevice, setShouldDeleteDevice] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const deviceIdToDelete = useRef();

    const onFetchDevicesCleanup = () => {
        setFirstPageLoad(false);
        setShouldFetchDevices(false);
    };
    const { value: devices = [] } = useFetchDevices(firstPageLoad, shouldFetchDevices, onFetchDevicesCleanup);

    const [shelfOpened, setShelfOpened] = useState(false);
    const [editDevice, setEditDevice] = useState(EDIT_DEVICE_INITIAL_STATE);

    const [selectedDevices, setSelectedDevices] = useState(SELECTED_DEVICES_INITIAL_STATE);
    const allDevicesPresentation = useMemo(() => {
        return organizeDevicesPresentation(devices);
    }, [devices]);

    const userData = useSelector(state => getUserData(state));
    const emptyStateData = useSelector(state => getEmptyStateOrgData(state));

    const { logs, setLogs, isLoading, isPollingStopped, killPoll, respawnPoll } = useFetchLogs(
        selectedDevices,
        POLLING_EXPIRED_TIME
    );

    const [shouldTriggerAudit, setShouldTriggerAudit] = useState(false);
    // small hack to make audit button loader work good because loading state is initialized to true in useRequest
    const [auditFirstTimeTrigger, setAuditFirstTimeTrigger] = useState(false);
    const onTriggerAuditCleanup = () => {
        setShouldTriggerAudit(false);
    };
    const { isLoading: sdkAuditInProgress, value: { validationId } = {} } = useTriggerSDKAudit(
        shouldTriggerAudit,
        onTriggerAuditCleanup
    );

    const onCloseShelf = () => {
        setShelfOpened(false);
    };
    const openShelfLogic = device => {
        setEditDevice({ ...device });
        setShelfOpened(true);
    };

    const showDeviceDeleteDialog = deletedDeviceId => {
        setShowDeleteDeviceDialog(true);
        deviceIdToDelete.current = deletedDeviceId;
    };

    const deviceDeleteDialogCleanup = () => {
        setShowDeleteDeviceDialog(false);
        deviceIdToDelete.current = null;
    };

    const onDeviceDelete = deletedDeviceId => {
        const deletedDeviceIndex = selectedDevices.findIndex(device => device.id === deletedDeviceId);

        if (deletedDeviceIndex >= 0) {
            const updatedDevices = [
                ...selectedDevices.slice(0, deletedDeviceIndex),
                ...selectedDevices.slice(deletedDeviceIndex + 1),
            ];
            setSelectedDevices(updatedDevices);

            if (!updatedDevices?.length) killPoll();
        }

        onCloseShelf();
        deviceDeleteDialogCleanup();
        setShouldFetchDevices(true);
    };

    const { isLoading: deviceDeleteInProcess, status: deleteStatus } = useDeleteDevice(
        shouldDeleteDevice,
        () => {
            setShouldDeleteDevice(false);
        },
        deviceIdToDelete.current
    );

    useEffect(() => {
        if (!deviceDeleteInProcess && deleteStatus === 0) {
            onDeviceDelete(deviceIdToDelete.current);
            trackMixpanelEvent(MIXPANEL_EVENT_PREFIX, MIXPANEL_EVENT_DELETED_DEVICE, {
                ...devices.find(device => device.id === deviceIdToDelete.current),
            });
        }
    }, [deleteStatus, deviceDeleteInProcess]);

    useEffect(() => {
        if (!isEmpty(emptyStateData) || devices.length > 0) {
            setShowLoader(false);
        }
    }, [emptyStateData, devices]);

    // Top Empty State Logic
    const isAppAdded = emptyStateData?.tasks[EmptyStateTasks.ADD_APP];
    const isRegisterDevice = emptyStateData?.tasks[EmptyStateTasks.REGISTER_DEVICE] || devices?.length > 0;
    const showTestingConsoleEmptyState = (!isEmpty(emptyStateData) && !isAppAdded) || !isRegisterDevice;

    const calculatePageProgressState = () => {
        let progressState = TESTING_CONSOLE_STATES.LOGS_RUNNING;
        if (!choseDevice(selectedDevices)) {
            progressState = TESTING_CONSOLE_STATES.NEED_TO_CHOOSE_DEVICE;
        } else if (!dataExist(logs)) {
            progressState = TESTING_CONSOLE_STATES.NO_LOGS_FOR_CURRENT_DEVICE;
        }
        return progressState;
    };

    const [pageProgressState, setPageProgressState] = useState(() => calculatePageProgressState());

    const emptyStateTestingConsoleData = getTestingConsoleEmptyStateData();

    const emptyStateActions = taskTopic => {
        if (taskTopic === EmptyStateTasks.REGISTER_DEVICE) {
            openShelfLogic({});
        } else if (taskTopic === EmptyStateTasks.ADD_APP) {
            window.location.href = '#/apps?new-app=1';
        } else {
            console.warn("emptyStateActions default, shouldn't happen!");
        }
    };

    useLayoutEffect(() => {
        const testingConsoleAPI = new TestingConsoleAPI();
        const getDeviceLogs = async () => {
            const { status, value } = await testingConsoleAPI.getLogs(selectedDevices.map(device => device.id));
            return status === 0 ? value : [];
        };
        if (choseDevice(selectedDevices)) {
            getDeviceLogs().then(res => setLogs(res));
        }
    }, [selectedDevices]);

    useEffect(() => {
        if (choseDevice(selectedDevices)) {
            respawnPoll();
        }
    }, [selectedDevices]);

    useEffect(() => {
        setPageProgressState(calculatePageProgressState());
    }, [logs, emptyStateData, selectedDevices, devices]);

    useEffect(() => {
        if (validationId && !sdkAuditInProgress) {
            let url = `/logs/download?validation_id=${validationId}&validation_type=sdk`;
            const impersonationUser = getImpersonationFromQuery();
            if (impersonationUser) {
                url += `&user=${decodeURIComponent(impersonationUser)}`;
            }
            const impersonatedOrganization = getImpersonationFromQuery('customer_id');
            if (impersonatedOrganization) {
                url += `&customer_id=${impersonatedOrganization}`;
            }

            window.open(url, '_blank');
        }
    }, [sdkAuditInProgress, validationId]);

    const runSDKAudit = () => {
        if (!auditFirstTimeTrigger) {
            setAuditFirstTimeTrigger(true);
        }
        setShouldTriggerAudit(true);
    };

    const onDeviceSave = savedDevice => {
        onCloseShelf();
        setShouldFetchDevices(true);

        const savedDeviceIndex = selectedDevices.findIndex(device => device.id === savedDevice.id);

        setSelectedDevices([
            ...selectedDevices.slice(0, savedDeviceIndex),
            ...selectedDevices.slice(savedDeviceIndex + 1),
            prepareDevicePresentation(savedDevice),
        ]);
    };

    /***
     * Saving state of the selected devices expect a case where choosing an already selected value (DESELECT_OPTION)
     * @param {array} currentSelectedDevices the selected devices
     * @param {object} event the original change event.
     */
    const onDeviceSelection = (currentSelectedDevices, event) => {
        if (event.action !== TAG_INPUT_VALUE_ACTIONS.DESELECT_OPTION) {
            killPoll();
            setLogs([]);
            setSelectedDevices(currentSelectedDevices);

            if (currentSelectedDevices) {
                trackMixpanelEvent(MIXPANEL_EVENT_PREFIX, MIXPANEL_EVENT_FILTERED_DEVICE, {
                    devices: currentSelectedDevices,
                });
            }
        }
    };

    const emptyStateBoxCTA = task => {
        const { icon, translateKey } = EMPTY_STATE_BOXES_TASKS[task];

        return (
            <>
                <Label
                    text={`STATIC.PAGES.TESTING_CONSOLE.EMPTY_STATE.${translateKey}_TITLE`}
                    type="h1"
                    className={css.emptyStateBoxTitle}
                />
                <EmptyStateBox
                    title={`STATIC.PAGES.TESTING_CONSOLE.EMPTY_STATE.${translateKey}_CTA`}
                    Icon={icon}
                    onClick={() => {
                        trackEmptyStateMixPanelEvents('clicked', {
                            page: 'STATIC.PAGE_HEADERS.TESTING_CONSOLE',
                            clicked: task,
                            emptyStateType: 'box',
                        });
                        emptyStateActions(task);
                    }}
                />
            </>
        );
    };

    return (
        <>
            {showLoader ? (
                <SingularLoader />
            ) : (
                <>
                    <StickyPageHeader
                        title="STATIC.PAGE_HEADERS.TESTING_CONSOLE"
                        helpMenuContent={{
                            faqLink:
                                'https://support.singular.net/hc/en-us/articles/115000497903-Using-the-SDK-Console',
                        }}
                    >
                        <>
                            {showTestingConsoleEmptyState ? (
                                <StickyPageHeaderEmptyState
                                    {...emptyStateTestingConsoleData}
                                    tasksDone={emptyStateData?.tasks}
                                />
                            ) : (
                                <StickyPageHeaderActions>
                                    <div className={css.actionsContainer}>
                                        <div
                                            className={classNames(css.filters, {
                                                [css.filtersHide]: !isRegisterDevice,
                                            })}
                                        >
                                            <NewTagInput
                                                dataTestId={DATA_TEST_ID_DEVICES}
                                                placeholder="STATIC.PAGES.TESTING_CONSOLE.SELECT_DEVICES"
                                                tags={selectedDevices}
                                                options={allDevicesPresentation}
                                                disabled={!allDevicesPresentation?.length}
                                                onEditClick={device => openShelfLogic(device)}
                                                onDeleteClick={device => showDeviceDeleteDialog(device.id)}
                                                onChange={onDeviceSelection}
                                                className={classNames(css.tagInputClass, {
                                                    [css.tagInputEmptyClass]: !selectedDevices.length,
                                                })}
                                                tagLabelClass={css.tagLabelClass}
                                                headerIcon={<DeviceIcon />}
                                                headerText="STATIC.PAGES.TESTING_CONSOLE.DEVICES_SELECTION_LABEL"
                                                hideSelectedOptions={false}
                                                tagsLimit={DEVICE_SELECTION_LIMIT}
                                                isClearable
                                            />
                                        </div>
                                        <div className={css.middleButtonPanel}>
                                            <Tooltip
                                                className={css.auditToolTip}
                                                titleTranslationKey="STATIC.PAGES.TESTING_CONSOLE.RUN_AUDIT_TOOLTIP"
                                            >
                                                <Button
                                                    type="secondary"
                                                    level="level1"
                                                    text="STATIC.PAGES.TESTING_CONSOLE.RUN_AUDIT"
                                                    onClick={() => runSDKAudit()}
                                                    showSpinner={auditFirstTimeTrigger && sdkAuditInProgress}
                                                />
                                            </Tooltip>
                                            <Button
                                                type="primary"
                                                level="level1"
                                                className={css.addDeviceButton}
                                                onClick={() => openShelfLogic({})}
                                                text="STATIC.PAGES.TESTING_CONSOLE.ADD_DEVICE"
                                            />
                                        </div>
                                    </div>
                                </StickyPageHeaderActions>
                            )}
                        </>
                    </StickyPageHeader>
                    <PageWrapper className={css.pageWrapper} dataTestId={DATA_TEST_ID_TESTING_CONSOLE}>
                        {showTestingConsoleEmptyState && (
                            <div className={css.emptyStateBox}>
                                {emptyStateBoxCTA(
                                    !isAppAdded ? EmptyStateTasks.ADD_APP : EmptyStateTasks.REGISTER_DEVICE
                                )}
                            </div>
                        )}
                        {!showTestingConsoleEmptyState && (
                            <LogsWrapper
                                pageProgressState={pageProgressState}
                                isDeviceSelected={choseDevice(selectedDevices)}
                                isLogsPolling={!isPollingStopped}
                                respawnPolling={respawnPoll}
                                isLogsLoading={isLoading}
                                logs={parseLogs(logs)}
                                selectedDevices={selectedDevices}
                            />
                        )}
                        <TestingConsoleShelf
                            editedDevice={editDevice}
                            deviceList={devices}
                            open={shelfOpened}
                            onClose={onCloseShelf}
                            onDeviceSave={onDeviceSave}
                            onDeviceDelete={showDeviceDeleteDialog}
                        />
                        <GeneralPopup
                            open={showDeleteDeviceDialog}
                            text="STATIC.PAGES.TESTING_CONSOLE.DELETE_POPUP.CONTENT"
                            acceptText="STATIC.PAGES.TESTING_CONSOLE.DELETE_POPUP.ACCEPT_BUTTON_TEXT"
                            rejectText="STATIC.PAGES.TESTING_CONSOLE.DELETE_POPUP.CANCEL_BUTTON_TEXT"
                            type={PopupTypes.DELETE}
                            onAccept={() => setShouldDeleteDevice(true)}
                            onReject={() => setShowDeleteDeviceDialog(false)}
                            showSpinner={deviceDeleteInProcess}
                        />
                    </PageWrapper>
                </>
            )}
        </>
    );
};

export default withLocalize(TestingConsole);
