import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import validator from 'validator';
import { PageHeader, PageWrapper } from '../components/partials';
import { AutoCompleteField, Label, Button, WarningMessage } from '../components/widgets';
import TextField from '../components/molecules/TextField';
import css from './NikeSocialLinks.css';
import RadioButtonsGroup from '../components/widgets/RadioButtonsGroup';
import { getUserData } from '../selectors/user';
import { trackMixpanelEvent } from '../utils/general';
import AppsService from '../services/apps';
import NikeSocialLinksService from './service';
import { useAsync } from '../utils/customHooks';
import CopyArea from '../components/widgets/CopyArea';

const appsApi = new AppsService();

function NikeSocialLinks() {
    const [shouldRedirectAppStore, setShouldRedirectAppStore] = useState(true);
    const [shouldRedirectAndroidStore, setShouldRedirectAndroidStore] = useState(true);
    const [webURl, setWebUrl] = useState('');
    const [cpParameter, setCpParameter] = useState(null);
    const [psnParameter, setPsnParameter] = useState(null);
    const [app, setApp] = useState(null);
    const [apps, setApps] = useState(null);
    const [response, run] = useAsync();
    const { data, error } = response;
    const user = useSelector(state => getUserData(state));

    const api = new NikeSocialLinksService();

    const onCreateSocialLinkClicked = async () => {
        trackMixpanelEvent('Social Links', 'Create Social Link', { user: user.email });
        await run(
            api.createSocialLink(
                app,
                webURl,
                cpParameter,
                psnParameter,
                shouldRedirectAndroidStore,
                shouldRedirectAppStore
            )
        );
    };

    const _onAndroidRadioButtonClicked = () => {
        setShouldRedirectAndroidStore(!shouldRedirectAndroidStore);
    };

    const _onAppleRadioButtonClicked = () => {
        setShouldRedirectAppStore(!shouldRedirectAppStore);
    };

    const getAppsData = async () => {
        const responseApps = await appsApi.getApps(false);
        setApps(responseApps.apps);
    };

    useEffect(() => {
        getAppsData();
    }, []);

    const isUrlValid = webURl && !validator.isURL(webURl);
    const createLinkEnabled = psnParameter && cpParameter && webURl && app && !isUrlValid;
    const appOptions = apps ? apps.map(appOption => ({ label: appOption.name, value: appOption.id })) : [];

    return (
        <PageWrapper className={css.page}>
            <PageHeader text="STATIC.PAGE_HEADERS.NIKE_SOCIAL_LINKS" />
            <Label text="STATIC.PAGES.NIKE_SOCIAL_LINKS.INSTRUCTIONS" type="shelfHeader" />
            <div className={css.search}>
                <Label text="STATIC.PAGES.NIKE_SOCIAL_LINKS.SELECT_APP" className={css.textField} type="shelfHeader" />
                <AutoCompleteField
                    containerClass={css.box}
                    value={app}
                    options={appOptions}
                    isMulti={false}
                    onChange={appOption => setApp(appOption.value)}
                    placeholder="STATIC.PAGES.NIKE_SOCIAL_LINKS.SELECT_APP"
                />
            </div>
            <div className={css.search}>
                <Label text="STATIC.PAGES.NIKE_SOCIAL_LINKS.WEB_URL" className={css.textField} type="shelfHeader" />
                <TextField clearable value={webURl} debounce={500} onChange={setWebUrl} containerClass={css.box} />
            </div>
            <div className={css.search}>
                <Label
                    text="STATIC.PAGES.NIKE_SOCIAL_LINKS.CP_PARAMETER"
                    className={css.textField}
                    type="shelfHeader"
                />
                <TextField
                    clearable
                    value={cpParameter}
                    debounce={500}
                    onChange={setCpParameter}
                    containerClass={css.box}
                />
            </div>
            <div className={css.search}>
                <Label
                    text="STATIC.PAGES.NIKE_SOCIAL_LINKS.PSN_PARAMETER"
                    className={css.textField}
                    type="shelfHeader"
                />
                <TextField
                    clearable
                    value={psnParameter}
                    debounce={500}
                    onChange={setPsnParameter}
                    containerClass={css.box}
                />
            </div>
            <Label text="STATIC.PAGES.NIKE_SOCIAL_LINKS.LINK_BEHAVIOR" className={css.sectionTitle} type="h2" />
            <Label text="STATIC.PAGES.NIKE_SOCIAL_LINKS.REDIRECT_TO" className={css.redirect} type="h3" />
            <RadioButtonsGroup
                horizontal
                itemsClassName={css.radioBtn}
                radioItems={[
                    {
                        label: 'STATIC.PAGES.NIKE_SOCIAL_LINKS.ANDROID_STORE',
                        checked: shouldRedirectAndroidStore,
                        id: 'android-store',
                    },
                    {
                        label: 'STATIC.PAGES.NIKE_SOCIAL_LINKS.ANDROID_WEB',
                        checked: !shouldRedirectAndroidStore,
                        id: 'android-web',
                    },
                ]}
                onChange={_onAndroidRadioButtonClicked}
            />

            <RadioButtonsGroup
                horizontal
                itemsClassName={css.radioBtn}
                radioItems={[
                    {
                        label: 'STATIC.PAGES.NIKE_SOCIAL_LINKS.APPLE_STORE',
                        checked: shouldRedirectAppStore,
                        id: 'apple-store',
                    },
                    {
                        label: 'STATIC.PAGES.NIKE_SOCIAL_LINKS.APPLE_WEB',
                        checked: !shouldRedirectAppStore,
                        id: 'apple-web',
                    },
                ]}
                onChange={_onAppleRadioButtonClicked}
            />
            <Button
                showSpinner={response?.isLoading}
                text="STATIC.PAGES.NIKE_SOCIAL_LINKS.CREATE_SOCIAL_LINK"
                disabled={!createLinkEnabled}
                onClick={onCreateSocialLinkClicked}
                className={css.createLinkBtn}
            />
            <WarningMessage show={isUrlValid} message="STATIC.PAGES.NIKE_SOCIAL_LINKS.INVALID_URL" />
            {data?.value ? (
                <CopyArea
                    style={css.linkBox}
                    copyText="STATIC.BUTTONS.COPY"
                    copiedText="STATIC.BUTTONS.COPIED"
                    content={data.value.link || ''}
                />
            ) : null}
            {error?.value?.message ? <WarningMessage message={error.value.message} /> : null}
        </PageWrapper>
    );
}

export default withLocalize(NikeSocialLinks);
