import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Translate } from 'react-localize-redux';
import Chevron from '../../../resources/icons/chevron.svg';

import css from './CreativePopup.css';
import { CreativeType } from '../../types';
import { AutoCompleteOptionType } from '../../../components/molecules/AutoCompleteField';
import CreativeAsset from '../creativeAsset/CreativeAsset';
import CreativePopupDimensionTags from '../creativePopupDimensionTags/CreativePopupDimensionTags';
import CreativeAITags from '../creativeAITags/CreativeAITags';
import { UNKNOWN_INDICATION } from '../../creativesFormattingUtils';

export const GO_BACK_BUTTON_TITLE = 'goBackButton';
export const DIMENSION_NAME_CELL_TEST_ID = 'dimension-name-cell';
export const CREATIVE_POPUP_TITLE = 'STATIC.PAGES.TAGS_VISIBILITY.CREATIVE_POPUP.TITLE';

export const formatValues = values => (values.length ? values.join(', ') : UNKNOWN_INDICATION);

const ASSET_ASPECT_RATIO = 550 / 400;
export const getTranslationKey = key => `STATIC.PAGES.TAGS_VISIBILITY.DIMENSIONS_INFO.${key}`;

const CreativePopup = ({ app, source, creative, onClose }) => {
    const assetRef = useRef();
    const [videoDuration, setVideoDuration] = useState();

    const closeOnEscape = useCallback(event => event.key === 'Escape' && onClose(), [onClose]);

    const { assetIdList, assetNameList, creativeIdList, creativeNameList } = creative.multiValuedDimensions;
    const isAssetName = !!assetNameList?.length;
    const isAssetId = !!assetIdList?.length;

    useEffect(() => {
        document.addEventListener('keydown', closeOnEscape, false);
        return () => document.removeEventListener('keydown', closeOnEscape, false);
    }, [closeOnEscape]);

    const onLoad = () => {
        setVideoDuration(assetRef?.current?.duration);
    };

    return (
        <div className={css.creativePopup}>
            <div className={css.header}>
                <div className={css.goBackContainer} onClick={onClose}>
                    <Chevron title={GO_BACK_BUTTON_TITLE} className={css.goBackIcon} />
                    <div className={css.goBackText}>
                        <Translate id="STATIC.PAGES.TAGS_VISIBILITY.CREATIVE_POPUP.GO_BACK" />
                    </div>
                </div>
                <div className={css.title}>
                    <Translate id={CREATIVE_POPUP_TITLE} />
                </div>
            </div>
            <div className={css.content}>
                <div className={css.creativeData}>
                    <CreativePopupDimensionTags
                        creative={creative}
                        source={source}
                        app={app}
                        videoDuration={videoDuration}
                    />
                    <CreativeAsset
                        ref={assetRef}
                        type={creative.type}
                        url={creative.url}
                        className={css.creativeAsset}
                        controls
                        aspectRatio={ASSET_ASPECT_RATIO}
                        onLoad={onLoad}
                        muted={false}
                    />
                    <div className={css.creativeRawData}>
                        <Translate
                            id={getTranslationKey(`${isAssetName ? 'ASSET' : 'CREATIVE'}_NAME`)}
                            data={{ value: formatValues(isAssetName ? assetNameList : creativeNameList || []) }}
                        />
                        <Translate
                            id={getTranslationKey(`${isAssetId ? 'ASSET' : 'CREATIVE'}_ID`)}
                            data={{ value: formatValues(isAssetId ? assetIdList : creativeIdList || []) }}
                        />
                    </div>
                </div>
                <CreativeAITags app={app.value} imageHash={creative.imageHash} />
            </div>
        </div>
    );
};

CreativePopup.propTypes = {
    creative: PropTypes.shape(CreativeType).isRequired,
    app: AutoCompleteOptionType.isRequired,
    source: AutoCompleteOptionType.isRequired,
    onClose: PropTypes.func,
};

CreativePopup.defaultProps = {};

export default CreativePopup;
