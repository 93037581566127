import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import CheckedIcon from '../../resources/icons/check_mark.svg';
import MagicIcon from '../../resources/svg/magic-wand.svg';
import XIcon from '../../resources/svg/no_data.svg';
import css from '../styles.css';
import { AVAILABILITY_TYPES, FIELDS_AVAILABILITY_MIXPANEL_PREFIX } from '../consts';
import Tooltip from '../../components/molecules/Tooltip';
import { trackMixpanelEvent } from '../../utils/general';

const FieldsAvailabilityTableCell = ({ availabilityList, availabilityKey, displayName, hideTooltip }) => {
    const onIconMouseLeave = useCallback(
        throttle(availabilityType => {
            trackMixpanelEvent(FIELDS_AVAILABILITY_MIXPANEL_PREFIX, 'Hover On Icon', {
                iconType: availabilityType,
                dimensionName: displayName,
            });
        }, 2000),
        []
    );

    const getInnerComponent = (availabilityList, availabilityKey) => {
        let component;
        let tooltipText;

        if (availabilityList.includes(`${availabilityKey}__${AVAILABILITY_TYPES.CALCULATED}`)) {
            component = (
                <MagicIcon
                    onMouseLeave={() => {
                        onIconMouseLeave(AVAILABILITY_TYPES.CALCULATED);
                    }}
                    className={classNames(css.indicationTdIcon, css.active)}
                />
            );
            tooltipText = 'STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.TABLE_TOOLTIPS.CALCULATED';
        } else if (availabilityList.includes(`${availabilityKey}__${AVAILABILITY_TYPES.MAPPED}`)) {
            tooltipText = 'STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.TABLE_TOOLTIPS.MAPPED';
            component = (
                <MagicIcon
                    onMouseLeave={() => {
                        onIconMouseLeave(AVAILABILITY_TYPES.MAPPED);
                    }}
                    className={classNames(css.indicationTdIcon, css.active)}
                />
            );
        } else if (availabilityList.includes(availabilityKey)) {
            component = <CheckedIcon className={classNames(css.indicationTdIcon, css.active)} />;
        } else if (availabilityList.includes(`${availabilityKey}__${AVAILABILITY_TYPES.COMBINED}`)) {
            component = (
                <MagicIcon
                    onMouseLeave={() => {
                        onIconMouseLeave(AVAILABILITY_TYPES.COMBINED);
                    }}
                    className={classNames(css.indicationTdIcon, css.active)}
                />
            );
            tooltipText = 'STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.TABLE_TOOLTIPS.COMBINED';
        } else if (availabilityList.includes(`${availabilityKey}__${AVAILABILITY_TYPES.NOT_PULLED}`)) {
            component = <XIcon className={classNames(css.noData, css.indicationTdIcon)} />;
            tooltipText = 'STATIC.PAGES.FIELDS_AVAILABILITY_SHELF.TABLE_TOOLTIPS.NOT_PULLED';
        } else {
            component = <div className={classNames(css.minus, css.indicationTdIcon)} />;
        }

        return { component, tooltipText };
    };

    const { component, tooltipText } = getInnerComponent(availabilityList, availabilityKey);

    return tooltipText && !hideTooltip ? (
        <Tooltip
            interactive
            titleTranslationKey={tooltipText}
            wrapperClass={classNames(css.tooltipWrapper, { [css.pointer]: tooltipText })}
        >
            {component}
        </Tooltip>
    ) : (
        component
    );
};

FieldsAvailabilityTableCell.propTypes = {
    availabilityList: PropTypes.arrayOf(PropTypes.string).isRequired,
    availabilityKey: PropTypes.string.isRequired,
    hideTooltip: PropTypes.bool,
};

FieldsAvailabilityTableCell.defaultProps = {
    hideTooltip: false,
};

export default FieldsAvailabilityTableCell;
