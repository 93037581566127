import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';

import classnames from 'classnames';
import Shelf from '../components/widgets/Shelf';
import { Label } from '../components/widgets';
import AutoComplete from '../components/molecules/AutoComplete';
import css from './widgetShelf.css';
import NewTagInput from '../components/widgets/NewTagInput';
import Spinner from '../components/widgets/Spinner';
import { CUSTOM_DASHBOARD_CHART_TYPES } from '../components/partials/PivotChart/consts';
import TextField from '../components/molecules/TextField';
import FilterFields from '../components/partials/FilterFields';
import { OPERATOR_TYPES } from './consts';
import useParseDimsMetricsAndFilters from './widgetFields';
import { DataTypes, RESTRICTION_TYPE, WIDGET_DATA_TYPES } from './widgetDataTypes';
import { getAdminModeEnabled, getUserData } from '../selectors/user';
import Dropdown from '../components/molecules/Dropdown';

import ColumnPreview from '../resources/png/dashboards/previews/column.png';
import BarPreview from '../resources/png/dashboards/previews/bar.png';
import StackedPreview from '../resources/png/dashboards/previews/stacked.png';
import LinePreview from '../resources/png/dashboards/previews/line.png';
import PiePreview from '../resources/png/dashboards/previews/pie.png';
import PivotPreview from '../resources/png/dashboards/previews/pivot.png';
import TablePreview from '../resources/png/dashboards/previews/table.png';
import Tooltip from '../components/molecules/Tooltip';
import TooltipIcon from '../resources/svg/tooltip.svg';
import { DEFAULT_SKAN_DATE_TYPE, trackDashboardsWidgetOpenedMixpanelEvent } from './utils';

export const WIDGET_TYPES = {};

CUSTOM_DASHBOARD_CHART_TYPES.forEach(x => {
    WIDGET_TYPES[x.name] = x.display_name;
});
Object.assign(WIDGET_TYPES, {
    pivot: 'Pivot Table',
    table: 'Table',
});

export const WIDGET_TYPE_OPTIONS = Object.keys(WIDGET_TYPES).map(k => ({ label: WIDGET_TYPES[k], value: k }));

export const SKAN_DATE_TYPES = {
    skan_postback_date: 'Postback Date',
    estimated_install_date: 'Estimated Install Date',
};
export const DATE_TYPE_OPTIONS = Object.keys(SKAN_DATE_TYPES).map(k => ({ label: SKAN_DATE_TYPES[k], value: k }));

export const CHART_TYPES_MULTI_METRICS = [
    { name: 'column', display_name: 'Bar Chart (Vertical)' },
    { name: 'bar', display_name: 'Bar Chart (Horizontal)' },
    { name: 'line', display_name: 'Line Chart' },
];

const MAX_METRICS_PER_WIDGET = 3;

const WIDGET_TYPE_TO_PREVIEW = {
    column: ColumnPreview,
    bar: BarPreview,
    stacked: StackedPreview,
    line: LinePreview,
    pie: PiePreview,
    pivot: PivotPreview,
    table: TablePreview,
};

const WIDGET_DIMENSIONS = {
    table: { name: 'Dimensions', mandatory: true },
    pie: { name: 'Dimensions - Breakdown', mandatory: true },
};

const WIDGET_ROWS = {
    pivot: { name: 'Rows', mandatory: true },
    column: { name: 'Dimensions (X axis)', mandatory: true },
    bar: { name: 'Dimensions (Y axis)', mandatory: true },
    stacked: { name: 'Dimensions (X axis)', mandatory: true },
    line: { name: 'Dimensions (X axis)', mandatory: true },
};

const WIDGET_COLUMNS = {
    pivot: { name: 'Columns', mandatory: false },
    column: { name: 'Dimensions - Breakdown', mandatory: false },
    bar: { name: 'Dimensions - Breakdown', mandatory: false },
    stacked: { name: 'Dimensions - Breakdown', mandatory: true },
    line: { name: 'Dimensions - Breakdown', mandatory: false },
};

const WIDGET_METRICS = {
    table: { name: 'Metrics', mandatory: true },
    pivot: { name: 'Metrics', mandatory: true },
    pie: { name: 'Metrics (Angle)', mandatory: true },
    column: { name: 'Metrics (Y axis)', mandatory: true },
    bar: { name: 'Metrics (X axis)', mandatory: true },
    stacked: { name: 'Metrics (Y axis)', mandatory: true },
    line: { name: 'Metrics (Y axis)', mandatory: true },
};

const DEFAULT_UI_FILTER = {
    field: {
        name: 'default',
        display_name: 'STATIC.PAGES.CUSTOM_DIMENSIONS.PLACEHOLDERS.FILTER_DIMENSION',
    },
    operator: {
        display_name: 'include',
        name: 'in',
        actual_type: 'filterOperators',
    },
    tags: [],
};

const SUBCOMPONENTS = {
    name: 'name',
    dimensions: 'dimensions',
    rows: 'rows',
    columns: 'columns',
    metrics: 'metrics',
    unifiedDataType: 'unifiedDataType',
    crossDeviceDataType: 'crossDeviceDataType',
};

const fieldsEmptyState = {
    query: {
        dimensions: [],
        metrics: [],
        filters: [],
        dataTypeFields: [],
    },
    options: {
        rows: [],
        columns: [],
    },
};

function getFieldsLabelClass(isMandatory) {
    return classnames({
        [css.fieldLabel]: true,
        [css.fieldLabelRequired]: isMandatory,
    });
}

function useRetrieveFieldObjectsByFieldNames(fieldNames, fieldObjects) {
    return useMemo(() => fieldNames.map(name => fieldObjects.find(x => x.value === name)).filter(x => x), [
        fieldNames,
        fieldObjects,
    ]);
}

function WidgetShelf({ adminMode, userData, widgets, widget: _widget, close, apiFields, onUpdateWidget, translate }) {
    const [shelfOpen, setShelfOpen] = useState(false);
    const [widget, setWidget] = useState(_widget);
    const originalWidget = useRef(widget);

    useEffect(() => {
        setShelfOpen(true);
    }, []);

    const [
        allDimensions,
        allMetrics,
        allFilters,
        unifiedDataTypes,
        crossDeviceDataTypes,
    ] = useParseDimsMetricsAndFilters(apiFields, adminMode, userData, widget.dataType);

    const availableDataTypes = useRef(
        Object.values(WIDGET_DATA_TYPES).filter(
            dataType => !dataType.feature_flag || userData.feature_flags.includes(dataType.feature_flag)
        )
    );

    // Translate the widget's dimensions, metrics (and options.rows / options.columns), which are simply list of
    // api field names, into a list of objects that look like: [{value: 'xxx', label: 'XXX'}, ...]
    const selectedDimensionsUI = useRetrieveFieldObjectsByFieldNames(widget.query.dimensions, allDimensions);
    const selectedRowsUI = useRetrieveFieldObjectsByFieldNames(widget.options.rows, allDimensions);
    const selectedColumnsUI = useRetrieveFieldObjectsByFieldNames(widget.options.columns, allDimensions);
    const selectedMetricsUI = useRetrieveFieldObjectsByFieldNames(widget.query.metrics, allMetrics);
    const selectedDataTypesUI = useRetrieveFieldObjectsByFieldNames(widget.query.dataTypeFields, unifiedDataTypes);
    const crossDeviceDataTypesUI = useRetrieveFieldObjectsByFieldNames(
        widget.query.dataTypeFields,
        crossDeviceDataTypes
    );
    // Translate widget.query.filters into a shape our UI likes
    const selectedFiltersUI = useMemo(() => {
        if (!widget.query.filters || !widget.query.filters.length) {
            return [DEFAULT_UI_FILTER];
        }

        return widget.query.filters
            .map(queryFilter => {
                if (queryFilter.field === 'default') {
                    return DEFAULT_UI_FILTER;
                }

                const filterObj = allFilters.find(x => x.name === queryFilter.field);
                if (!filterObj) {
                    return null;
                }

                return {
                    field: filterObj,
                    operator: OPERATOR_TYPES.find(x => x.name === queryFilter.operator),
                    tags: queryFilter.values.map(v => filterObj.values.find(x => x.name === v)).filter(v => v),
                };
            })
            .filter(x => x !== null);
    }, [widget.query.filters, allFilters]);

    // If we already used a filter (in widget.query.filters) then we don't want it to be an option again
    const allUnusedFilters = useMemo(() => {
        const usedFilters = widget.query.filters.map(x => x.field);
        return allFilters.filter(filter => !usedFilters.includes(filter.name));
    }, [selectedFiltersUI, allFilters]);

    function updateWidgetQueryFields(updatedQueryFields) {
        setWidget({
            ...widget,
            query: {
                ...widget.query,
                ...updatedQueryFields,
            },
        });
    }

    function setWidgetOptions(newOptions) {
        setWidget({
            ...widget,
            options: {
                ...widget.options,
                ...newOptions,
            },
        });
    }

    function setWidgetQueryFieldsAndOptions(updatedQueryFields, newOptions) {
        setWidget({
            ...widget,
            query: {
                ...widget.query,
                ...updatedQueryFields,
            },
            options: {
                ...widget.options,
                ...newOptions,
            },
        });
    }

    // This function is getting the widget's selected dimensions, metrics and data type
    // and a restriction type (blacklist/whitelist). It returns null if all selected metrics/dimensions are valid, or
    // returns json of metric and dimension that must have come together (whitelist),
    // or shouldn't come together (blacklist)
    function getFieldsRestrictions(dimensions, metrics, dataType, restrictionType) {
        const metricsGroups = allMetrics.filter(metric => metrics.includes(metric.name)).map(metric => metric.group);
        const { restrictions } = WIDGET_DATA_TYPES[dataType];

        if (!restrictions || !restrictions[restrictionType] || !dimensions.length || !metrics.length) {
            return null;
        }

        for (let i = 0; i < restrictions[restrictionType].length; i++) {
            const restriction = restrictions[restrictionType][i];

            for (let j = 0; j < restriction.metrics.length; j++) {
                const restrictionMetric = restriction.metrics[j];

                if (!metrics.includes(restrictionMetric) && !metricsGroups.includes(restrictionMetric)) {
                    continue;
                }

                for (let k = 0; k < restriction.dimensions.length; k++) {
                    const restrictionDimension = restriction.dimensions[k];
                    const isRestrictedDimensionSelected = dimensions.includes(restrictionDimension);

                    if (
                        (restrictionType === RESTRICTION_TYPE.BLACKLIST && isRestrictedDimensionSelected) ||
                        (restrictionType === RESTRICTION_TYPE.WHITELIST && !isRestrictedDimensionSelected)
                    ) {
                        return {
                            metric: restrictions.names[restrictionMetric],
                            dimension: restrictions.names[restrictionDimension],
                        };
                    }
                }
            }
        }

        return null;
    }

    const isMultiMetricsChartWidget = !!CHART_TYPES_MULTI_METRICS.find(chart => chart.name === widget.type);
    const isChartWidget = CUSTOM_DASHBOARD_CHART_TYPES.find(x => x.name === widget.type);

    const [error, setError] = useState('Gotta set some values...');
    const [errorComponent, setErrorComponent] = useState(null);
    const didUpdateSubComponent = useRef({});

    const [breakdownsDisabled, setBreakdownsDisabled] = useState(
        isMultiMetricsChartWidget && widget.query.metrics.length > 1
    );
    const [breakdownColumnsError, setBreakdownColumnsError] = useState(
        breakdownsDisabled ? 'STATIC.PAGES.DASHBOARD.SHELF.ERRORS.BREAKDOWN_DISABLED' : null
    );
    const [metricsError, setMetricsError] = useState(null);

    function resetErrorToDefault() {
        setError('Gotta set some values...');
        setErrorComponent(null);
    }

    useEffect(() => {
        const { name, type, options, query } = widget;

        if (!name.length) {
            if (!didUpdateSubComponent.current[SUBCOMPONENTS.name]) {
                resetErrorToDefault();
                return;
            }

            setError('Please enter a widget name');
            setErrorComponent(SUBCOMPONENTS.name);
            return;
        } else {
            didUpdateSubComponent.current[SUBCOMPONENTS.name] = true;
        }

        if (widgets.some(w => w.gridParams.i !== widget.gridParams.i && w.name === name)) {
            setError('A widget with that name already exists');
            setErrorComponent(SUBCOMPONENTS.name);
            return;
        }

        if (WIDGET_DIMENSIONS[type] && WIDGET_DIMENSIONS[type].mandatory && !query.dimensions.length) {
            if (!didUpdateSubComponent.current[SUBCOMPONENTS.dimensions]) {
                resetErrorToDefault();
                return;
            }

            setError('You must select at least one dimension');
            setErrorComponent(SUBCOMPONENTS.dimensions);
            return;
        } else {
            didUpdateSubComponent.current[SUBCOMPONENTS.dimensions] = true;
        }
        if (widget.dataType === DataTypes.CROSS_DEVICE && !query.dataTypeFields.length) {
            if (!didUpdateSubComponent.current[SUBCOMPONENTS.crossDeviceDataType]) {
                resetErrorToDefault();
                return;
            }
            setError('You must select an attribution type');
            setErrorComponent(SUBCOMPONENTS.crossDeviceDataType);
            return;
        } else {
            didUpdateSubComponent.current[SUBCOMPONENTS.crossDeviceDataType] = true;
        }
        if (widget.dataType === DataTypes.UNIFIED && !query.dataTypeFields.length) {
            if (!didUpdateSubComponent.current[SUBCOMPONENTS.unifiedDataType]) {
                resetErrorToDefault();
                return;
            }

            setError('You must select at least one data type');
            setErrorComponent(SUBCOMPONENTS.unifiedDataType);
            return;
        } else {
            didUpdateSubComponent.current[SUBCOMPONENTS.unifiedDataType] = true;
        }

        if (WIDGET_COLUMNS[type] && WIDGET_COLUMNS[type].mandatory && !options.columns.length) {
            if (!didUpdateSubComponent.current[SUBCOMPONENTS.columns]) {
                resetErrorToDefault();
                return;
            }

            setError('You must select at least one column');
            setErrorComponent(SUBCOMPONENTS.columns);
            return;
        } else {
            didUpdateSubComponent.current[SUBCOMPONENTS.columns] = true;
        }

        if (WIDGET_ROWS[type] && WIDGET_ROWS[type].mandatory && !options.rows.length) {
            if (!didUpdateSubComponent.current[SUBCOMPONENTS.rows]) {
                resetErrorToDefault();
                return;
            }

            setError('You must select at least one row');
            setErrorComponent(SUBCOMPONENTS.rows);
            return;
        } else {
            didUpdateSubComponent.current[SUBCOMPONENTS.rows] = true;
        }

        if (WIDGET_METRICS[type] && WIDGET_METRICS[type].mandatory && !query.metrics.length) {
            if (!didUpdateSubComponent.current[SUBCOMPONENTS.metrics]) {
                resetErrorToDefault();
                return;
            }

            setError('You must select at least one metric');
            setErrorComponent(SUBCOMPONENTS.metrics);
            return;
        } else {
            const dimensions = query.dimensions.concat(options.rows.concat(options.columns));

            const whitelistRestriction = getFieldsRestrictions(
                dimensions,
                query.metrics,
                widget.dataType,
                RESTRICTION_TYPE.WHITELIST
            );

            if (whitelistRestriction) {
                setError(
                    `${whitelistRestriction.metric} is available only if the dimension ${whitelistRestriction.dimension} is also selected`
                );
                setErrorComponent(SUBCOMPONENTS.metrics);
                return;
            }

            const blacklistRestriction = getFieldsRestrictions(
                dimensions,
                query.metrics,
                widget.dataType,
                RESTRICTION_TYPE.BLACKLIST
            );

            if (blacklistRestriction) {
                setError(
                    `${blacklistRestriction.metric} can not be selected with the dimension ${blacklistRestriction.dimension}`
                );
                setErrorComponent(SUBCOMPONENTS.metrics);
                return;
            }

            didUpdateSubComponent.current[SUBCOMPONENTS.metrics] = true;
        }

        setError('');
    }, [widget.name, widget.type, widget.options, widget.query]);

    useEffect(() => {
        const { dataType } = widget;
        const isSkanWidget = dataType === DataTypes.SKAN || dataType === DataTypes.SKAN_RAW;
        updateWidgetQueryFields({ skanDateDimensionName: isSkanWidget ? DEFAULT_SKAN_DATE_TYPE : '' });
    }, [widget.dataType]);

    function getNonEmptyQueryFilters(queryFilters) {
        return queryFilters.filter(queryFilter => queryFilter.values.length);
    }

    const onSave = useCallback(() => {
        const { type, options, query: widgetQuery } = widget;

        const filters = getNonEmptyQueryFilters(widgetQuery.filters);
        const query = {
            ...widgetQuery,
            filters,
        };

        if (WIDGET_DIMENSIONS[type]) {
            onUpdateWidget({
                ...widget,
                query,
                options: {
                    rows: [],
                    columns: [],
                },
            });
        }

        if (WIDGET_ROWS[type]) {
            const rowsAndCols = [...options.rows, ...options.columns];
            onUpdateWidget({
                ...widget,
                query: {
                    ...query,
                    dimensions: rowsAndCols,
                },
            });
        }

        setError('');
        close();
    }, [widget]);

    const onFieldSelection = (field, index) => {
        const newFilters = [...widget.query.filters];
        newFilters[index] = {
            field: field.name,
            operator: OPERATOR_TYPES[0].name,
            values: [],
        };
        updateWidgetQueryFields({ filters: newFilters });
    };

    const onOperatorSelection = (operator, index) => {
        const newFilters = [...widget.query.filters];
        newFilters[index] = {
            ...newFilters[index],
            operator: operator.name,
        };
        updateWidgetQueryFields({ filters: newFilters });
    };

    const onFilterValueAdded = (tag, index) => {
        // tag is using display name, and we want to store names
        const filterValueObj = selectedFiltersUI[index].field.values.find(x => x.display_name === tag);

        if (filterValueObj && !widget.query.filters[index].values.includes(filterValueObj.name)) {
            const newFilters = [...widget.query.filters];
            newFilters[index] = {
                ...newFilters[index],
                values: [...newFilters[index].values, filterValueObj.name],
            };
            updateWidgetQueryFields({ filters: newFilters });
        }
    };

    const onFilterValueRemoved = (tag, index) => {
        // tag is using display name, and we want to store names
        const filterValueObj = selectedFiltersUI[index].field.values.find(x => x.display_name === tag);

        if (filterValueObj && widget.query.filters[index].values.includes(filterValueObj.name)) {
            const newFilters = [...widget.query.filters];
            newFilters[index] = {
                ...newFilters[index],
                values: newFilters[index].values.filter(x => x !== filterValueObj.name),
            };
            updateWidgetQueryFields({ filters: newFilters });
        }
    };

    function disableBreakdowns() {
        setBreakdownsDisabled(true);
        setBreakdownColumnsError('STATIC.PAGES.DASHBOARD.SHELF.ERRORS.BREAKDOWN_DISABLED');
    }

    function enableBreakdowns() {
        setBreakdownsDisabled(false);
        setBreakdownColumnsError(null);
    }

    const onMetricsChange = updatedMetrics => {
        if (!updatedMetrics) {
            updateWidgetQueryFields({ metrics: [] });
            return;
        }
        if (isChartWidget && !isMultiMetricsChartWidget) {
            updateWidgetQueryFields({ metrics: [updatedMetrics.value] });
            return;
        }
        if (isMultiMetricsChartWidget) {
            if (updatedMetrics.length > MAX_METRICS_PER_WIDGET) {
                setMetricsError('STATIC.PAGES.DASHBOARD.SHELF.ERRORS.SELECT_METRICS_MAX');
            } else if (updatedMetrics.length > 1) {
                // set metrics and remove breakdowns
                setWidgetQueryFieldsAndOptions({ metrics: updatedMetrics.map(x => x.value) }, { columns: [] });
                setMetricsError(null);
                disableBreakdowns();
            } else if (updatedMetrics.length <= 1) {
                updateWidgetQueryFields({ metrics: updatedMetrics.map(x => x.value) });
                enableBreakdowns();
            }
        } else {
            // if it's a table/pivot table widget
            updateWidgetQueryFields({ metrics: updatedMetrics.map(x => x.value) });
        }
    };

    const onAddFilter = () => {
        updateWidgetQueryFields({
            filters: [
                ...widget.query.filters,
                {
                    field: 'default',
                    operator: 'in',
                    values: [],
                },
            ],
        });
    };

    const onRemoveFilter = index => {
        const newFilters = [...widget.query.filters];
        newFilters.splice(index, 1);
        updateWidgetQueryFields({ filters: newFilters });
    };

    const isPivotWidget = widget.type === 'pivot';
    const dragMessage = `Drag tags to set ${isChartWidget ? 'label' : 'column'} order`;

    const dragTooltip = (
        <Tooltip interactive title={dragMessage}>
            <TooltipIcon className={css.tooltip} />
        </Tooltip>
    );

    const widgetColumns = WIDGET_COLUMNS[widget.type] && (
        <Fragment>
            {!WIDGET_COLUMNS[widget.type].mandatory && !isPivotWidget && <div className={css.sectorBreak} />}
            <div className={css.fieldContainer}>
                <Label
                    className={getFieldsLabelClass(WIDGET_COLUMNS[widget.type].mandatory)}
                    text={WIDGET_COLUMNS[widget.type].name}
                />
                {dragTooltip}
                <NewTagInput
                    placeholder="STATIC.PLACEHOLDERS.SELECT_DIMENSION"
                    containerStyle={{ width: '100%' }}
                    tags={selectedColumnsUI}
                    options={allDimensions.filter(x => !widget.options.rows.includes(x.value))}
                    sortable
                    disabled={breakdownsDisabled}
                    onChange={tags => {
                        setWidgetOptions({
                            columns: tags.map(tag => tag.value),
                        });
                    }}
                    error={breakdownColumnsError || (errorComponent === SUBCOMPONENTS.columns && error ? error : '')}
                />
            </div>
        </Fragment>
    );

    return (
        <Shelf
            open={shelfOpen}
            shelfSize="medium"
            headerText="STATIC.PAGES.DASHBOARD.SHELF.HEADER_ADD"
            onClose={useCallback(() => {
                const formDirty = widget !== originalWidget.current;
                if (formDirty && !confirm(translate('STATIC.PAGES.CUSTOM_EVENTS.UNSAVED_CHANGES_WARNING'))) {
                    return;
                }
                trackDashboardsWidgetOpenedMixpanelEvent({
                    dashboard: null,
                    widget,
                    changeType: null,
                    isSaved: false,
                });
                setError('');
                close();
            })}
            footerNavigationButtons={[
                {
                    type: 'cancelButton',
                    text: 'Cancel',
                },
                {
                    type: 'primaryButton',
                    text: 'Save',
                    onClick: onSave,
                    disabled: !!error,
                },
            ]}
            enterAnimationDisabled={false}
        >
            {!apiFields && (
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Spinner show />
                </div>
            )}
            {apiFields && (
                <div className={css.shelfContainer}>
                    <div className={css.topSectorContainer}>
                        <div className={css.topSectorDataContainer}>
                            <div className={css.fieldContainer}>
                                <Label className={`${css.fieldLabel} ${css.fieldLabelRequired}`} text="Widget Name" />
                                <TextField
                                    inputConfig={{ autoFocus: true }}
                                    placeholder="Enter widget name"
                                    onChange={name => {
                                        setWidget({ ...widget, name });
                                    }}
                                    value={widget.name}
                                    error={errorComponent === SUBCOMPONENTS.name && error ? error : ''}
                                />
                            </div>
                            <div className={css.fieldContainer}>
                                <Label
                                    className={`${css.fieldLabel} ${css.fieldLabelRequired}`}
                                    text="Reporting Type"
                                />
                                <Dropdown
                                    items={availableDataTypes.current}
                                    selected={WIDGET_DATA_TYPES[widget.dataType]}
                                    onSelection={dataType => {
                                        const newWidgetQuery = {
                                            ...(widget.query || {}),
                                            ...fieldsEmptyState.query,
                                        };
                                        const newWidgetOptions = {
                                            ...(widget.options || {}),
                                            ...fieldsEmptyState.options,
                                        };

                                        setWidget({
                                            ...widget,
                                            dataType: dataType.name,
                                            query: newWidgetQuery,
                                            options: newWidgetOptions,
                                        });
                                    }}
                                    wrapperStyle={{ width: '100%' }}
                                    containerStyle={{ width: '100%' }}
                                />
                            </div>
                            <div className={css.fieldContainer}>
                                <Label className={`${css.fieldLabel} ${css.fieldLabelRequired}`} text="Widget Type" />
                                <AutoComplete
                                    isCreatable={false}
                                    controlledValue={{ value: widget.type, label: WIDGET_TYPES[widget.type] }}
                                    onChange={option => {
                                        const newWidgetQuery = {
                                            ...(widget.query || {}),
                                            ...fieldsEmptyState.query,
                                        };
                                        const newWidgetOptions = {
                                            ...(widget.options || {}),
                                            ...fieldsEmptyState.options,
                                        };

                                        setWidget({
                                            ...widget,
                                            type: option.value,
                                            query: newWidgetQuery,
                                            options: newWidgetOptions,
                                        });
                                    }}
                                    selectOptions={{
                                        isSearchable: false,
                                        options: WIDGET_TYPE_OPTIONS,
                                    }}
                                />
                            </div>
                        </div>
                        <div className={css.topSectorPreviewContainer}>
                            <Label className={`${css.fieldLabel} ${css.previewLabel}`} text="Preview:" />
                            <img src={WIDGET_TYPE_TO_PREVIEW[widget.type]} />
                        </div>
                    </div>
                    <div className={css.sectorBreak} />
                    {(widget.dataType === DataTypes.SKAN || widget.dataType === DataTypes.SKAN_RAW) && (
                        <div className={css.fieldContainer}>
                            <Label className={getFieldsLabelClass(true)} text="Skan Date Type" />
                            <AutoComplete
                                isCreatable={false}
                                controlledValue={{
                                    value: widget.query.skanDateDimensionName,
                                    label: SKAN_DATE_TYPES[widget.query.skanDateDimensionName],
                                }}
                                onChange={option => {
                                    updateWidgetQueryFields({
                                        skanDateDimensionName: option.value,
                                    });
                                }}
                                selectOptions={{
                                    isSearchable: false,
                                    options: DATE_TYPE_OPTIONS,
                                }}
                            />
                        </div>
                    )}
                    {WIDGET_DIMENSIONS[widget.type] && (
                        <div className={css.fieldContainer}>
                            <Label
                                className={getFieldsLabelClass(WIDGET_DIMENSIONS[widget.type].mandatory)}
                                text={WIDGET_DIMENSIONS[widget.type].name}
                            />
                            {dragTooltip}
                            <NewTagInput
                                placeholder="STATIC.PLACEHOLDERS.SELECT_DIMENSION"
                                containerStyle={{ width: '100%' }}
                                tags={selectedDimensionsUI}
                                options={allDimensions}
                                sortable
                                onChange={tags => {
                                    updateWidgetQueryFields({ dimensions: tags.map(x => x.value) });
                                }}
                                error={errorComponent === SUBCOMPONENTS.dimensions && error ? error : ''}
                            />
                        </div>
                    )}
                    {widget.dataType === DataTypes.CROSS_DEVICE && (
                        <div className={css.fieldContainer}>
                            <Label className={getFieldsLabelClass(true)} text="Attribution Type" />
                            <NewTagInput
                                placeholder="Select Attribution Type"
                                containerStyle={{ width: '100%' }}
                                tags={crossDeviceDataTypesUI}
                                options={crossDeviceDataTypes}
                                onChange={tags => {
                                    updateWidgetQueryFields({ dataTypeFields: tags.map(x => x.value) });
                                }}
                                error={errorComponent === SUBCOMPONENTS.crossDeviceDataType && error ? error : ''}
                            />
                        </div>
                    )}
                    {widget.dataType === DataTypes.UNIFIED && (
                        <div className={css.fieldContainer}>
                            <Label className={getFieldsLabelClass(true)} text="Data Type" />
                            <NewTagInput
                                placeholder="Select Data Type"
                                containerStyle={{ width: '100%' }}
                                tags={selectedDataTypesUI}
                                options={unifiedDataTypes}
                                onChange={tags => {
                                    updateWidgetQueryFields({ dataTypeFields: tags.map(x => x.value) });
                                }}
                                error={errorComponent === SUBCOMPONENTS.unifiedDataType && error ? error : ''}
                            />
                        </div>
                    )}
                    {WIDGET_ROWS[widget.type] && (
                        <div className={css.fieldContainer}>
                            <Label
                                className={getFieldsLabelClass(WIDGET_ROWS[widget.type].mandatory)}
                                text={WIDGET_ROWS[widget.type].name}
                            />
                            {dragTooltip}
                            <NewTagInput
                                placeholder="STATIC.PLACEHOLDERS.SELECT_DIMENSION"
                                containerStyle={{ width: '100%' }}
                                tags={selectedRowsUI}
                                options={allDimensions.filter(x => !widget.options.columns.includes(x.value))}
                                sortable
                                onChange={tags => {
                                    setWidgetOptions({
                                        rows: tags.map(tag => tag.value),
                                    });
                                }}
                                error={errorComponent === SUBCOMPONENTS.rows && error ? error : ''}
                            />
                        </div>
                    )}
                    {WIDGET_COLUMNS[widget.type] &&
                        (WIDGET_COLUMNS[widget.type].mandatory || isPivotWidget) &&
                        widgetColumns}
                    <div className={css.fieldContainer}>
                        <Label
                            className={getFieldsLabelClass(WIDGET_METRICS[widget.type].mandatory)}
                            text={WIDGET_METRICS[widget.type].name}
                        />
                        {!isChartWidget && dragTooltip}
                        <NewTagInput
                            placeholder={
                                isMultiMetricsChartWidget
                                    ? 'STATIC.PAGES.DASHBOARD.SHELF.PLACEHOLDERS.SELECT_METRICS'
                                    : 'STATIC.PLACEHOLDERS.SELECT_METRIC'
                            }
                            containerStyle={{ width: '100%' }}
                            tags={selectedMetricsUI}
                            options={allMetrics}
                            sortable
                            isMulti={isMultiMetricsChartWidget || !isChartWidget}
                            isClearable
                            onChange={onMetricsChange}
                            error={metricsError || (errorComponent === SUBCOMPONENTS.metrics && error ? error : '')}
                        />
                    </div>
                    {WIDGET_COLUMNS[widget.type] &&
                        !(WIDGET_COLUMNS[widget.type].mandatory || isPivotWidget) &&
                        widgetColumns}
                    {(!WIDGET_COLUMNS[widget.type] || WIDGET_COLUMNS[widget.type].mandatory || isPivotWidget) && (
                        <div className={css.sectorBreak} />
                    )}
                    <div className={css.fieldContainer}>
                        <Label className={css.fieldLabel} text="Filters" />
                        <FilterFields
                            fields={allUnusedFilters}
                            filters={selectedFiltersUI}
                            operators={OPERATOR_TYPES}
                            onFieldSelection={onFieldSelection}
                            onOperatorSelection={onOperatorSelection}
                            onFilterValueAdded={onFilterValueAdded}
                            onFilterValueRemoved={onFilterValueRemoved}
                            onAddFilter={onAddFilter}
                            onRemoveFilter={onRemoveFilter}
                        />
                    </div>
                </div>
            )}
        </Shelf>
    );
}

const WIDGET_SHAPE = PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['table', 'pivot', ...CUSTOM_DASHBOARD_CHART_TYPES.map(x => x.name)]).isRequired,
    dataType: PropTypes.oneOf(Object.values(WIDGET_DATA_TYPES).map(dataType => dataType.name)).isRequired,
    query: PropTypes.shape({
        dimensions: PropTypes.arrayOf(PropTypes.string).isRequired,
        metrics: PropTypes.arrayOf(PropTypes.string).isRequired,
        filters: PropTypes.arrayOf(
            PropTypes.shape({
                field: PropTypes.string.isRequired,
                operator: PropTypes.oneOf(OPERATOR_TYPES.map(x => x.name)).isRequired,
                values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
            })
        ).isRequired,
    }).isRequired,
    options: PropTypes.shape({
        rows: PropTypes.arrayOf(PropTypes.string),
        columns: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
});

WidgetShelf.propTypes = {
    adminMode: PropTypes.bool.isRequired,
    userData: PropTypes.objectOf(PropTypes.any).isRequired,
    widgets: PropTypes.arrayOf(WIDGET_SHAPE).isRequired,
    widget: WIDGET_SHAPE.isRequired,
    onUpdateWidget: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    apiFields: PropTypes.objectOf(PropTypes.any),
    translate: PropTypes.func,
};

WidgetShelf.defaultProps = {
    apiFields: null,
    translate: () => {},
};

export default connect((state, ownProps) => ({
    adminMode: getAdminModeEnabled(state),
    userData: getUserData(state),
    ...ownProps,
}))(withLocalize(WidgetShelf));
