import React, { Fragment, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import LockIcon from '../resources/svg/lock.svg';
import CollapseIcon from '../resources/svg/collapse_arrow.svg';
import ExpandIcon from '../resources/svg/expand_arrow.svg';
import { PageWrapper } from '../components/partials';
import {
    getDefaultActiveItem,
    setLastVisitedMenuItem,
    getFirstItemIdInCategory,
    trackTaskClick,
    trackTaskButtonClick,
    parseHomepageHref,
    setLastVisitedPageParams,
} from './utils';
import css from './style.css';
import HomePageContent from './homePageContent';
import Spinner from '../components/widgets/Spinner';
import Tooltip from '../components/molecules/Tooltip';
import Label from '../components/foundations/Label';
import Button from '../components/atoms/Button';
import { setShowBackPanel } from '../topPanels/utils';

function Homepage(props) {
    const {
        menuItemsOrder,
        menuItemsConfig,
        isLoading,
        error,
        translate,
        saveCompletedTask,
        adminMode,
        apsalarEnabled,
    } = props;
    const { defaultActiveItemId, defaultActiveCategoryId } = getDefaultActiveItem(
        menuItemsOrder,
        menuItemsConfig,
        apsalarEnabled
    );
    const [activeItemId, setActiveItemId] = React.useState(defaultActiveItemId);
    const [activeCategoryId, setActiveCategoryId] = React.useState(defaultActiveCategoryId);
    const {
        mediaSrc,
        mediaSrcType,
        nextItemId,
        buttonOnClick: propsButtonOnClick,
        warningMsg,
        completed: activeItemCompleted,
        locked: activeItemLocked,
        shouldSetCompletedOnClick,
        ContentBodyComponent: activeContentBodyComponent,
    } = menuItemsConfig[activeItemId] || {};
    const buttonText = `STATIC.PAGES.HOMEPAGE.MENU_ITEMS.${activeItemId}_BUTTON`;
    const title = `STATIC.PAGES.HOMEPAGE.MENU_ITEMS.${activeItemId}_CONTENT_TITLE`;

    useEffect(() => {
        setActiveItemId(defaultActiveItemId);
    }, [defaultActiveItemId]);

    useEffect(() => {
        setActiveCategoryId(defaultActiveCategoryId);
    }, [defaultActiveCategoryId]);

    function handleItemClick(id) {
        trackTaskClick(activeItemId, nextItemId);

        setLastVisitedMenuItem(id, apsalarEnabled);
        setActiveItemId(id);
        setLastVisitedPageParams(apsalarEnabled);
    }

    const buttonOnClick = () => {
        trackTaskButtonClick(activeItemId, nextItemId);

        if (!activeItemCompleted && shouldSetCompletedOnClick) {
            saveCompletedTask(activeItemId);
        }

        if (nextItemId) {
            handleItemClick(nextItemId);
        } else if (propsButtonOnClick) {
            setShowBackPanel();
            propsButtonOnClick();
        }
    };

    function handleCategoryClick(id) {
        if (activeCategoryId !== id) {
            setActiveCategoryId(id);
            setActiveItemId(getFirstItemIdInCategory(menuItemsOrder, menuItemsConfig, id));
        }
    }

    return (
        <PageWrapper>
            <Spinner show={isLoading} />
            {!isLoading && !error && (
                <div className={css.homePageLayout}>
                    <div className={css.homePageMenu}>
                        <List component="nav" className={css.homePageMenuList}>
                            {menuItemsOrder.map((category, i) => {
                                const { categoryId, items } = category;
                                const activeCategory = activeCategoryId === categoryId;
                                const { completed: categoryCompleted } = menuItemsConfig[categoryId] || {};
                                const categoryLabel = translate(`STATIC.PAGES.HOMEPAGE.MENU_ITEMS.${categoryId}_LABEL`);

                                return (
                                    <Fragment key={`fragment_${categoryId}`}>
                                        <ListItem
                                            onClick={() => {
                                                handleCategoryClick(categoryId);
                                            }}
                                            className={classNames(css.categoryListItem, {
                                                [css.completed]: categoryCompleted,
                                            })}
                                            key={`category_${categoryId}`}
                                        >
                                            <div className={css.collapseExpand}>
                                                {activeCategory ? (
                                                    <CollapseIcon className={css.collapseIcon} />
                                                ) : (
                                                    <ExpandIcon className={css.expandIcon} />
                                                )}
                                            </div>
                                            <Label
                                                className={classNames(css.categoryListItemText)}
                                                text={`${i + 1}. ${categoryLabel}`}
                                            />
                                        </ListItem>
                                        <Collapse in={activeCategory} key={`collapse_${categoryId}`}>
                                            <List component="div" disablePadding>
                                                {items.map(itemId => {
                                                    const selected = activeItemId === itemId;
                                                    const { locked, lockTooltip, completed, badgeText } =
                                                        menuItemsConfig[itemId] || {};
                                                    const label = translate(
                                                        `STATIC.PAGES.HOMEPAGE.MENU_ITEMS.${itemId}_LABEL`
                                                    );

                                                    return (
                                                        <ListItem
                                                            key={`${categoryId}_${itemId}`}
                                                            button
                                                            className={classNames(
                                                                css.listItem,
                                                                { [css.selected]: selected },
                                                                { [css.completed]: completed }
                                                            )}
                                                            onClick={() => {
                                                                handleItemClick(itemId);
                                                            }}
                                                        >
                                                            {locked && lockTooltip && (
                                                                <Tooltip
                                                                    classes={{
                                                                        tooltip: css.tooltip,
                                                                        popper: css.opaqueBackground,
                                                                    }}
                                                                    wrapperClass={css.lockIconWrapper}
                                                                    title={lockTooltip}
                                                                >
                                                                    <LockIcon className={css.lockIcon} />
                                                                </Tooltip>
                                                            )}
                                                            {locked && !lockTooltip && (
                                                                <LockIcon
                                                                    className={classNames(
                                                                        css.lockIcon,
                                                                        css.lockIconWrapper
                                                                    )}
                                                                />
                                                            )}
                                                            <Label className={css.listItemText} text={label} />
                                                            {badgeText && (
                                                                <Label className={css.badgeText} text={badgeText}>
                                                                    {badgeText}
                                                                </Label>
                                                            )}
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                );
                            })}
                        </List>
                    </div>
                    <div
                        className={css.homePageContent}
                        onClick={e => {
                            const { href } = e.target;
                            if (href && href.includes('support.singular')) {
                                e.preventDefault();
                                window.open(parseHomepageHref(e.target.href, activeItemId), '_blank');
                            }
                        }}
                    >
                        <HomePageContent
                            mediaSrc={mediaSrc}
                            mediaSrcType={mediaSrcType}
                            warningMsg={warningMsg}
                            buttonText={buttonText}
                            title={title}
                            buttonOnClick={buttonOnClick}
                            ContentBodyComponent={activeContentBodyComponent}
                            locked={activeItemLocked}
                            itemId={activeItemId}
                            apsalarEnabled={apsalarEnabled}
                        />
                    </div>
                    {adminMode && (
                        <div>
                            <Button
                                style={{ margin: '20px' }}
                                type="primary"
                                level="level1"
                                text="Simulate - org has data"
                                onClick={() => {
                                    saveCompletedTask('ORG_HAS_DATA');
                                    document.location.reload();
                                }}
                            />
                            <Button
                                style={{ marginLeft: '20px' }}
                                type="primary"
                                level="level1"
                                text="Simulate - sdk integrated"
                                onClick={() => {
                                    saveCompletedTask('SDK_INTEGRATED');
                                    document.location.reload();
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </PageWrapper>
    );
}

Homepage.propTypes = {
    menuItemsOrder: PropTypes.arrayOf(PropTypes.object).isRequired,
    menuItemsConfig: PropTypes.objectOf(PropTypes.any).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    saveCompletedTask: PropTypes.func.isRequired,
    apsalarEnabled: PropTypes.bool,
    adminMode: PropTypes.bool,
    translate: PropTypes.func,
};

Homepage.defaultProps = {
    apsalarEnabled: false,
    adminMode: false,
    translate: () => {},
};

export default withLocalize(Homepage);
