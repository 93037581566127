import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate, withLocalize } from 'react-localize-redux';
import Dropdown from './Dropdown';
import QuestionMark from '../widgets/QuestionMark';
import css from './DropdownField.css';

function DropdownField(props) {
    const {
        valueField,
        label,
        items,
        selected,
        placeholder,
        onChange,
        onBlur,
        containerStyle,
        wrapperStyle,
        bodyClassName,
        disabled,
        error,
        fieldContainerStyle,
        labelTooltip,
        labelTooltipStyle,
        mandatory,
        containerClass,
        labelClassName,
        dropdownContainerClassName,
        dropdownSelectedClassName,
        errorClassName,
        translate,
    } = props;
    const id = `dropdownField-${label.replace(/ /g, '_')}`;
    const selectedOption = items.find(item => item[valueField] === selected);
    const defaultPlaceholder = { name: 'default', display_name: <Translate id={placeholder} /> };
    return (
        <div className={classNames(css.field, containerClass, { [css.error]: error })} style={fieldContainerStyle}>
            <label className={css.fieldLabel} htmlFor={id}>
                <div className={classNames(labelClassName, css.fieldText)}>
                    {mandatory ? <span className={css.mandatory}>*</span> : null}
                    <span>
                        <Translate id={label} />
                    </span>
                    {labelTooltip ? <QuestionMark message={labelTooltip} contentStyle={labelTooltipStyle} /> : null}
                </div>
                <Dropdown
                    id={id}
                    items={items}
                    valueField={valueField}
                    selected={selectedOption || defaultPlaceholder}
                    onSelection={opt => {
                        onChange(opt[valueField]);
                    }}
                    containerClass={dropdownContainerClassName || css.dropDownContainer}
                    wrapperStyle={{
                        display: 'block',
                        ...wrapperStyle,
                    }}
                    onBlur={onBlur}
                    containerStyle={containerStyle}
                    disabled={disabled}
                    error={error}
                    bodyClassName={bodyClassName}
                    selectedContainer={dropdownSelectedClassName}
                />
            </label>
            {error && <div className={classNames(errorClassName, css.errorMessage, css.error)}>{translate(error)}</div>}
        </div>
    );
}

DropdownField.propTypes = {
    valueField: PropTypes.string,
    label: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    selected: PropTypes.string || PropTypes.number,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    wrapperStyle: PropTypes.objectOf(PropTypes.any),
    bodyClassName: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fieldContainerStyle: PropTypes.objectOf(PropTypes.any),
    labelTooltip: PropTypes.string,
    labelTooltipStyle: PropTypes.objectOf(PropTypes.any),
    mandatory: PropTypes.bool,
    containerClass: PropTypes.string,
    dropdownContainerClassName: PropTypes.string,
    dropdownSelectedClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    errorClassName: PropTypes.string,
    translate: PropTypes.func.isRequired,
};

DropdownField.defaultProps = {
    valueField: 'name',
    label: '',
    selected: null,
    placeholder: 'Select value',
    onBlur: () => {},
    containerStyle: {},
    wrapperStyle: {},
    disabled: false,
    error: '',
    fieldContainerStyle: {},
    labelTooltip: null,
    labelTooltipStyle: {},
    mandatory: false,
    containerClass: '',
    bodyClassName: undefined,
    labelClassName: '',
    errorClassName: '',
    dropdownContainerClassName: '',
    dropdownSelectedClassName: '',
};

export default withLocalize(DropdownField);
