import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import Tooltip from '../../components/molecules/Tooltip';
import RevenueEventIcon from '../../resources/svg/revenue_event.svg';
import FirstEventIcon from '../../resources/svg/first_event.svg';

const IconTableCell = ({ log }) => {
    return (
        <TableCell align="left" width={40}>
            {log.isRevenueEvent && (
                <Tooltip titleTranslationKey="STATIC.PAGES.TESTING_CONSOLE.REVENUE_EVENT_TOOLTIP">
                    <RevenueEventIcon />
                </Tooltip>
            )}
            {log.isFirstSession && (
                <Tooltip titleTranslationKey="STATIC.PAGES.TESTING_CONSOLE.FIRST_EVENT_TOOLTIP">
                    <FirstEventIcon />
                </Tooltip>
            )}
        </TableCell>
    );
};

IconTableCell.propTypes = {
    log: PropTypes.objectOf(PropTypes.any),
};

IconTableCell.defaultProps = {
    log: {},
};

export default IconTableCell;
