import React from 'react';
import PropTypes from 'prop-types';
import {withLocalize} from 'react-localize-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faTrashAlt from '@fortawesome/fontawesome-free-regular/faTrashAlt';
import faLock from '@fortawesome/fontawesome-free-solid/faLock';
import moment from 'moment-timezone';
import Tooltip from '../molecules/Tooltip';
import classNames from 'classnames';
import css from './EventsItemHeader.css';
import EditableTitle from './EditableTitle';
import {EVENT_SLOT_GROUP_TYPE} from '../../utils/consts';
import PermissionsWrapper from '../../containers/PermissionsWrapper';

class EventsItemHeader extends React.Component {
    constructor(props) {
        super(props);
        this.onDeleteClicked = this._onDeleteClicked.bind(this);
        this.renderExpandedHeaderMetadata = this._renderExpandedHeaderMetadata.bind(this);
        this.onEventNameChanged = this._onEventNameChanged.bind(this);
        this.state = {
            isEditNameSelected: false,
        };
    }

    _onDeleteClicked(e) {
        e.stopPropagation();
        const {onDeleteClicked, locked} = this.props;
        if (locked) {
            return;
        }
        onDeleteClicked();
    }

    _renderExpandedHeaderMetadata() {
        const {
            lastModified,
            eventName,
            calculatedMetricsOptions,
            locked,
            unique,
            eventSlotGroupType,
            standard,
            translate,
        } = this.props;
        const enabledMetrics = calculatedMetricsOptions.filter(option => option.value);
        const eventDate = moment(lastModified).format('YYYY-MM-DD');
        return (
            <>
                {(locked || standard) && (
                    <div className={classNames(css.eventAutoGenerated, css.rightSideItem)}>
                        <span className={css.eventAutoGeneratedText}>
                            {translate('STATIC.PAGES.CUSTOM_EVENTS.EVENT_ITEM_HEADER.GENERATED_AUTOMATICALLY')}
                        </span>
                    </div>
                )}
                <div className={classNames(css.eventDate, css.rightSideItem)} key={`${eventName}_modified_text`}>
                    {locked || standard
                        ? translate('STATIC.PAGES.CUSTOM_EVENTS.EVENT_ITEM_HEADER.ADDED', { date: eventDate })
                        : translate('STATIC.PAGES.CUSTOM_EVENTS.EVENT_ITEM_HEADER.LAST_MODIFIED', {
                              date: eventDate,
                          })}
                </div>
                {eventSlotGroupType !== EVENT_SLOT_GROUP_TYPE.SKAN && (
                    <div
                        className={classNames(css.eventField, css.rightSideItem)}
                        key={`${eventName}_header_quick_options`}
                    >
                        {enabledMetrics.length > 0 ? (
                            enabledMetrics.map((option, index) => {
                                return (
                                    <div key={`event_item_header_option_${option.name}`}>
                                        <span>{option.name}</span>
                                        <span className={css.spacer}>
                                            {index !== enabledMetrics.length - 1 ? '/' : null}
                                        </span>
                                    </div>
                                );
                            })
                        ) : (
                            <div key="event_item_header_option_empty">
                                <span>
                                    {translate('STATIC.PAGES.CUSTOM_EVENTS.EVENT_ITEM_HEADER.NO_METRICS_SELECTED')}
                                </span>
                            </div>
                        )}
                    </div>
                )}
                {![EVENT_SLOT_GROUP_TYPE.CONVERSION, EVENT_SLOT_GROUP_TYPE.SKAN].includes(eventSlotGroupType) && (
                    <Tooltip
                        titleTranslationKey={
                            unique
                                ? 'STATIC.PAGES.CUSTOM_EVENTS.UNIQUE_EVENTS_TOOLTIP'
                                : 'STATIC.PAGES.CUSTOM_EVENTS.TOTAL_EVENTS_TOOLTIP'
                        }
                    >
                        <div
                            className={classNames(css.eventField, css.rightSideItem)}
                            key={`${eventName}_header_unique`}
                        >
                            {unique
                                ? translate('STATIC.PAGES.CUSTOM_EVENTS.EVENT_ITEM_HEADER.UNIQUE')
                                : translate('STATIC.PAGES.CUSTOM_EVENTS.EVENT_ITEM_HEADER.TOTAL_EVENTS')}
                        </div>
                    </Tooltip>
                )}
            </>
        );
    }

    _onEventNameChanged(...args) {
        const {onEventNameChanged, readOnly} = this.props;
        if (readOnly) {
            return;
        }
        onEventNameChanged(...args);
    }

    render() {
        const {
            eventName,
            expanded,
            locked,
            slot,
            showSlotNumber,
            disabled,
            translate,
            eventSlotGroupType,
        } = this.props;
        return (
            <div className={classNames(css.container, {[css.expanded]: expanded, [css.locked]: locked})}>
                <div className={css.eventName}>
                    {expanded && !disabled ? (
                        <EditableTitle
                            onTextChanged={this.onEventNameChanged}
                            value={eventName}
                            placeholder={translate('STATIC.PAGES.CUSTOM_EVENTS.PLACEHOLDERS.EVENT_NAME')}
                        />
                    ) : (
                        <span>
                            {eventName}
                            {showSlotNumber && <span className={css.slotIndication}>{`(slot: ${slot})`}</span>}
                        </span>
                    )}
                </div>
                <div className={css.rightSide}>
                    {expanded ? null : this.renderExpandedHeaderMetadata()}
                    <PermissionsWrapper write>
                        {eventName && eventSlotGroupType !== EVENT_SLOT_GROUP_TYPE.SKAN ? (
                            <div
                                className={classNames(css.trash, css.rightSideItem)}
                                role="button"
                                onClick={this.onDeleteClicked}
                            >
                                {!disabled ? (
                                    locked ? (
                                        <FontAwesomeIcon icon={faLock}/>
                                    ) : (
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                    )
                                ) : null}
                            </div>
                        ) : null}
                    </PermissionsWrapper>
                </div>
            </div>
        );
    }
}

EventsItemHeader.propTypes = {
    lastModified: PropTypes.string,
    eventName: PropTypes.string,
    expanded: PropTypes.bool,
    locked: PropTypes.bool,
    calculatedMetricsOptions: PropTypes.arrayOf(PropTypes.any),
    onEventNameChanged: PropTypes.func,
    onDeleteClicked: PropTypes.func,
    slot: PropTypes.number,
    showSlotNumber: PropTypes.bool,
    unique: PropTypes.bool,
    eventSlotGroupType: PropTypes.string,
    readOnly: PropTypes.bool,
    standard: PropTypes.bool,
};

EventsItemHeader.defaultProps = {
    lastModified: '',
    calculatedMetricsOptions: [],
    eventName: '',
    expanded: false,
    locked: false,
    onEventNameChanged: () => {
    },
    onDeleteClicked: () => {
    },
    slot: -1,
    showSlotNumber: false,
    unique: false,
    eventSlotGroupType: undefined,
    readOnly: false,
    standard: false,
};

export default withLocalize(EventsItemHeader);
