import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from '../../components/molecules/Popover';
import popoverStyle from './MoreActionsPopover.css';
import { useOutsideEventHandler } from '../../utils/customHooks';

const MoreActionsPopover = ({ actionComponentsConfigs, showExtendedMenu, onCloseExtendedMenu, anchorEl, row }) => {
    const wrapperRef = useRef(null);

    const outsideEvents = [{ name: 'mousedown' }, { name: 'scroll', useCapture: true }];

    useOutsideEventHandler(wrapperRef, onCloseExtendedMenu, outsideEvents);

    const actionsContent = actionComponentsConfigs.map(
        ({ ActionComponent, onClick, onMouseEnter, onMouseLeave, key, title, shouldShow = () => true }) => {
            return shouldShow(row) ? (
                <div
                    className={popoverStyle.line}
                    onClick={() => {
                        onCloseExtendedMenu();
                        onClick(row);
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    key={key}
                >
                    <ActionComponent className={popoverStyle.iconContainer} />
                    <span>{title}</span>
                </div>
            ) : null;
        }
    );

    return anchorEl ? (
        <Fragment>
            <Popover
                className={popoverStyle.container}
                showPopover={showExtendedMenu}
                anchorEl={anchorEl}
                wrapperRef={wrapperRef}
            >
                <div className={popoverStyle.table}> {actionsContent} </div>
            </Popover>
        </Fragment>
    ) : null;
};

MoreActionsPopover.propTypes = {
    actionComponentsConfigs: PropTypes.arrayOf(
        PropTypes.shape({
            ActionComponent: PropTypes.element,
            title: PropTypes.string,
            shouldShow: PropTypes.func,
        })
    ),
    anchorEl: PropTypes.element,
    showExtendedMenu: PropTypes.bool,
    onCloseExtendedMenu: PropTypes.func,
    header: PropTypes.string,
    row: PropTypes.shape({
        uanId: PropTypes.number,
    }),
};

MoreActionsPopover.defaultProps = {
    actionComponentsConfigs: [],
    anchorEl: {},
    showExtendedMenu: false,
    onCloseExtendedMenu: () => {},
    header: '',
    row: {},
};

export default MoreActionsPopover;
