import React from 'react';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import css from '../organisms/Table.css';
import ExtendedMenu from '../../resources/svg/extended_menu.svg';
import Tooltip from '../molecules/Tooltip';

class ContextMenu extends React.Component {
    constructor(props) {
        super(props);
        this.itemSelect = this.itemSelect.bind(this);
        this.menuToggle = this.menuToggle.bind(this);
        this.state = {
            show: false,
        };
    }

    getItem(item) {
        const disabled = typeof item.disabled === 'function' ? item.disabled(this.props) : item.disabled;

        const itemContent = (
            <>
                {typeof item.name === 'function' ? item.name(this.props) : <Translate id={item.name} />}
                {!item.isNew ? null : <sup className={css.itemTag}>New</sup>}
                {!item.isBeta ? null : <sup className={css.itemTag}>Beta</sup>}
            </>
        );

        return (
            <li
                id={item.name}
                key={item.name}
                onMouseDown={!disabled ? this.itemSelect.bind(this, item) : undefined}
                className={`${disabled ? css.disabled : ''}`}
            >
                {item.tooltip ? (
                    <Tooltip wrapperClass={css.menuItemTooltip} titleTranslationKey={item.tooltip}>
                        {itemContent}
                    </Tooltip>
                ) : (
                    itemContent
                )}
            </li>
        );
    }

    itemSelect(item) {
        const { onSelect } = this.props;
        this.setState({
            show: false,
        });
        onSelect(item, this.props);
    }

    menuToggle(show) {
        this.setState({
            show,
        });
    }

    render() {
        const { items, className, icon, menuDisabled } = this.props;
        const { show } = this.state;

        const menuContents = !(show && !menuDisabled) ? null : (
            <ul className={`${show ? css.show : ''}`}>{items.map(this.getItem.bind(this))}</ul>
        );

        return (
            <div className={classNames(css.dropdownCell, className)}>
                <div role="button" tabIndex="0" onBlur={this.menuToggle.bind(this, false)}>
                    <button onClick={this.menuToggle.bind(this, !show)} className={`${show ? css.active : ''}`}>
                        {icon || <ExtendedMenu style={{ width: 18, height: 18, position: 'relative', top: '4px' }} />}
                    </button>
                    {menuContents}
                </div>
            </div>
        );
    }
}

ContextMenu.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            disabled: PropTypes.bool,
            tooltip: PropTypes.string,
            isNew: PropTypes.bool,
            isBeta: PropTypes.bool,
        })
    ),
    className: PropTypes.string,
    icon: PropTypes.node,
    menuDisabled: PropTypes.bool,
    onSelect: PropTypes.func,
};

ContextMenu.defaultProps = {
    items: [],
    className: '',
    icon: null,
    menuDisabled: false,
    onSelect: () => {},
};

export default ContextMenu;
