import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';

import Dropdown from '../../components/molecules/Dropdown';
import css from '../../customDimensions/styles.css';
import governanceCss from '../governance.css';
import { PageHeader, PageWrapper } from '../../components/partials';
import TextField from '../../components/molecules/TextField';
import Button from '../../components/atoms/Button';
import SchemasTable, { addSchema, setReloadOnNextRender } from './schemasTable';

function SchemasPage() {
    const [filterText, setFilterText] = useState('');
    const [creationSchemaType, setCreationSchemaType] = useState();

    const schemaOptions = [
        {
            id: 1,
            name: 'Campaign',
            display_name: 'Campaign Schema',
        },
        {
            id: 2,
            name: 'Creative',
            display_name: 'Creative Schema',
        },
    ];
    return (
        <PageWrapper>
            <PageHeader
                text="STATIC.PAGES.GOVERNANCE.SCHEMAS.PAGE_HEADER"
                subText={{
                    faqLink:
                        'https://support.singular.net/hc/en-us/articles/360059060891-How-to-Create-Taxonomy-Schemas',
                }}
            />
            <br />
            <div className={css.headerRow} style={{ display: 'flex' }}>
                <div className={css.filterDimensionsWrapper}>
                    <TextField
                        onChange={x => {
                            setFilterText(x);
                        }}
                        value={filterText}
                        placeholder="Search by schema name"
                    />
                </div>
                <div className={governanceCss.newGovernanceActionArea}>
                    <Dropdown
                        items={schemaOptions}
                        selected={creationSchemaType}
                        onSelection={v => setCreationSchemaType(v)}
                        wrapperStyle={{ display: 'flex' }}
                    />
                    <Button
                        onClick={() => addSchema(creationSchemaType?.name)}
                        type="primary"
                        level="level1"
                        disabledDark={creationSchemaType === undefined}
                    >
                        <Translate id="STATIC.BUTTONS.NEW_GOVERNANCE_SCHEMA" />
                    </Button>
                </div>
            </div>

            <SchemasTable filter={filterText} />
        </PageWrapper>
    );
}

SchemasPage.locationHandler = location => {
    if (location.pathname !== '/react/governance/schemas') {
        setReloadOnNextRender();
    }
};

export default SchemasPage;
