import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from '../../components/molecules/Popover';
import popoverStyle from './EtlPopover.css';
import { getTextWidth } from '../../utils/general';
import { useOutsideEventHandler } from '../../utils/customHooks';
import Tooltip from '../../components/molecules/Tooltip';

import {
    SINGLE_ETL_STATUS_COLOR_MAP,
    SINGLE_ETL_STATUS_TEXT_MAP,
    ETL_POPOVER_DESTINATION_NAME_FONT,
    ETL_POPOVER_TEXT_NAME_FONT,
} from '../utils';
import { sortAlphabetically } from '../../utils/sortUtil';

const EtlPopover = props => {
    const { header, etlTableContent, showPopover, setShowPopover, anchorEl } = props;

    const wrapperRef = useRef(null);

    const outsideEvents = [{ name: 'mousedown' }, { name: 'scroll', useCapture: true }];

    const closePopover = () => {
        setShowPopover(false);
    };

    useOutsideEventHandler(wrapperRef, closePopover, outsideEvents);

    const tableContent = (
        <div className={popoverStyle.table}>
            {etlTableContent
                .map(etl => {
                    const destinationName = etl.display_name.split(/(\s)Destination/)[0];
                    const destinationNameWidth = getTextWidth(destinationName, ETL_POPOVER_DESTINATION_NAME_FONT);
                    const destinationEl =
                        destinationNameWidth > 80 ? (
                            <Tooltip title={destinationName} distance={2}>
                                <span className={popoverStyle.destinationName}>{destinationName}</span>
                            </Tooltip>
                        ) : (
                            <span className={popoverStyle.destinationName}>{destinationName}</span>
                        );

                    const destinationScheme = etl.sub_text_extra;
                    const destinationSchemeWidth = getTextWidth(destinationScheme, ETL_POPOVER_TEXT_NAME_FONT);
                    const destinationSchemeEl =
                        destinationSchemeWidth > 190 ? (
                            <Tooltip title={destinationScheme} distance={2}>
                                <span className={popoverStyle.destinationScheme}>{destinationScheme}</span>
                            </Tooltip>
                        ) : (
                            <span className={popoverStyle.destinationScheme}>{destinationScheme}</span>
                        );

                    return (
                        <div className={popoverStyle.line} key={etl.uan_id}>
                            <span className={popoverStyle.iconContainer}>
                                {etl.state ? (
                                    <span
                                        className={popoverStyle.booleanIcon}
                                        style={{ backgroundColor: SINGLE_ETL_STATUS_COLOR_MAP[etl.state] }}
                                    />
                                ) : null}
                            </span>
                            {destinationEl}
                            {destinationSchemeEl}
                            <span className={popoverStyle.destinationStatus}>
                                {SINGLE_ETL_STATUS_TEXT_MAP[etl.state]}
                            </span>
                        </div>
                    );
                })
                .sort((etl1, etl2) => {
                    const { state: stateEtl1 } = etl1;
                    const { state: stateEtl2 } = etl2;
                    return sortAlphabetically(stateEtl1, stateEtl2);
                })}
        </div>
    );

    return (
        <Fragment>
            <Popover showPopover={showPopover} anchorEl={anchorEl} header={header} wrapperRef={wrapperRef}>
                {tableContent}
            </Popover>
        </Fragment>
    );
};

EtlPopover.propTypes = {
    etlTableContent: PropTypes.arrayOf(PropTypes.object),
    anchorEl: PropTypes.objectOf(PropTypes.any),
    showPopover: PropTypes.bool,
    setShowPopover: PropTypes.func,
    header: PropTypes.string,
};

EtlPopover.defaultProps = {
    etlTableContent: [],
    anchorEl: {},
    showPopover: false,
    setShowPopover: () => {},
    header: '',
};

export default EtlPopover;
