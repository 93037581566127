import React from 'react';

import css from './Edit.pcss';
import { INNER_LOGIC, FILTER_LABELS, TIMES } from '../consts';
import Dropdown from '../../components/molecules/Dropdown';
import PlusIcon from '../../resources/svg/plus.svg';
import XIcon from '../../resources/svg/x.svg';

export default class ConditionSession extends React.Component {
    render() {
        const {
            index,
            cIndex,
            filter,
            condition,
            editFilter,
            addCondition,
            editCondition,
            deleteCondition,
        } = this.props;
        return (
            <div className={css.condition}>
                <div className={css.flexRow}>
                    {cIndex !== 0 && (
                        <Dropdown
                            containerClass={`${css.conditionDropdown} ${css.blueDropdown}`}
                            popperClass={css.conditionDropdownPopper}
                            selected={{ name: filter.inner_logic, display_name: filter.inner_logic }}
                            onSelection={(s) => {
                                editFilter(index, 'inner_logic', s.name);
                            }}
                            items={[
                                { name: INNER_LOGIC.and, display_name: INNER_LOGIC.and },
                                { name: INNER_LOGIC.or, display_name: INNER_LOGIC.or },
                            ]}
                        />
                    )}
                    <div className={css.labelGroup}>
                        <div className={css.label}>{`${FILTER_LABELS[filter.filter_type]} in the last`}</div>
                        <Dropdown
                            containerClass={`${css.conditionDropdown} ${css.installDropdown}`}
                            popperClass={css.conditionDropdownPopper}
                            selected={{ name: condition.time, display_name: TIMES[condition.time] }}
                            onSelection={(s) => {
                                editCondition(index, cIndex, 'time', +s.name);
                            }}
                            items={Object.keys(TIMES).map((t) => ({
                                name: t,
                                display_name: TIMES[t],
                            }))}
                        />
                    </div>
                    <div className={css.conditionButtons}>
                        {filter.conditions.length > 1 && (
                            <XIcon
                                className={css.x}
                                onClick={() => {
                                    deleteCondition(index, cIndex);
                                }}
                            />
                        )}
                        <PlusIcon
                            className={css.plus}
                            onClick={() => {
                                addCondition(index);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
