import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../../components/molecules/ProgressBar';

function OnboardingProgressBar({ donePercentage, barWidth, barHeight, containerColor }) {
    const [barFillColor, setBarFillColor] = useState('var(--attention)');

    useEffect(() => {
        if (donePercentage >= 50 && donePercentage < 75) {
            setBarFillColor('var(--warning1Color');
        } else if (donePercentage >= 75) {
            setBarFillColor('var(--success1Color)');
        }
    }, [donePercentage]);

    return (
        <ProgressBar
            progressPercentage={donePercentage}
            barHeight={barHeight}
            barWidth={barWidth}
            fillColor={barFillColor}
            containerColor={containerColor}
        />
    );
}

OnboardingProgressBar.propTypes = {
    donePercentage: PropTypes.number.isRequired,
    barWidth: PropTypes.string,
    barHeight: PropTypes.string,
    containerColor: PropTypes.string,
};

OnboardingProgressBar.defaultProps = {
    barWidth: '100%',
    barHeight: '10px',
};

export default memo(OnboardingProgressBar);
